import React, { useEffect, useState } from "react";
import { Form, FormControl as Input, Button, InputGroup } from "react-bootstrap";
import { Link, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import Request from "../../../Request";
import { STORAGE_URL } from "../../../variables";
import ImageLoaderInput from "../../ReusableComponents/ImageLoaderInput";
import PasswordInput from "../../ReusableComponents/PasswordInput";

import default_profile from "../../../assets/images/default.png";
import { useSelector } from "react-redux";
import { MdModeEdit } from "react-icons/md";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";
import CourseGroup from "./CourseGroup";
import Certificate from "./Certificate";

const ProfilePage = props => {

    const [name, SetName] = useState("");
    const [email, SetEmail] = useState("");
    const [role, SetRole] = useState("");
    const [image, SetImage] = useState("");
    const [phone, SetPhone] = useState("");
    const [profession, SetProfession] = useState("");
    const [courses, SetCourses] = useState([]);
    const [usercourses, SetUserCourses] = useState([]);

    const [firstLoading, SetFirstLoading] = useState(false);

    const current_user = useSelector(store => store.AppReducer.user);

    const {id} = useParams();
    const location = useLocation();
    const history = useHistory();

    const Auth = () => {
        if (current_user["id"] == id || current_user["role"] == "Admin") return true;
        else return false;
    }

    const GetData = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let response = await Request.Get(`register/id/${id}`);
        if (!response) return;

        if (response?.status === true) {
            SetName(response?.user?.name);
            SetEmail(response?.user?.email);
            SetRole(response?.user?.role?.name);
            SetImage(response?.user?.image);
            SetPhone(response?.user?.phone);
            SetProfession(response?.user?.profession?.name);
            SetCourses(response?.user?.courses);
            SetUserCourses(response?.user?.usercourses);
            console.log(response?.user?.usercourses);
        }

        SetFirstLoading(true);
    };

    useEffect(() => {
        GetData();
    }, [location]);

    useEffect(() => {
        if (current_user["role"]) {
            if (!Auth()) history.push("/");
        }
    }, [current_user["role"]]);

    if (!firstLoading) return <SkeletonProfilePage/>
    else return(
        <div className="card">
            <div className="card-body">
                <div className="flex jcsb">
                    <h4>{name}</h4>
                    {/* <Link to={{
                        pathname: `/accounts/edit/${id}`,
                        state: {
                            from: id
                        }
                    }} onClick={(e) => e.stopPropagation()}>
                        <span 
                            className="inline-flex align-center jcc bg-primary icon" 
                            style={{width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px"}}
                            data-toggle="tooltip"
                            title="Editar este curso"
                        >
                            <MdModeEdit color="white" size={17}/>
                        </span>
                    </Link> */}
                    <DefaultButton
                        icon={<MdModeEdit color="white" size={17}/>}
                        title="Editar perfil"
                        bg="primary"
                        to={`/accounts/edit/${id}`}
                        routeState={{from: id}}
                        padding={0}
                        width="2.2em"
                        height="2.2em"
                    />
                </div>
                <div className="flex fdcolumn align-center">
                    <img src={(image ? STORAGE_URL + image : default_profile)} style={{width: "7em", height: "7em", borderRadius: "100%", objectFit: "cover"}} className="skeleton-shimmer" onError={(e) => e.target.src = default_profile}/>
                    <hr className="w100"/>
                    <Form className="w100">
                       <Form.Label>Nome: <span className="font-light">{name}</span></Form.Label><br/>
                       <Form.Label>E-mail: <span className="font-light">{email}</span></Form.Label><br/>
                       <Form.Label className={phone ? "" : "hide"}>Telefone: <span className="font-light">{phone}</span></Form.Label>{phone && <br/>}
                       <Form.Label className={profession ? "" : "hide"}>Profissão: <span className="font-light">{profession}</span></Form.Label>{profession && <br/>}
                       <Form.Label>Tipo de usuário: <span className="font-light">{role}</span></Form.Label><br/>
                    </Form>
                </div>
                <br/>

                <h4>Meus cursos</h4>
                <CourseGroup
                    courses={courses}
                />
                <br/>

                <h4>Meus certificados</h4>
                <Certificate
                    usercourses={usercourses}
                />
                <br/>

                <DefaultButton
                    icon={<RiArrowGoBackLine/>}
                    text="Voltar"
                    bg="secondary"
                    // to={(current_user["role"] == "Admin" ? "/accounts/list" : `/`)}
                    to={location?.state?.from ? location.state.from : (current_user["role"] == "Admin" ? "/accounts/list" : `/`)}
                />

            </div>
        </div>
    );
}

const SkeletonProfilePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title skeleton-shimmer"></div>
                <div className="flex fdcolumn align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em", borderRadius: "100%"}}></div>
                    <hr/>
                    <div className="w100 margin-bottom">
                        <div className="skeleton-medium-text skeleton-shimmer"></div>
                        <div className="skeleton-medium-text skeleton-shimmer"></div>
                        <div className="skeleton-medium-text skeleton-shimmer"></div>
                        <div className="skeleton-medium-text skeleton-shimmer"></div>
                        <div className="skeleton-medium-text skeleton-shimmer"></div>
                    </div>
                </div>
                <div className="skeleton-small-button skeleton-shimmer"/>
            </div>
        </div>
    );
}

export default ProfilePage;
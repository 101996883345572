import React, { useEffect, useState } from "react";

import { Link, useHistory, useParams } from "react-router-dom";

import { Button } from "react-bootstrap";
import DeleteIcon from '@material-ui/icons/Delete';
import OnlyAdmin from "../../../ReusableComponents/OnlyAdmin";
import Request from "../../../../Request";
import { CircularProgress, IconButton, Snackbar, Tooltip } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Pagination from '@material-ui/lab/Pagination';
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import Search from "../../../ReusableComponents/Search";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_URL } from "../../../../variables";
import { BsFillEyeFill } from "react-icons/bs";
import { MdViewList } from "react-icons/md";
import moment from "moment-timezone";

const UsersInCourse = ({ course_id, load, OnUpdate, course_name }) => {

    const [users, SetUsers] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [snackbar, SetOpenSnackbar] = useState(false);
    const [snackbar_message, SetMessageSnackbar] = useState("");
    const [page, SetPage] = useState(1);
    const [max_page, SetMaxPage] = useState(1);
    const [search, SetSearch] = useState('');
    const [showpopup, SetPopup] = useState(false);
    const [message_alert, SetMessageAlert] = useState('');
    const [user_id, SetUserId] = useState('');

    const history = useHistory();

    const GetUsers = async (search, page) => {

        const token = localStorage.getItem("token");
        if (!token || !course_id) return;
        SetLoading(true);
        let response = await Request.Get(`usercourse/course/in/${course_id}?page=${page}&search=${search}`, { "Authorization": `Bearer ${token}` });
        console.log(response);
        if (!response) return;

        SetUsers(response["data"]);
        SetMaxPage(response["last_page"]);
        SetLoading(false);
    }

    const RemoveUserFromCourse = async (user_id, user_name = "Usuário") => {
        if (loading) return;
        const token = localStorage.getItem("token");
        if (!token) return;

        const form = new FormData();
        form.append("user_id", user_id);
        form.append("course_id", course_id);

        SetLoading(true);
        let response = await Request.Post("usercourse/remove-user", form, {"Authorization": `Bearer ${token}`});
        
        if (response["status"]) {
            SetOpenSnackbar(true);
            SetMessageSnackbar("Usuário removido do curso");
        }
        SetLoading(false);
        OnUpdate();
    }

    useEffect(() => {
        GetUsers(search, page);
    }, [history.location && history.location.search]);

    useEffect(() => {
        GetUsers(search, page);
    }, [load, page, search]);

    return (
        <div>
            <p className="text-muted">Aqui ficam os alunos cadastrados no curso "{course_name}"</p>
            <div className="flex jcsb flex-wrap">
                <Search placeholder=" Pesquisar usuário pelo Nome ou E-mail" ValueChange={value => { SetSearch(value); SetPage(1); }} />
                <OnlyAdmin
                    component={
                        <DefaultButton
                            to={`/courses/course/add-user/${course_id}`}
                            routeState={{
                                course_id: course_id
                            }}
                            bg="confirm"
                            text="Adicionar aluno"
                        />
                    }
                />
            </div>
            <br />
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Data de entrada</th>

                            <th>Nome de usuário</th>
                            <th>E-mail</th>
                            <th>Progresso no curso</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users?.map((current, index) => (
                            <tr id={current["id"]} key={index} className={loading ? "hide" : ""}>
                                <td>
                                    {moment(current["user_course"][0]['created_at']).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss')}
                                </td>
                                <td>
                                    {current["name"]}
                                </td>
                                <td>
                                    {current["email"]}
                                </td>
                                <td>{current["percentage"]}%</td>
                                <td style={{ width: "10em" }}>
                                    {/* <a target={'_blank'} href={API_URL+'courses/certificado/'+course_id+'/'+current["id"]} >
                                        Ver certificado
                                    </a> */}
                                    <DefaultButton
                                        icon={<MdViewList size={17} color="white"/>}
                                        title="Ver detalhes"
                                        width="2.2em"
                                        height="2.2em"
                                        padding="0"
                                        to={`/courses/course/detail_user/${course_id}/${current["id"]}`}
                                        bg="primary"
                                        style={{marginRight: "2px"}}
                                        routeState={{
                                            from: `/courses/course/${course_id}`
                                        }}
                                    />
                                    <DefaultButton
                                        icon={<BsFillEyeFill size={17} color="white"/>}
                                        title="Ver perfil"
                                        width="2.2em"
                                        height="2.2em"
                                        padding="0"
                                        to={`/accounts/profile/${current["id"]}`}
                                        bg="warning"
                                        style={{marginRight: "2px"}}
                                        routeState={{
                                            from: `/courses/course/${course_id}`
                                        }}
                                    />
                                    <DefaultButton
                                        icon={<DeleteIcon size={17} style={{ color: "white" }} />}
                                        onClick={() => { SetUserId(current["id"]); SetMessageAlert('Deseja mesmo retirar o aluno ' + current['name'] + ' deste curso?'); SetPopup(true); }}
                                        title="Remover aluno do curso"
                                        bg="danger"
                                        padding={0}
                                        width="2.2em"
                                        height="2.2em"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="w100 flex align-center jcc">
                    {users.length == 0 && !loading ? "Sem usuários no curso" : ""}
                </div>
                <Pagination
                    className={(max_page == 1 ? "hide" : "")}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    count={max_page}
                    value={page}
                    onChange={(_, value) => SetPage(value)}
                />
            </div>
            <div className="w100 flex jcc align-center">
                <CircularProgress className={loading ? "" : "hide"} style={{ width: "30px", height: "30px" }} />
            </div>
            <SweetAlert
                title="Atenção!"
                onConfirm={() => {
                    SetPopup(false);
                    RemoveUserFromCourse(user_id)
                }}
                onCancel={() => {
                    SetPopup(false);
                }}
                warning
                showCancel={true}
                cancelBtnStyle={{ color: "white", textDecoration: "none" }}
                cancelBtnCssClass="btn-danger"
                cancelBtnText="Cancelar"
                confirmBtnText="Excluir"
                show={showpopup}
            >{message_alert}</SweetAlert>
            <Snackbar
                open={snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                autoHideDuration={3000}
                onClose={() => SetOpenSnackbar(false)}
                message={snackbar_message}
                action={
                    <IconButton onClick={() => SetOpenSnackbar(false)}>
                        <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                }
            />
        </div >
    );
}

export default UsersInCourse;

import React, { useEffect, useState } from "react";
import { Button, Form, FormControl as Input,ProgressBar } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Request from "../../../Request";
import { STORAGE_URL } from "../../../variables";
import InputFile from "../../ReusableComponents/InputFile";
import LoadingScreen from "../../ReusableComponents/LoadingScreen";
import VideoLoaderInput from "../../ReusableComponents/VideoLoaderInput";

import { VideoInputBox } from "../../ReusableComponents/VideoLoaderInput";
import { Chip, CircularProgress } from "@material-ui/core";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import Attention from "../../ReusableComponents/Attention";

const EditLesson = props => {

    const { id } = useParams();

    const [title, SetTitle] = useState("");

    const [videos, SetVideos] = useState([]);
    const [existing_videos, SetExistingVideos] = useState([]);
    const [videos_to_delete, SetVideosToDelete] = useState([]);

    const [docs, SetDocs] = useState([]);
    const [existing_docs, SetExistingDocs] = useState([]);
    const [docs_to_delete, SetDocsToDelete] = useState([]);

    const [description, SetDescription] = useState("");
    const [course_id, SetCourse] = useState("");
    const [loading, SetLoading] = useState(false);
    const [firstLoading, SetFirstLoading] = useState(false);

    const [showpopup, SetPopup] = useState(false);
    const [popup_message, SetMessage] = useState("");
    const [popup_title, SetPopupTitle] = useState("Atenção!");
    const [select_options, SetOptions] = useState([]);
    const [from_course_id, SetFromCourse] = useState("");
    const [progress, SetProgress] = useState(0);

    const history = useHistory();
    const location = useLocation();

    const LoadContent = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        SetLoading(true);
        let response = await Request.Get(`lessons/id/${id}`, { "Authorization": `Bearer ${token}` });
        if (!response) {
            SetLoading(false);
            return;
        }
        SetTitle(response["title"]);
        SetExistingVideos(response["files"]["videos"]);
        SetExistingDocs(response["files"]["documents"]);
        SetDescription(response["description"]);
        SetCourse(response["course_id"]);
        SetLoading(false);
        SetFirstLoading(true);
    }

    const Submit = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        if (!title) {
            SetPopup(true);
            SetMessage("Coloque um título");
            return;
        } 
        else if (videos.length == 0 && docs.length==0 && existing_videos.length==0 && existing_docs.length==0) {
            SetPopup(true);
            SetMessage("Coloque pelo menos um vídeo ou um arquivo");
            return;
        } 
        else if (!course_id) {
            SetPopup(true);
            SetMessage("Selecione um curso para colocar a aula");
            return;
        } else if (!description) {
            SetPopup(true);
            SetMessage("Coloque uma descrição");
            return;
        }

        let form = new FormData();
        form.append("id", id);
        form.append("title", title);
        form.append("description", description);
        for (let i = 0; i < videos.length; i++) {
            form.append("videos_covers[]", videos[i].cover_file ?? 'none');
            delete videos[i].cover_file;
            form.append("videos[]", videos[i]);
            form.append("videos_names[]", videos[i].name_file);

        }
        for (let i = 0; i < docs.length; i++) {
            form.append("files[]", docs[i]);
            form.append("files_names[]", docs[i].name_file);

        }

        for (let i = 0; i < existing_videos.length; i++) {
            form.append("existing_videos_ids[]", existing_videos[i].id);
            form.append("existing_videos_names[]", existing_videos[i].name);
            form.append("existing_videos_covers[]", existing_videos[i].cover_file ?? 'none');

        }
        for (let i = 0; i < existing_docs.length; i++) {
            form.append("existing_docs_ids[]", existing_docs[i].id);
            form.append("existing_docs_names[]", existing_docs[i].name);
        }
        for (let i = 0; i < videos_to_delete.length; i++) {
            form.append("to_delete_files_videos[]", videos_to_delete[i]);
        }
        for (let i = 0; i < docs_to_delete.length; i++){
            form.append("to_delete_files_docs[]", docs_to_delete[i]);
        } 
        // form.append("to_delete_files[]", "");
        form.append("course_id", course_id);
        SetProgress(0);
        SetLoading(true);

        let response = await Request.PostProgress("lessons/edit", form, { "Authorization": `Bearer ${token}` },(progress)=>{SetProgress(progress)});
        if (!response) {
            SetLoading(false);
            return;
        } else if (response["status"]) {
            history.push({
                pathname: `/courses/course/${course_id}`,
                state: {
                    snackbar: {
                        show: true,
                        message: "Aula editada com sucesso"
                    }
                },
                search:'?'+new URLSearchParams({tab: 0}).toString()
            });
            return;

        } else if (!response["status"]) {
            SetPopup(true);
            SetMessage(response["message"]);
            SetPopupTitle("Atenção!");
        }
        SetLoading(false);
    }

    const GetCourses = async () => {
        let response = await Request.Get(`courses`);
        if (!response) return;

        let temp_select = [];
        for (let i = 0; i < response.length; i++) {
            let element = (
                <option value={response[i]["id"]} key={response[i]["id"]}>{response[i]["name"]}</option>
            );
            temp_select.push(element);
        }
        SetOptions(temp_select);
    }

    const HandleExistingVideoDeletion = (id, index) => {
        let temp_list = existing_videos;
        temp_list.splice(index, 1);
        let temp_ids = videos_to_delete;
        temp_ids.push(id);

        SetExistingVideos([...temp_list]);
        SetVideosToDelete(temp_ids);
    }

    const HandleExistingDocDeletion = (id, index) => {
        let temp_list = existing_docs;
        temp_list.splice(index, 1);
        let temp_ids = docs_to_delete;
        temp_ids.push(id);
        SetExistingDocs([...temp_list]);
        SetDocsToDelete(temp_ids);
    }

    useEffect(() => {
        LoadContent();
        GetCourses();
    }, []);
    console.log(location)

    if (!firstLoading) return <SkeletonEditLesson/>
    return (
        <div style={{ position: "relative" }}>
            {/* <LoadingScreen loading={loading}/> */}
            <SweetAlert
            warning
                title={popup_title}
                show={showpopup}
                onConfirm={() => {
                    SetPopup(false);
                }}
            >{popup_message}</SweetAlert>
            <div className="card">
                <div className="card-body">
                    <h3 className="margin-bottom title">Editar aula</h3>
                    <p className="text-muted">Aqui você, sendo administrador, pode editar os dados da aula</p>
                    <Form>
                        <Form.Label>Vídeo(s)</Form.Label>
                        {/* <p className={"text-muted " + (existing_videos.length == 0 ? "hide" : "")}>Vídeos existentes</p> */}


                        <VideoLoaderInput

                            existing_videos={existing_videos}
                            changeExisting={(e)=>{
                                SetExistingVideos(e);
                            }}
                            HandleExistingVideoDeletion={HandleExistingVideoDeletion}
                            VideoChange={video => {
                                SetVideos(video);
                            }}
                            multiple
                        />

                        <Form.Label>Arquivo(s)</Form.Label>
                        {/* <p className={"text-muted " + (existing_docs.length == 0 ? "hide" : "")}>Arquivos existentes</p> */}

                        <InputFile
                            existing_docs={existing_docs}
                            changeExisting={(e)=>{
                                SetExistingDocs(e);
                            }}
                            HandleExistingDocDeletion={(e, e1) => HandleExistingDocDeletion(e, e1)}
                            onChange={(value) => SetDocs(value)}
                            accept=".pdf,.doc"
                        />

                        <Form.Label>Título <Attention /></Form.Label>
                        <Input placeholder="Título da aula" onChange={(e) => SetTitle(e.target.value)} value={title} className="margin-bottom" />

                        <Form.Label>Descrição <Attention /></Form.Label>
                        <Input className="margin-bottom" as="textarea" placeholder="Descrição da aula" onChange={(e) => SetDescription(e.target.value)} value={description} />

                        <Form.Label className={(select_options.length == 0 ? "hide" : "")}>Selecionar curso <Attention /></Form.Label>
                        <select className={"margin-bottom form-select " + (select_options.length == 0 ? "hide" : "")} onChange={(e) => SetCourse(e.target.value)} value={course_id}>
                            <option value="">Selecionar curso</option>
                            {select_options}
                        </select>
                        {loading==false && <div className="w100 inline-flex jcsb">
                            
                            <DefaultButton
                                text="Voltar"
                                onClick={Submit}
                                bg="secondary"
                                icon={<RiArrowGoBackLine />}
                                to={`/courses/course/${location?.state?.from}`}
                                search={'?'+new URLSearchParams({tab: 0}).toString()}

                            />
                            <DefaultButton
                                loading={loading}
                                text="Salvar alterações"
                                onClick={Submit}
                                bg="confirm"
                            />
                        </div>}
                        {loading==true && <ProgressBar animated now={progress} label={parseInt(progress)+'%'} />}
                    </Form>
                </div>
            </div>
        </div>
    );
}

const SkeletonEditLesson = () => {
    return (
        <div className="card">
            <div className="card-body">
                <h3 className="margin-bottom title">Editar aula</h3>
                <p className="text-muted">Aqui você, sendo administrador, pode editar os dados da aula</p>
                <Form.Label>Vídeo(s) <Attention /></Form.Label>
                <div className="flex fdrow margin-bottom">
                    <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em", marginRight: "1em"}}/>
                    <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em", marginRight: "1em"}}/>
                    <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em", marginRight: "1em"}}/>
                </div>
                <div className="skeleton-medium-button skeleton-shimmer"/>
                <Form.Label>Arquivos(s)</Form.Label>
                <div className="flex fdrow margin-bottom">
                    <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "2em", marginRight: "1em", borderRadius: "10em"}}/>
                    <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "2em", marginRight: "1em", borderRadius: "10em"}}/>
                    <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "2em", marginRight: "1em", borderRadius: "10em"}}/>
                </div>
                <div className="skeleton-medium-button skeleton-shimmer"/>

                <Form.Label>Título(s) <Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer" style={{height: "2em"}}/>
                <Form.Label>Descrição(s) <Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{height: "2em"}}/>

                <div className="flex w100 jcsb">
                    <div className="skeleton-small-button skeleton-shimmer"/>
                    <div className="skeleton-medium-button skeleton-shimmer"/>
                </div>
            </div>
        </div>
    );
}

export default EditLesson;
const ObjectEqual = (objectA, objectB) => {
    let keysA = Object.keys(objectA);
    let keysB = Object.keys(objectB);

    if (keysA.length != keysB.length) return false;

    for (let key of keysA) if (objectA[key] != objectB[key]) return false;
    return true;
}

export default ObjectEqual;
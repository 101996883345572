import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { BsFillEyeFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import DefaultButton from "../../../../ReusableComponents/DefaultButton";

import Request from "../../../../../Request";

const Board = props => {

    const {
        id,
        name,
        date,
        group_id,
        OnDelete
    } = props;

    const [popup, SetPopup] = useState(false);

    const Delete = async () => {
        let form = new FormData();
        form.append("profession_id", id);
        form.append("group_id", group_id);

        let response = await Request.Post("groupprofession/delete", form);
        console.log(response);
        if (!response) return;
        else if (response["status"]) OnDelete(response);
    }

    return (
        <tr>
            <td>{name}</td>
            <td>{new Date(Date.parse(date)).toLocaleDateString("pt-BR")}</td>
            <td style={{
                verticalAlign: "middle",
                whiteSpace: "nowrap"
            }}>
                <SweetAlert
                    show={popup}
                    title="Atenção!"
                    onConfirm={() => {
                        SetPopup(false);
                        Delete();
                    }}
                    onCancel={() => {
                        SetPopup(false);
                    }}
                    showCancel={true}
                    cancelBtnStyle={{color: "white", textDecoration: "none"}}
                    cancelBtnCssClass="btn-danger"
                    cancelBtnText="Cancelar"
                    confirmBtnText="Remover"
                >
                    Deseja mesmo remover a profissão "{name}" do grupo?
                </SweetAlert>
                <DefaultButton
                    onClick={() => SetPopup(true)}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Remover profissão`}
                    bg="danger"
                    icon={<MdDelete size={17} color="white"/>}
                />
            </td>
        </tr>
    );
}

export default Board;
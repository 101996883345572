import { ButtonBase, Card, CardContent, CardMedia, LinearProgress, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { STORAGE_URL } from "../../../../variables";

import styles from "./index.module.css";

const CourseGroup = props => {

    const {
        courses = []
    } = props;

    const history = useHistory();

    return (
        <div className={styles.group}>
            {courses?.map((course, key) => (
                <ButtonBase 
                    key={key}
                    className={styles.cardContainer}
                    onClick={() => history.push(`/courses/course/${course?.id}`)}
                >
                    <Card className={styles.card}>
                        <CardMedia
                            component="img"
                            image={STORAGE_URL + course?.image}
                            height="140"
                            alt="Imagem do curso"
                        />
                        <CardContent>
                            <Typography>{course?.name}</Typography>
                            <LinearProgress variant="determinate" value={course?.progression}/>
                            <Typography
                                style={{
                                    float: "right"
                                }}
                            >
                                {course?.progression}%
                            </Typography>
                        </CardContent>
                    </Card>
                </ButtonBase>
            ))}
        </div>
    );
}

export default CourseGroup;
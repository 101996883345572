import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import { Accordion, AccordionSummary, AccordionDetails, Menu, MenuItem, Button, ButtonBase, Checkbox, CircularProgress } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Request from "../../../../Request";
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import UsersLessonPage from "../../Lessons/Users/UsersLessonPage";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { API_URL, STORAGE_URL } from "../../../../variables";
import { Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../../ReusableComponents/TabPanel";
import VideocamIcon from '@material-ui/icons/Videocam';
import DescriptionIcon from '@material-ui/icons/Description';
import LaunchIcon from '@material-ui/icons/Launch';
import { AdminAsUser, logout } from "../../../../actions/AppActions";
import { MdDvr } from "react-icons/md";
// import { Tab, Tabs } from "react-bootstrap";


const UsersCoursePage = () => {

    const [course, SetCourse] = useState();
    const [lessons, SetLessons] = useState([]);
    const [videosLinks, SetVideosLinks] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [firstLoading, SetFirstLoading] = useState(false);

    const [currentVideoPath, SetCurrentVideoPath] = useState("");

    const [name, SetName] = useState("");
    const [description, SetDescription] = useState("");
    const [banner_url, SetBannerUrl] = useState("");

    const [tabValue, SetTabValue] = useState(0);

    const [userCourse, SetUserCourse] = useState({});

    const [activeDocumentPath, SetActiveDocumentPath] = useState("");

    const userId = useSelector(store => store.AppReducer.user.id);
    const role = useSelector(store => store.AppReducer.user.role);

    const history = useHistory();
    const location = useLocation();
    const { id } = useParams();
    const query = new URLSearchParams(location.search);
    const dispatch = useDispatch();

    const GetCourseInfo = async () => {
        if (!id || id == "undefined") return;
        let response = await Request.Get(`courses/${id}`);
        if (!response) return;
        if (response.length == 0) return;
        if (response["name"]) SetName(response["name"]);
        if (response["description"]) SetDescription(response["description"]);
        if (response["image"]) SetBannerUrl(response["image"]);
        SetLoading(false);
    }

    const GetLessons = async () => {
        SetLoading(true);
        let response = await Request.Get(`lessons/course/${id}/${userId}`);
        if (!response || response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());

            return;
        }
        SetLessons(response.lessons);
        SetCourse(response?.course);
        console.log(response);
        let tVideosLinks = [];
        let tDocsLinks = [];
        for (let i = 0; i < response.lessons.length; i++) {
            let tVideos = response.lessons[i]["files"]["videos"];
            if (tVideos) for (let v = 0; v < tVideos.length; v++) {
                tVideosLinks.push(tVideos[v]);
            }

            let tDocs = response.lessons[i]?.files?.documents;
            if (tDocs) for (let j = 0; j < tDocs.length; j++) tDocsLinks.push(tDocs[j]);
        }

        SetVideosLinks(tVideosLinks);

        if (response?.file_id !== null && response?.lesson_id !== null && response?.file_type !== null) {
            if (response?.file_type === "video") history.push(`?video=${response?.file_id}&lesson=${response?.lesson_id}`);
            else if (response?.file_type === "document") history.push(`?doc=${response?.file_id}&lesson=${response?.lesson_id}`);
        } else {
            if ((!query.get("video") || !query.get("lesson") && !query.get("doc")) && tVideosLinks.length != 0) history.push(`?video=${tVideosLinks[0]?.id}&lesson=${tVideosLinks[0]?.lesson_id}`);
            else if ((!query.get("doc") || !query.get("lesson") && !query.get("video") && tDocsLinks.length != 0)) history.push(`?doc=${tDocsLinks[0]?.id}&lesson=${tDocsLinks[0]?.lesson_id}`);
        }

        SetLoading(false);
        SetFirstLoading(true);
    }

    const VisitedDoc = async (docId, lessonId) => {
        let token = localStorage.getItem("token");
        if (!token) return;

        lessonId = query.get("lesson") || lessonId;
        if (!lessonId || lessonId == "") return;
        // console.log(token);

        let form = new FormData();
        form.append("doc_id", docId);
        form.append("lesson_id", lessonId);
        form.append("user_id", userId);

        let response = await Request.Post("lessons/progress/doc/visited", form, { "Authorization": `Bearer ${token}` });
        if (response["status"]) {
            AuthCourse();

            GetLessons();
        }
    }

    const UpdateLastSeenDoc = async (docId, lessonId) => {
        if (!lessonId) return;

        let form = new FormData();
        form.append("doc_id", docId);
        form.append("lesson_id", lessonId);
        form.append("user_id", userId);

        let response = await Request.Post("lessons/progress/doc/log", form);
        // console.log(response);
    }

    const AuthCourse = async () => {
        let token = localStorage.getItem("token");
        if (!token) return false;

        let response = await Request.Get(`usercourse/user-in-course/${id}/${userId}`);
        if (!response["status"] && role != "Admin") {
            history.push("/courses/list");
        }
        else {
            // console.log(response);
            SetUserCourse(response.user_course);
        }
    }

    useEffect(() => {
        if (!query.get("doc")) {
            SetActiveDocumentPath("");
            return;
        }
        // console.log(query.get("doc"));
        let lesson = lessons.filter(x => x.id == query.get("lesson"))[0];
        if (lesson != undefined) {
            let docs = lesson?.files?.documents;
            let doc = docs.filter(x => x.id == query.get("doc"));
            
            // console.log(docs);
            if (doc.length == 0) return;

            // console.log(doc[0]?.path);
            SetActiveDocumentPath(doc[0]?.path);
        }
        console.log("changed");
    }, [query]);

    useEffect(() => {
        if (!userId) history.push("/");
        AuthCourse();
        GetCourseInfo();
        GetLessons();
    }, []);

    if (!firstLoading) return <SkeletonUsersCoursePage />
    return (
        <div className="card" id={loading.toString()}>
            <div className="card-body">
                <div className="flex fdrow jcsb w100">
                    <h2 className="title">{name}</h2>
                    <DefaultButton
                        onClick={() => dispatch(AdminAsUser({ adminAsUser: false }))}
                        bg="primary"
                        text="Ver curso como administrador"
                        hidden={role != "Admin"}
                        title="Somente administradores podem ver esse botão"
                    />
                </div>
                <br />
                <div className="course-page flex fdrow margin-bottom">
                    <div
                        id="left-side"
                        className={"flex3"}
                        style={{
                            width: "-webkit-fill-available"
                        }}
                    >
                        <UsersLessonPage
                            videoId={query.get("video")}
                            lessonId={query.get("lesson")}
                            videoPath={currentVideoPath}
                            onCompleteSync={() => {
                                AuthCourse();
                                GetLessons();
                            }}
                            previousVideoLink={(() => {
                                let currentIndex = videosLinks.findIndex(x => x["id"] == query.get("video"));
                                if (currentIndex === 0) return null;
                                else {
                                    if (videosLinks[currentIndex - 1] === undefined) return null;
                                    let previousLessonId = videosLinks[currentIndex - 1]["lesson_id"];
                                    let previousVideoId = videosLinks[currentIndex - 1]["id"];
                                    return `/courses/course/${id}?video=${previousVideoId}&lesson=${previousLessonId}`;
                                }
                            })()}
                            nextVideoLink={(() => {
                                let currentIndex = videosLinks.findIndex(x => x["id"] == query.get("video"));
                                if (currentIndex === videosLinks.length) return null;
                                else {
                                    if (videosLinks[currentIndex + 1] === undefined) return null;
                                    let nextLessonId = videosLinks[currentIndex + 1]["lesson_id"];
                                    let nextVideoId = videosLinks[currentIndex + 1]["id"];
                                    return `/courses/course/${id}?video=${nextVideoId}&lesson=${nextLessonId}`;
                                }
                            })()}
                        />
                        <div>
                            <iframe
                                src={activeDocumentPath ? `${STORAGE_URL}${activeDocumentPath}` : ""}
                                hidden={!activeDocumentPath}
                                style={{
                                    width: "100%",
                                    height: "30vw",
                                    minHeight: "22em"
                                }}
                            />
                        </div>
                        <Tabs
                            value={tabValue}
                            onChange={(_, value) => SetTabValue(value)}
                            style={{ backgroundColor: "#ececf1", minHeight: "51px" }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant={"scrollable"}
                            scrollButtons={"on"}
                        >
                            <Tab label="Sobre o curso" />
                            <Tab label="Sobre a aula" />
                            <Tab label="Conteúdo do curso" className="d-lg-none d-block" />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            {description}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            {(lessons.length != 0 && lessons) && lessons.map((x, index) => {
                                if (x["id"] == query.get("lesson")) return <p key={index}>{x["description"]}</p>
                            })}
                        </TabPanel>
                        <TabPanel value={tabValue} index={2} className="d-lg-none d-block">
                            <div className="w100 flex jcc">
                                {loading && lessons.length == 0 ? <CircularProgress /> : ""}
                            </div>
                            {lessons.length != 0 && lessons.map((value, index) => (
                                <Accordion
                                    key={index}
                                    style={{
                                        background: "#ececf1"
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{
                                            fontWeight: "700",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <div className="text-truncate" style={{ maxWidth: "300px" }}>
                                            {index + 1}. {value["title"]}
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        className="flex fdcolumn "
                                        style={{ padding: 0 }}
                                    >
                                        {value.files.videos && value.files.videos.map((video, videoIndex) => (
                                            <ButtonBase
                                                className="w100 flex align-center jcsb"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    padding: "2px",
                                                    backgroundColor: (query.get("video") == video.id ? "rgba(0, 0, 0, 0.135)" : "initial")
                                                }}
                                                key={"video_" + videoIndex}
                                                onClick={() => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth'
                                                    });
                                                    history.push(`?video=${video.id}&lesson=${video.lesson_id}`);
                                                    SetCurrentVideoPath(video.path);
                                                }}
                                            >
                                                <div style={{ overflow: "hidden" }}>
                                                    <Checkbox
                                                        disabled
                                                        checked={video.completed}
                                                    />
                                                    {video.name !== null ? video.name : "Video sem nome"}
                                                </div>
                                                <VideocamIcon style={{ color: "gray" }} />
                                            </ButtonBase>
                                        ))}
                                        {value.files.documents && value.files.documents.map((document, index) => (
                                            <ButtonBase
                                                className="w100 flex align-center jcsb"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    padding: "2px",
                                                    cursor: "pointer",
                                                    backgroundColor: (query.get("doc") == document.id ? "rgba(0, 0, 0, 0.135)" : "initial")
                                                }}
                                                key={"file" + index}
                                                onClick={() => {
                                                    !document.completed && VisitedDoc(document.id);
                                                    history.push(`?doc=${document.id}&lesson=${document.lesson_id}`);
                                                    UpdateLastSeenDoc(document?.id, document?.lesson_id);
                                                    // window.open(STORAGE_URL + document["path"]);
                                                }}
                                            >
                                                <div style={{ overflow: "hidden" }}>
                                                    <Checkbox
                                                        disabled
                                                        checked={document.completed}
                                                    />
                                                    {document["name"] ? document["name"] : "Arquivo"}
                                                </div>
                                                <div>
                                                    <LaunchIcon
                                                        style={{ color: "gray" }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            !document.completed && VisitedDoc(document.id);
                                                            window.open(STORAGE_URL + document["path"]);
                                                        }}
                                                        className="icon"
                                                    />
                                                    <DescriptionIcon style={{ color: "gray" }} />
                                                </div>
                                            </ButtonBase>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                            <div className="w100 inline-flex jcsb margin-top-1">
                                <DefaultButton
                                    to={`/courses/course_avaliation/${id}`}
                                    bg="primary"
                                    icon={<MdDvr style={{ marginRight: 5 }} />}
                                    text="Ver avaliação"
                                    hidden={!course?.hasExam}
                                    style={{ width: '100%', marginTop: '15px', marginRight: 10, marginLeft: 10 }}
                                />
                            </div>
                        </TabPanel>
                    </div>
                    <div
                        id="right-side"
                        className="flex1 d-lg-block d-none"
                    >
                        <AccordionSummary
                            style={{
                                fontWeight: 700,
                                borderBottom: "3px solid #351c75",
                                fontSize: "1.2em",
                                background: "#ececf1"
                            }}
                        >
                            <div className="flex jcc w100">Conteúdo do curso</div>
                        </AccordionSummary>
                        <div className={"w100 flex jcc" + (loading && lessons.length == 0 ? "" : " hide")} style={{ marginTop: "5em" }}>
                            <CircularProgress />
                        </div>
                        <div
                            className="course-content"
                            style={{
                                maxHeight: "31\.8vw",
                                overflowY: "scroll"
                            }}
                        >
                            {lessons.length != 0 && lessons.map((value, index) => (
                                <Accordion
                                    key={index}
                                    style={{
                                        background: "#ececf1"
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{
                                            fontWeight: "700",
                                            textTransform: "capitalize"
                                        }}

                                    >
                                        <div
                                            className="text-truncate"
                                            style={{
                                                maxWidth: "300px"
                                            }}
                                        >
                                            {index + 1}. {value["title"]}
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        className="flex fdcolumn "
                                        style={{ padding: 0 }}
                                    >
                                        {value.files.videos && value.files.videos.map((video, videoIndex) => (
                                            <ButtonBase
                                                className="w100 flex align-center jcsb"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    padding: "2px",
                                                    backgroundColor: (query.get("video") == video.id ? "rgba(0, 0, 0, 0.135)" : "initial")
                                                }}
                                                key={"video_" + videoIndex}
                                                onClick={() => {
                                                    history.push(`?video=${video.id}&lesson=${video.lesson_id}`);
                                                    SetCurrentVideoPath(video.path);
                                                }}
                                            >
                                                <div style={{ overflow: "hidden" }}>
                                                    <Checkbox
                                                        disabled
                                                        checked={video.completed}
                                                    />
                                                    {video.name !== null ? video.name : "Video sem nome"}
                                                </div>
                                                <VideocamIcon style={{ color: "gray" }} />
                                            </ButtonBase>
                                        ))}
                                        {value.files.documents && value.files.documents.map((document, index) => (
                                            <ButtonBase
                                                className="w100 flex align-center jcsb"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    padding: "2px",
                                                    cursor: "pointer",
                                                    backgroundColor: (query.get("doc") == document.id ? "rgba(0, 0, 0, 0.135)" : "initial")
                                                }}
                                                key={"file" + index}
                                                onClick={() => {
                                                    !document.completed && VisitedDoc(document.id, document.lesson_id);
                                                    history.push(`?doc=${document.id}&lesson=${document.lesson_id}`);
                                                    UpdateLastSeenDoc(document?.id, document?.lesson_id);
                                                    // window.open(STORAGE_URL + document["path"]);
                                                }}
                                            >
                                                <div className="text-truncate" style={{ maxWidth: "250px" }}>
                                                    <Checkbox
                                                        disabled
                                                        checked={document.completed}
                                                    />
                                                    {document["name"] ? document["name"] : "Arquivo"}
                                                </div>
                                                <div>
                                                    <LaunchIcon
                                                        style={{ color: "gray" }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            !document.completed && VisitedDoc(document.id);
                                                            window.open(STORAGE_URL + document["path"]);
                                                        }}
                                                        className="icon"
                                                    />
                                                    <DescriptionIcon style={{ color: "gray" }} />
                                                </div>
                                            </ButtonBase>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                        <DefaultButton
                            to={`/courses/course_avaliation/${id}`}
                            bg="primary"
                            icon={<MdDvr style={{ marginRight: 5 }} />}
                            text="Ver avaliação"
                            hidden={!course?.hasExam}
                            style={{ width: '100%', marginTop: '15px' }}
                        />
                        <a
                            href={API_URL + 'courses/certificado/' + course?.id + '/' + userId}
                            target="_blank"
                            className={`remove-a ${!course?.completedCourse ? "clickDisabled" : ""}`}
                            hidden={course?.hasExam}
                        >
                            <DefaultButton
                                text="Baixar certificado"
                                disabled={!course?.completedCourse}
                                style={{ width: '100%', marginTop: '15px' }}
                            />
                        </a>
                        {/* {userCourse.completed_course === true && <div className="w100 inline-flex jcsb margin-top-1">
                        </div>} */}
                    </div>
                </div>

                <div className="w100 inline-flex jcsb margin-top-1">
                    <DefaultButton
                        to={`/courses/list`}
                        bg="secondary"
                        icon={<RiArrowGoBackLine />}
                        text="Voltar"
                    />
                </div>
            </div>
        </div>
    );
}

const SkeletonUsersCoursePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title skeleton-shimmer" />
                <br />
                <div className="w100 flex fdrow margin-bottom" style={{ height: "32vw" }}>
                    <div className="skeleton-base skeleton-shimmer flex3" style={{ marginRight: "1px" }} />
                    <div className="skeleton-base skeleton-shimmer flex1 d-lg-block d-none" />
                </div>
                <div className="skeleton-medium-text skeleton-shimmer" />
                <div className="skeleton-medium-text skeleton-shimmer" />
                <div className="skeleton-medium-text skeleton-shimmer" style={{ width: "45%" }} />
                <div className="skeleton-small-button skeleton-shimmer" style={{ marginTop: "1em" }} />
            </div>
        </div>
    );
}

export default UsersCoursePage;
import { CircularProgress } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import { Tabs, Tab } from "@material-ui/core";
import TabPanel from "../../../ReusableComponents/TabPanel";

import React, { useEffect, useState } from "react";
import { RiArrowGoBackLine } from "react-icons/ri";

import { useLocation, useParams, useHistory } from "react-router";
import Request from "../../../../Request";
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import Search from "../../../ReusableComponents/Search";
import Userboard from "./Userboard";
import ProfessionsTable from "./ProfessionsTable";

const ViewGroup = props => {

    const [users, SetUsers] = useState([]);
    const [name, SetName] = useState("");
    const [description, SetDescription] = useState("");
    
    const [loading, SetLoading] = useState(false);
    const [firstLoading, SetFirstLoading] = useState(false);

    const [page, SetPage] = useState(1);
    const [maxPage, SetMaxPage] = useState(1);

    const [activeTab, SetActiveTab] = useState(0);

    const [search, SetSearch] = useState("");

    const location = useLocation();
    const {id} = useParams();
    const history = useHistory();

    const GetData = async () => {
        SetLoading(true);
        let response = await Request.Get(`usergroup/group/${id}?search=${search}&page=${page}`);
        if (response) {
            if (response.users) {
                SetUsers(response.users.data);  
                SetMaxPage(response.users.last_page);
            } 
            SetName(response.name);
            SetDescription(response.description);
        }
        SetLoading(false);
        SetFirstLoading(true);
    }

    useEffect(() => {
        if (activeTab == 0) GetData();
    }, [search, page, activeTab]);

    if (!firstLoading) return <Skeleton/>
    return (
        <div className="card">
            <div className="card-body">
                <h2 className="title">Grupo {name}</h2>
                <p className="text-muted">{description}</p>
                <br />
                <div className="flex jcsb flex-wrap margin-bottom">
                    <Search
                        placeholder={
                            activeTab == 0 ?
                            "Pesquisar alunos pelo Nome ou E-mail" :
                            "Pesquisar profissões pelo Nome"
                        }
                        ValueChange={value => SetSearch(value)}
                    />
                    <div>
                        <DefaultButton
                            bg="confirm"
                            to={`/groups/add/professions/${id}`}
                            text="Adicionar profissão ao grupo"
                            style={{
                                marginRight: "10px"
                            }}
                        />
                        <DefaultButton
                            bg="confirm"
                            to={`/groups/add/users/${id}`}
                            text="Adicionar alunos ao grupo"
                        />
                    </div>
                </div>
                <Tabs
                    value={activeTab}
                    onChange={(_, value) => {
                        SetActiveTab(value);
                        history.push({search: `?tab=${value}`});
                    }}
                    style={{backgroundColor: "#ececf1"}}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={"scrollable"}
                    scrollButtons={"on"}
                >
                    <Tab label="Usuários"/>
                    <Tab label="Profissões"/>
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                    <div className="table-responsive">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>E-mail</th>
                                    <th>Data criado</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(!loading && users && users.length != 0) && users.map((value, index) => (
                                    <Userboard
                                        key={index}
                                        id={value.id}
                                        name={value.name}
                                        email={value.email}
                                        date={value.created_at}
                                        group_id={id}
                                        OnDelete={(data) => GetData()}
                                    />
                                ))}
                            </tbody>
                        </table>
                        <div className="w100 flex align-center jcc">
                            {!loading && (users && users.length == 0) ? "Nenhum aluno achado" : ""}
                        </div>
                    </div>
                    <div className="w100 flex jcc align-center">
                        {loading && <CircularProgress/>}
                    </div>
                    <div
                        className={(maxPage <= 1 ? "hide" : "")}
                    >
                        <Pagination
                            style={{ display: "flex", justifyContent: "flex-end" }}
                            count={maxPage}
                            value={page}
                            onChange={(_, value) => {
                                SetPage(value);
                            }}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <ProfessionsTable search={search}/>
                </TabPanel>
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to={location?.state?.from ? location.state.from : `/groups`}
                />
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div>
                    <div className="skeleton-title skeleton-shimmer"/>
                    <div className="skeleton-small-text skeleton-shimmer"/>
                </div>
                <br/>
                <div className="w100 flex jcsb">
                    <div className="skeleton-base skeleton-shimmer" style={{width: "50%"}}/>
                    <div className="skeleton-medium-button skeleton-shimmer"/>
                </div>
                <br/>
                <div className="skeleton-base skeleton-shimmer w100" style={{height: "10em"}}/>
            </div>
        </div>
    );
}

export default ViewGroup;
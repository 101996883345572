import React, { useEffect, useState } from "react";
import { Form, FormControl as Input } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { MdAirlineSeatReclineNormal } from "react-icons/md";
import { useHistory, useParams } from "react-router";
import Request from "../../../Request";
import Attention from "../../ReusableComponents/Attention";
import DefaultButton from "../../ReusableComponents/DefaultButton";

const EditProfession = props => {

    const [name, SetName] = useState("");

    const [loading, SetLoading] = useState(false);
    const [firstLoading, SetFirstLoading] = useState(false);

    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const {id} = useParams();
    const history = useHistory();

    const Edit = async () => {

        if (!name) {
            SetPopup(true);
            SetPopupMessage("O campo nome não pode estar em branco");
        }

        let form = new FormData();
        form.append("profession_id", id);
        form.append("name", name);

        SetLoading(true);
        let response = await Request.Post("professions/update", form);
        SetLoading(false);

        if (response.status) history.push({
            pathname: "/professions",
            state: {
                snackbar: true,
                message: response.message
            }
        });
        else if (response.status === false) {
            SetPopup(true);
            SetPopupMessage(response["message"]);
        }
    }

    const GetData = async () => {
        SetLoading(true);
        let response = await Request.Get(`professions/${id}`);
        if (!response) return;

        SetName(response.name);

        SetLoading(false);
        SetFirstLoading(true);
    }

    useEffect(() => {
        if (!id || id == "") history.push("/groups");
        GetData();
    }, []);

    if (!firstLoading) return <Skeleton/>
    return (
        <div className="card">
            <div className="card-body">
                <SweetAlert
                    show={popup}
                    title="Atenção!"
                    onConfirm={() => {
                        SetPopup(false);
                    }}
                    confirmBtnText="Ok"
                >
                    {popupMessage}
                </SweetAlert>
                <h2 className="title">Editar informações da profissão</h2>
                <p className="text-muted">Aqui é possível editar as informações das profissões</p>
                <br />
                <Form className="margin-bottom">
                    <Form.Label>Nome <Attention/></Form.Label>
                    <Input 
                        placeholder="Nome"
                        value={name}
                        onChange={(e) => SetName(e.target.value)}
                        className="margin-bottom"
                    />
                </Form>
                <br/>
                <div className="w100 inline-flex jcsb margin-top-1">
                    <DefaultButton
                        text="Voltar"
                        to="/professions"
                        bg="secondary"
                    />

                    <DefaultButton
                        text="Salvar alterações"
                        bg="confirm"
                        onClick={Edit}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div className="card">
            <div className="card-body">
            <h2 className="title">Editar informações da profissão</h2>
                <p className="text-muted">Aqui é possível editar as informações das profissões</p>
                <br />
                <Form.Label>Nome <Attention/></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{height: "2em"}}/>
                <br/>
                <div className="flex w100 jcsb">
                    <div className="skeleton-small-button skeleton-shimmer"/>
                    <div className="skeleton-medium-button skeleton-shimmer"/>
                </div>
            </div>
        </div>
    );
}

export default EditProfession;
import React, { useState } from "react";
import { Form, FormControl as Input } from "react-bootstrap";
import Request from "../../../Request";
import Attention from "../../ReusableComponents/Attention";
import DefaultButton from "../../ReusableComponents/DefaultButton";

import { useHistory } from "react-router";

const CreateGroup = props => {

    const [name, SetName] = useState("");
    const [description, SetDescription] = useState("");

    const [loading, SetLoading] = useState(false);

    const history = useHistory();

    const Create = async () => {
        let form = new FormData();
        form.append("name", name);
        form.append("description", description);

        SetLoading(true);
        let response = await Request.Post("usergroup/create", form);
        if (!response) return;

        if (response["status"]) history.push("/groups");

        SetLoading(false);

    }

    return (
        <div className="card">
            <div className="card-body">
                <h2 className="title">Cadastrar grupo de usuários</h2>
                <p className="text-muted">Aqui são cadastrados todos os grupos de usuários do sistema</p>
                <br />
                <Form className="margin-bottom">
                    <Form.Label>Nome <Attention/></Form.Label>
                    <Input 
                        placeholder="Nome"
                        value={name}
                        onChange={(e) => SetName(e.target.value)}
                        className="margin-bottom"
                    />
                    <Form.Label>Descrição <Attention/></Form.Label>
                    <Input 
                        placeholder="Descrição"
                        value={description}
                        onChange={(e) => SetDescription(e.target.value)}
                        className="margin-bottom"
                        as="textarea"
                    />
                </Form>
                <br/>
                <div className="w100 inline-flex jcsb margin-top-1">
                    <DefaultButton
                        text="Voltar"
                        to="/groups"
                        bg="secondary"
                    />

                    <DefaultButton
                        text="Criar grupo de usuários"
                        bg="confirm"
                        onClick={Create}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}

export default CreateGroup;
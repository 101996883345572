import React, { useEffect, useState } from "react";

import { Link, useHistory, useParams } from "react-router-dom";

import { Button, FormControl as Input } from "react-bootstrap";
import AddIcon from '@material-ui/icons/Add';
import OnlyAdmin from "../../../ReusableComponents/OnlyAdmin";
import Request from "../../../../Request";
import { CircularProgress, IconButton, Snackbar, TextField, Tooltip } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Search from "../../../ReusableComponents/Search";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";

const abortController = new AbortController();

const UsersNotInCourse = () => {

    const [users, SetUsers] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [snackbar, SetOpenSnackbar] = useState(false);
    const [snackbar_message, SetMessageSnackbar] = useState("");
    const [search, SetSearch] = useState('');

    const [toAddUsers, SetToAddUsers] = useState([]);

    const { course_id } = useParams();

    const history = useHistory();

    const GetUsers = async () => {
        const token = localStorage.getItem("token");
        if (!token) return;

        SetLoading(true);

        let response = await Request.Get(`usercourse/course/not-in/${course_id}`, { "Authorization": `Bearer ${token}` });

        if (!response) return;

        SetUsers(response);
        SetLoading(false);
    }

    const SearchUsers = async () => {
        if (search == "") {
            GetUsers();
            return;
        }
        const token = localStorage.getItem("token");
        if (!token) return;

        SetLoading(true);
        let response = await Request.Get(`usercourse/course/not-in/${course_id}/search/${search}`, { "Authorization": `Bearer ${token}` });

        if (!response) return;

        SetUsers(response);
        SetLoading(false);
    }

    const UpdateUserList = (user_id) => {
        let toAddUsersTemp = toAddUsers;
        console.log(toAddUsersTemp)

        if (!toAddUsersTemp.find(x => x.id == user_id))
            toAddUsersTemp.push(users.find(x => x.id == user_id));
        console.log(toAddUsersTemp)
        SetToAddUsers([...toAddUsersTemp]);
        SetSearch("");
    }

    const RemoveUserFromUserList = index => {
        let toAddUsersTemp = toAddUsers;
        toAddUsersTemp.splice(index, 1);
        SetToAddUsers([...toAddUsersTemp]);
    }

    const AddUserToCourse = async () => {
        const token = localStorage.getItem("token");
        if (!token) return;

        const form = new FormData();
        for (let i = 0; i < toAddUsers.length; i++) form.append("user_id[]", toAddUsers[i]["id"]);
        form.append("course_id", course_id);
        SetLoading(true);
        let response = await Request.Post("usercourse/add-user", form, { "Authorization": `Bearer ${token}` });
        if (!response) return;
        else if (response["status"]) {
            SetOpenSnackbar(true);
            SetMessageSnackbar("Usuário adicionado ao curso");
        }
        SetLoading(false);

        history.push({
            pathname: `/courses/course/${course_id}`,
            state: {
                snackbar: {
                    show: true,
                    message: (toAddUsers.length == 1 ? "Usuário adicionado com sucesso" : "Usuários adicionados com sucesso")
                }
            }
            ,search:'?'+new URLSearchParams({tab: '1'}).toString()
        });
    }

    useEffect(() => {
        SearchUsers();
    }, [search]);

    useEffect(() => {
        GetUsers();
    }, []);

    useEffect(() => {
        console.log(users.filter(user => !toAddUsers.map(value => value["id"]).includes(user["id"])))
    })

    return (
        <div className="card">
            <div className="card-body">
                <h2 className="title">Adicionar aluno ao curso</h2>
                <p className="text-muted">Aqui são adicionados os usuários ao curso em questão</p>
                <br />
                <div className="flex jcsb flex-wrap margin-bottom">
                    <Autocomplete
                        id="add-user-field"
                        disableCloseOnSelect={false}
                        blurOnSelect
                        options={users.filter(user => !toAddUsers.map(value => value["id"]).includes(user["id"]))}
                        value={toAddUsers}
                        multiple
                        getOptionSelected={(option,value) => option.id ==value.id}

                        getOptionLabel={option => option.name ? option.name : ""}
                        renderOption={(option, i) => (
                            <div key={i} onClick={(e) => UpdateUserList(option["id"])} className="flex fdrow jcsb w100 align-center" >
                                {option.name}
                                {/* <CircularProgress className={loading ? "" : "hide"} style={{width: "20px", height: "20px"}}/> */}
                                {/* <DefaultButton
                                    icon={<AddIcon size={17} style={{color: "white"}}/>}
                                    // onClick={() => AddUserToCourse(option["id"])}
                                    onClick={() => UpdateUserList(option["id"])}
                                    title="Adicionar usuário ao curso"
                                    bg="confirm"
                                    padding={0}
                                    width="2.2em"
                                    height="2.2em"
                                /> */}
                            </div>
                        )}
                        style={{ width: "100%" }}
                        noOptionsText={(
                            <p>Nenhum usuário encontrado</p>
                        )}
                        renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                                <Input
                                    {...params.inputProps}
                                    placeholder="Procurar alunos que não estão no curso"
                                    autoFocus
                                    value={search}
                                    onChange={(e) => SetSearch(e.target.value)}
                                />
                            </div>
                        )}
                    />
                </div>
                <div className="table-responsive margin-bottom">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                toAddUsers.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{value["name"]}</td>
                                            <td>{value["email"]}</td>
                                            <td>
                                                <DefaultButton
                                                    icon={<MdDelete size={17} color="white" />}
                                                    bg="danger"
                                                    onClick={() => RemoveUserFromUserList(index)}
                                                    title="Remover usuário da lista"
                                                    width="2.2em"
                                                    height="2.2em"
                                                    padding="0"
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    <div className="w100 flex align-center jcc">
                        {toAddUsers.length == 0 && !loading ? "Nenhum usuário selecionado" : ""}
                    </div>
                </div>
                <div className="w100 flex align-center jcc">
                    <CircularProgress className={loading ? "" : "hide"} style={{ width: "30px", height: "30px" }} />
                </div>
                <div className="w100 inline-flex jcsb mt-2">
                    <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to={`/courses/course/${course_id}`}
                        search={'?'+new URLSearchParams({tab: 1}).toString()}
                    />
                    <DefaultButton
                        bg="confirm"
                        text={toAddUsers.length <= 1 ? "Adicionar usuário" : "Adicionar usuários"}
                        onClick={() => AddUserToCourse()}
                        loading={loading}
                        disabled={toAddUsers.length == 0}
                    />
                </div>
            </div>
            <Snackbar
                open={snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                autoHideDuration={3000}
                onClose={() => SetOpenSnackbar(false)}
                message={snackbar_message}
                action={
                    <IconButton onClick={() => SetOpenSnackbar(false)}>
                        <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                }
            />
        </div>
    );
}

export default UsersNotInCourse;
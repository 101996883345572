import React, { useEffect, useRef, useState } from "react";

const ContentDotOverflow = props => {

    const [overflow_text, SetText] = useState(props.children);

    const div_ref = useRef(null);

    const TextHandler = () => {
        if (!div_ref.current) return;
        if (props.children === null) return;
        let width = div_ref.current.getBoundingClientRect().width;
        let height = div_ref.current.getBoundingClientRect().height;
        let fontSize = parseInt(window.getComputedStyle(div_ref.current).getPropertyValue("font-size"));
        let char_limit = (width / fontSize) * (height / fontSize);
        
        SetText(props.children.substring(0, char_limit) + (props.children.length > char_limit ? "..." : ""));
    };

    useEffect(() => {
        TextHandler();
        window.addEventListener("resize", TextHandler);
    }, []);

    return (
        <props.element>
            <div className="div-description" style={{textAlign: "center"}} ref={div_ref}>{overflow_text}</div>
        </props.element>
    );
}

export default ContentDotOverflow;
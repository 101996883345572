import React, { useEffect, useState } from "react";

import { useParams } from "react-router";

import { Form, FormControl as Input, Button, Spinner } from "react-bootstrap";

import ImageLoaderInput from "../../ReusableComponents/ImageLoaderInput";
import LoadingScreen from "../../ReusableComponents/LoadingScreen";
import Request from "../../../Request";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import Attention from "../../ReusableComponents/Attention";
import { MdDelete } from "react-icons/md";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress } from "@material-ui/core";

const EditCourse = props => {
    const [image, SetImage] = useState("");
    const [image_path, SetPath] = useState("");
    const [name, SetName] = useState("");
    const [description, SetDescription] = useState("");
    const [loading, SetLoading] = useState(false);
    const [firstLoading, SetFirstLoading] = useState(false);
    const [loading_screen, SetLoadingScreen] = useState(false);
    const [loading_users, SetLoadingUser] = useState(false);

    const [selectUser, SetSelectUser] = useState({});
    const [carga_horaria, SetCargaHoraria] = useState('');
    const [users, SetUsers] = useState([]);

    const [search, SetSearch] = useState('');

    const [componentdidmount, SetComponentDidMount] = useState(false);

    const { id } = useParams();

    const history = useHistory();
    const GetUsers = async (search, screen = false)=> {
        const token = localStorage.getItem("token");
        if (!token) return;
        
        SetUsers([]);
        SetLoadingUser(true);

        let response = await Request.Get(`register/admins?search=${search}`, { "Authorization": `Bearer ${token}` });

        if (!response) return;
        console.log(response);
        SetLoadingUser(false);
        SetUsers(response);
        SetLoadingScreen(false);
    }

    const RequestData = async () => {
        SetLoadingScreen(true);
        let token = localStorage.getItem("token");
        if (!token) return;
        SetLoading(true);
        let response = await Request.Get(`courses/${id}`, { "Authorization": `Bearer ${token}` });
        SetName(response.name);
        SetDescription(response.description);
        SetCargaHoraria(response.carga_horaria);
        SetSelectUser(response.responsavel);

        SetPath(response.image);
        SetLoading(false);
        GetUsers(search);
        SetFirstLoading(true);
    }

    const EditData = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let form = new FormData();
        form.append("id", id);
        form.append("name", name);
        if (image !== undefined && image != "" && image !== null) form.append("image", image);
        form.append("description", description);
        form.append("carga_horaria", carga_horaria);
        form.append("responsavel_id", selectUser.id==undefined?'':selectUser.id);
        SetLoading(true);
        let response = await Request.Post("courses/edit", form, { "Authorization": `Bearer ${token}` });
        if (response["status"]) {
            history.push({
                pathname: `/courses/list`,
                state: {
                    snackbar: {
                        show: true,
                        message: "Curso editado com sucesso"
                    }
                }
            });
            return;

        }

        SetLoading(false);
    }

    useEffect(() => {
        if (!componentdidmount) {
            SetComponentDidMount(true);
            RequestData();
        }
    });

    if (!firstLoading) return <SkeletonEditCourse/>;
    return (
        <div className="courses-edit">
            <div className="card">
                <div className="card-body">
                    <h3 className="margin-bottom title">Editar informações do curso</h3>
                    <p className="text-muted">Aqui você, sendo administrador, pode editar os dados do curso</p>
                    <Form style={{ position: "relative" }}>
                        <Form.Label>Imagem do curso<Attention /></Form.Label>
                        <ImageLoaderInput path={image_path} ImageChange={(image) => SetImage(image)} />
                        <Form.Label>Nome do curso<Attention /></Form.Label>
                        <Input
                            value={name}
                            placeholder="Nome do curso"
                            onChange={(e) => SetName(e.target.value)}
                            required
                            className="margin-bottom"

                        />

                        <Form.Label>Descrição do curso <Attention /></Form.Label>
                        <Input
                            value={description}
                            onChange={(e) => SetDescription(e.target.value)}
                            as="textarea"
                            placeholder="Descrição do curso"
                            className="margin-bottom"
                        />


                        <Form.Label>Carga horária (Em horas) <Attention /></Form.Label>
                            <Input
                                value={carga_horaria}
                                onChange={(e) => SetCargaHoraria(e.target.value)}
                                placeholder="Carga horária do curso (Em horas)"
                                className="margin-bottom"
                                type="number"
                                min="1"
                            />

                            <div className="flex jcsb flex-wrap margin-bottom">
                                <Form.Label>Responsável do Curso <Attention /></Form.Label>

                                <Autocomplete
                                    id="add-user-field"
                                    disableCloseOnSelect={false}
                                    blurOnSelect
                                    options={users}
                                    value={[selectUser]}
                                    multiple
                                    getOptionLabel={option => option.name ? option.name : ""}
                                    getOptionSelected={(option, value) => option.id == value.id}

                                    renderOption={(option, i) => (
                                        <div key={i} onClick={(e) => {
                                            SetSelectUser(option);
                                            SetSearch('');
                                            GetUsers('', false);

                                        }} className="flex fdrow jcsb w100 align-center" >
                                            {option.name}
                                            {/* <CircularProgress className={loading ? "" : "hide"} style={{width: "20px", height: "20px"}}/> */}
                                            {/* <DefaultButton
                                    icon={<AddIcon size={17} style={{color: "white"}}/>}
                                    // onClick={() => AddUserToCourse(option["id"])}
                                    onClick={() => UpdateUserList(option["id"])}
                                    title="Adicionar usuário ao curso"
                                    bg="confirm"
                                    padding={0}
                                    width="2.2em"
                                    height="2.2em"
                                /> */}
                                        </div>
                                    )}
                                    style={{ width: "100%" }}
                                    noOptionsText={(
                                        loading_users == true ? <CircularProgress style={{ width: "20px", height: "20px" }} /> : <p>Nenhum usuário encontrado</p>
                                    )}
                                    renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                            <Input
                                                {...params.inputProps}
                                                placeholder="Procure por um usuário para ser o responsável do curso"
                                                value={search}
                                                onChange={(e) => {
                                                    SetSearch(e.target.value)
                                                    GetUsers(e.target.value, false);
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="table-responsive margin-bottom">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>E-mail</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {selectUser['id'] != undefined && <tr >
                                            <td>{selectUser["name"]}</td>
                                            <td>{selectUser["email"]}</td>
                                            <td>
                                                <DefaultButton
                                                    icon={<MdDelete size={17} color="white" />}
                                                    bg="danger"
                                                    onClick={() => {
                                                        SetSelectUser({})
                                                    }}
                                                    title="Remover usuário da lista"
                                                    width="2.2em"
                                                    height="2.2em"
                                                    padding="0"
                                                />
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                                <div className="w100 flex align-center jcc">
                                    {selectUser['id'] == undefined && "Nenhum responsável selecionado"}
                                </div>
                            </div>

                        {/* <Form.Label>Área do curso</Form.Label>
                        <select className="form-select" value={this.state.section} onChange={(e) => this.setState({section: e.target.value})}>
                            <option value="">Área do curso</option>
                            <option value="opcaoteste">para teste</option>
                        </select> */}
                        <div className="w100 inline-flex jcsb margin-top-1">
                            <DefaultButton
                                bg="secondary"
                                icon={<RiArrowGoBackLine />}
                                text="Voltar"
                                to={`/courses/list`}
                            />
                            <DefaultButton
                                loading={loading}
                                onClick={EditData}
                                bg="confirm"
                                text="Salvar alterações"
                            />
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}

const SkeletonEditCourse = () => {
    return (
        <div className="card">
            <div className="card-body">
                <h3 className="margin-bottom title">Editar informações do curso</h3>
                <p className="text-muted">Aqui você, sendo administrador, pode editar os dados do curso</p>
                <Form.Label>Imagem do curso <Attention /></Form.Label>
                <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "9em", marginRight: "1em"}}/>

                <Form.Label>Nome do curso <Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{height: "2em"}}/>

                <Form.Label>Descrição do curso<Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{height: "2em"}}/>

                <Form.Label>Carga horária (Em horas)<Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{height: "2em"}}/>

                <Form.Label>Responsável do Curso<Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{height: "2em"}}/>

                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{height: "4em"}}/>

                <div className="flex w100 jcsb">
                    <div className="skeleton-small-button skeleton-shimmer"/>
                    <div className="skeleton-medium-button skeleton-shimmer"/>
                </div>
            </div>
        </div>
    );
}

export default EditCourse;
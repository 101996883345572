import React, { Component } from "react";

import { Form, FormControl as Input, Button, InputGroup, Spinner } from "react-bootstrap";

import { Link, withRouter } from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";

import { ImImages } from "react-icons/im";
import { RiArrowGoBackLine } from "react-icons/ri";

import JoditEditor from "jodit-react";
import Request from "../../../../Request";
import { API_URL, URL } from "../../../../variables";
import { logout } from "../../../../actions/AppActions";

import { connect } from "react-redux";

const config = {
    language: 'pt_br',
    toolbarButtonSize: 'large',
    "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol",
    uploader: {
        url: API_URL + 'courses/questoes/upload_image',  //your upload api url
        insertImageAsBase64URI: false, //not inster base64
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
        headers: { "accept": `application/json` },
        filesVariableName: function (t) {
            return 'imagem';
        }, //"files",
        withCredentials: false,
        pathVariableName: 'path',
        //   format: 'json',
        method: 'POST',
        prepareData: function (formdata) {

            return formdata;
        },
        isSuccess: function (e) {
            console.log(e);
            return 'Upload realizado com sucesso';
        },
        getMessage: function (e) {
            console.log(e);

            return e;
        },
        process: function (resp) { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
            let files = [];
            console.log(resp);
            files.unshift(URL + 'storage/' + resp.imagem);
            return {
                files: files,

            };
        },
        error: function (elem, e) {
            // elem.j.e.fire('errorMessage', e.message, 'error', 4000);
            console.log(e);
            console.log(elem);
        },
        defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
            if (resp.files && resp.files.length) {
                resp.files.forEach((filename, index) => { //edetor insertimg function
                    this.selection.insertImage(filename);
                });
            }
            // debugger;

        },
        defaultHandlerError: function (elem, e) {
            // elem.j.e.fire('errorMessage', e.message);
            console.log(e);
            console.log(elem);

        },
        //   contentType: function (e) {
        //     return (

        //       'application/x-www-form-urlencoded; charset=UTF-8'
        //     );
        //   },
    },
}

class EditAvaliacao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            section: "",
            image: "",
            loading: false,
            loading_screen: true,
            show_sweet: false,
            message_sweet: "",
            success: false,
            curso: {name:''},
            questao: {},

            enunciado: '',
            alternativa_a: '',
            alternativa_b: '',
            alternativa_c: '',
            alternativa_d: '',
            order: 1,
            correta:0,
            success:false,
            error:false,
            titleSweet:'',
            peso:1
        };
        this.editor = React.createRef();
        this.input_image = React.createRef();

        this.Submit = this.Submit.bind(this);
    }

    async GetQuestao(questao_id) {

        let response = await Request.Get(`courses/questoes/get_questao/${questao_id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message']=='Unauthenticated.') {
            this.props.logout();
            return;
        }

        this.setState({ questao: response.questao, 
            loading_screen: false,
            enunciado: response.questao.enunciado,
            alternativa_a: response.questao.alternativas['a)'].texto,
            alternativa_b: response.questao.alternativas['b)'].texto,
            alternativa_c: response.questao.alternativas['c)'].texto,
            alternativa_d: response.questao.alternativas['d)'].texto,
            order: response.questao.order,
            correta:response.questao.correta,
            curso:response.questao.curso,
            peso:response.questao.peso

        });

    }

    async Submit(e) {
        e.preventDefault();
        if (this.state.loading == true) {
            return;
        }
        let token = localStorage.getItem("token");
        if (!token) return;

        let form = new FormData();
        form.append("enunciado", this.state.enunciado);
        form.append("alternativa_a", this.state.alternativa_a);
        form.append("alternativa_b", this.state.alternativa_b);
        form.append("alternativa_c", this.state.alternativa_c);
        form.append("alternativa_d", this.state.alternativa_d);
        form.append("questao_id", this.state.questao.id);
        form.append("order", this.state.order);
        form.append("correta", this.state.correta);
        form.append("peso", this.state.peso);


        this.setState({ loading: true });

        let response = await Request.Post("courses/questoes/update", form, { "Authorization": `Bearer ${token}` });
        console.log(response);
        if (response["errors"]) {
            this.setState({
                show_sweet: true,
                message_sweet: response["errors"][Object.keys(response["errors"])[0]],
                loading: false,
                success:false,
                titleSweet:'Erro!',
                error:true,
            });
        } else if (response["status"]) {
            this.setState({
                show_sweet: true,
                success:true,
                error:false,
                message_sweet: response["message"],
                loading: false,
                titleSweet:'Sucesso',
                success: true
            });
        } else this.setState({ loading: false })
    }

    componentDidMount() {
        this.GetQuestao(this.props.match.params.id);
    }

    render() {


        return (
            <div className="courses">
                <SweetAlert
                    error={this.state.error}
                    success={this.state.success}
                    title={this.state.titleSweet}
                    show={this.state.show_sweet}
                    onConfirm={() => {
                        this.setState({ show_sweet: false });
                        if (this.state.success) this.props.history.push(`/courses/course/${this.state.curso.id}?tab=4`);
                    }}
                >
                    {this.state.message_sweet}
                </SweetAlert>
                <div className="card">
                    <div className="card-body">
                        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar questão</h4>
                        <p style={{ fontSize: '1rem', color: 'black' }}>Edite uma questão do curso {this.state.curso.name}</p>
                        <br />
                        {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                            <div className="spinner-border text-primary" role="status"  >
                            </div>
                        </div></div>}
                        {this.state.loading_screen == false && <Form style={{ position: "relative" }} onSubmit={this.Submit}>
                        <Form.Label>Peso da questão <b style={{ color: 'red', fontWeight: "bold" }}>*</b></Form.Label>
                            <Input
                                value={this.state.peso}
                                onChange={(e) => this.setState({ peso: e.target.value })}
                                placeholder="Peso da questão"
                                className="margin-bottom"
                                type="number"
                                min="1"
                            />
                            <Form.Label>Enunciado <b style={{ color: 'red', fontWeight: "bold" }}>*</b></Form.Label>
                            <JoditEditor
                                ref={this.editor}
                                value={this.state.enunciado}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => this.setState({ enunciado: newContent })} // preferred to use only this option to update the content for performance reasons
                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                            />
                            <Form.Label className="mt-4">Alternativa a) <b style={{ color: 'red', fontWeight: "bold" }}>*</b></Form.Label>
                            <JoditEditor
                                ref={this.editor2}
                                value={this.state.alternativa_a}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => this.setState({ alternativa_a: newContent })} // preferred to use only this option to update the content for performance reasons
                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                            />

                            <Form.Label className="mt-4">Alternativa b) <b style={{ color: 'red', fontWeight: "bold" }}>*</b></Form.Label>
                            <JoditEditor
                                ref={this.editor2}
                                value={this.state.alternativa_b}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => this.setState({ alternativa_b: newContent })} // preferred to use only this option to update the content for performance reasons
                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                            />
                            <Form.Label className="mt-4">Alternativa c) <b style={{ color: 'red', fontWeight: "bold" }}>*</b></Form.Label>
                            <JoditEditor
                                ref={this.editor2}
                                value={this.state.alternativa_c}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => this.setState({ alternativa_c: newContent })} // preferred to use only this option to update the content for performance reasons
                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                            />
                            <Form.Label className="mt-4">Alternativa d) <b style={{ color: 'red', fontWeight: "bold" }}>*</b></Form.Label>
                            <JoditEditor
                                ref={this.editor2}
                                value={this.state.alternativa_d}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => this.setState({ alternativa_d: newContent })} // preferred to use only this option to update the content for performance reasons
                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                            />
                            <Form.Label className="mt-4">Qual a alternativa correta? <b style={{ color: 'red', fontWeight: "bold" }}>*</b></Form.Label>

                            <Input
                                as="select"
                                value={this.state.correta}
                                onChange={(e)=>{
                                    this.setState({correta:e.target.value});
                                }}
                            >
                                <option value="0">a)</option>
                                <option value="1">b)</option>
                                <option value="2">c)</option>
                                <option value="3">d)</option>
                            </Input>
                            {/* <Form.Label className="mt-4">Ordem <b style={{ color: 'red', fontWeight: "bold" }}>*</b></Form.Label>
                            <Input
                                value={this.state.order}
                                placeholder="Ordem"
                                onChange={(e) => this.setState({ order: e.target.value })}
                                required
                                type='number'
                                min={0}
                                className="margin-bottom"
                            /> */}
                            {this.state.loading == false && <div className="w100 inline-flex jcsb mt-3">
                                <Link to={`/courses/course/${this.state.curso.id}?tab=4`}>
                                    <Button variant="secondary"><RiArrowGoBackLine /> Voltar</Button>
                                </Link>
                                <Button
                                    type="submit"
                                    disabled={this.state.loading ? true : false}
                                    variant="success"
                                >
                                    Salvar questão
                                </Button>
                            </div>}
                            {this.state.loading == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center mt-3" >
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}
                        </Form>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps,{logout})(withRouter(EditAvaliacao));
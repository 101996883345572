import React, { useEffect, useRef, useState } from "react";

import { Button, Chip } from "@material-ui/core";
import { Form, FormControl as Input, Card,ProgressBar } from "react-bootstrap";
import DefaultButton from "./DefaultButton";

const InputFile = ({ onChange, accept = "",HandleExistingDocDeletion=()=>{},existing_docs =[],changeExisting}) => {

    const [files, SetFiles] = useState([]);
    const [size, SetSize] = useState(0);

    const input = useRef();

    const HandleInputReceive = () => {
        let current_files = input.current.files;
        let temp_files = files;
        for (let i = 0; i < current_files.length; i++) {
            current_files[i].name_file = current_files[i].name;
            temp_files.push(current_files[i]);
        }
        SetFiles([...temp_files]);
        input.current.value = "";
    }

    const HandleChipDeletion = index => {
        let temp_files = files;
        temp_files.splice(index, 1);
        SetFiles([...temp_files]);
    }

    useEffect(() => {
        onChange && onChange(files);

        let temp_size = 0;
        for (let i = 0; i < files.length; i++) {
            temp_size += files[i].size;
        }
        SetSize(temp_size);
    }, [files]);

    return (
        <div className="input-select">
            <div id="chips" className='mb-2'>
                {existing_docs.map((value, i) => (
                    <div>
                    <Chip
                        key={i}
                        // label={'Arquivo '+(i+1)}
                        label={value["name"]}
                        onDelete={() => HandleExistingDocDeletion(value["id"], i)}
                        style={{
                            // maxWidth: "10em",
                            width:250,
                            marginBottom: "5px"
                        }}
                    />
                     <Input  placeholder="Nome do arquivo"  onChange={(e) => {
                            let files_=[...existing_docs];
                            files_[i].name=e.target.value;
                            changeExisting([...files_]);
                        }} style={{width:250}}  value={value["name"]}/>
                    </div>
                ))
                }
                {
                    files.map((value, index) => {
                        return (
                        <div>
                        <Chip
                            id={index}
                            key={index}
                            label={value["name_file"]}
                            onDelete={(index) => HandleChipDeletion(index)}
                            style={{
                                // maxWidth: "10em",
                                width:250,
                                marginBottom: "5px"
                            }}
                            className="video-new"
                        />
                        <Input  placeholder="Nome do arquivo"  onChange={(e) => {
                            let files_=[...files];
                            files_[index].name_file=e.target.value;
                            SetFiles([...files_]);
                        }} style={{width:250}} value={value["name_file"]}/>

                        </div>
                        
                        )
                    })
                }
            </div>

            <div className={(size == 0 ? "hide" : "")}>
                <p className="text-muted">Tamanho total dos arquivos: <b>{(size / 1000000).toFixed(2)} MB</b></p>
            </div>
            <input
                ref={input}
                className="hide"
                type="file"
                multiple
                accept={accept}
                onChange={() => HandleInputReceive()}
            />

            <DefaultButton
                text="Selecionar arquivos"
                bg="primary"
                onClick={() => input.current.click()}
                style={{ marginBottom: "10px" }}
            />


        </div>
    );

}

export default InputFile;
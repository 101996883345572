import React, { useEffect, useRef, useState } from "react";

import { Redirect, useParams } from "react-router";
import Request from "../../../Request";
import { STORAGE_URL } from "../../../variables";
import LessonCard from "../Lessons/LessonCard";
import { useMutation } from "react-query";

import LoadingScreen from "../../ReusableComponents/LoadingScreen";
import { GiRead } from "react-icons/gi";
import { Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Search from "../../ReusableComponents/Search";
import { FaVideo } from "react-icons/fa";
import { RiArrowGoBackLine } from "react-icons/ri";
import TextReadMore from "../../ReusableComponents/TextReadMore";
import OnlyAdmin from "../../ReusableComponents/OnlyAdmin";
import { MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Reorder, AnimatePresence } from "framer-motion";

import UsersInCourse from "./Users/UsersInCourse";
import UsersNotInCourse from "./Users/UsersNotInCourse";
import Avaliacao from "./Avaliacao/Avaliacao";
import Prova from "./Avaliacao/Prova";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import Certificates from "./Certificates";
import SubRequestTable from "./SubRequestTable";
import TabPanel from "../../ReusableComponents/TabPanel";
import { logout, AdminAsUser } from "../../../actions/AppActions";
import AddToCourse from "./Group/AddToCourse";
import ListGroups from "./Group";
import Statistic from "./Statistic";
import FeedbackPage from "./Feedback";
import SaveOrderButton from "./SaveOrderButton";
import SweetAlert from "react-bootstrap-sweetalert";

const CoursePage = (props) => {
  const [lesson_cards, SetLessonCards] = useState([]);
  const [show_success, setShowSuccess] = useState(false);
  const [[lessons, tempLessons], SetLessons] = useState([[], []]);
  const [course_name, SetName] = useState("");
  const [responsavel, SetResponsavel] = useState("");

  const [course_description, SetDescription] = useState("");
  const [banner_url, SetBannerUrl] = useState("");
  const [loading, SetLoading] = useState(false);
  const [firstLoading, SetFirstLoading] = useState(false);

  const [reload_users, setReload] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const [showSnackbar, SetShowSnackbar] = useState(false);
  const [snackbarMessage, SetSnackbarMessage] = useState("");
  const [snackbarSeverity, SetSnackbarSeverity] = useState("");

  const user_id = useSelector((store) => store.AppReducer.user["id"]);
  let { id } = useParams();

  const history = useHistory();
  const location = useLocation();
  const [path, setPath] = useState("");

  const dispatch = useDispatch();

  const GetLessons = async (search) => {
    let token = localStorage.getItem("token");
    if (!token) return;
    let response;
    SetLoading(true);
    if (search)
      response = await Request.Get(`lessons/search/${id}/${search}`, {
        Authorization: `Bearer ${token}`,
      });
    else
      response = await Request.Get(`lessons/course/${id}/${user_id}`, {
        Authorization: `Bearer ${token}`,
      });
    console.log(response === undefined || !response["status"]);
    if (response === undefined || !response["status"]) {
      history.push("/courses/list");
      return;
    }

    if (response["message"] == "Unauthenticated.") {
      dispatch(logout());
      return;
    }

    SetLessons([response.lessons, response.lessons]);

    // let temp_cards = [];
    // for (let i = 0; i < response.lessons.length; i++) {
    //   let element = (
    //     <LessonCard
    //       title={response.lessons[i]["title"]}
    //       description={
    //         response.lessons[i]["description"]
    //           ? response.lessons[i]["description"]
    //           : ""
    //       }
    //       date_created={response.lessons[i]["created_at"]}
    //       path={response.lessons[i]["video"]}
    //       key={response.lessons[i]["id"]}
    //       id={response.lessons[i]["id"]}
    //       user_id={user_id}
    //       value={response.lessons[i]}
    //       update={() => GetLessons()}
    //       onDelete={() => {
    //         SetShowSnackbar(true);
    //         SetSnackbarMessage("Aula deletada com sucesso");
    //       }}
    //     />
    //   );

    //   temp_cards.push(element);
    // }

    // SetLessonCards(temp_cards);
    SetLoading(false);
    SetFirstLoading(true);
  };

  const orderMutation = useMutation(
    async () => {
      const form = new FormData();
      for (let i = 0; i < tempLessons?.length; i++)
        form.append("lessonsIds[]", tempLessons[i]?.id);

      //   return await Post("lessons/order", form);
      return await Request.Post(`lessons/order`, form, {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      });
    },
    {
      onSuccess: (data) => {
        const { message } = data || {};
        setShowSuccess(true);
      },
    }
  );

  const GetBanner = async () => {
    let token = localStorage.getItem("token");
    if (!token) return;
    if (!id || id == "undefined") return;
    let response = await Request.Get(`courses/${id}`, {
      Authorization: `Bearer ${token}`,
    });
    if (!response) return;
    if (response.length == 0) return;
    SetName(response["name"]);
    SetResponsavel(response["responsavel"]["name"]);

    if (response["description"]) SetDescription(response["description"]);
    SetBannerUrl(response["image"]);
    SetLoading(false);
  };

  useEffect(() => {
    if (id === undefined || id == "undefined") {
      history.push("/courses/list");
      return;
    }

    let tab = new URLSearchParams(location.search).get("tab");
    if (tab !== undefined && tab !== null) setActiveTab(parseInt(tab));
    else if (tab === null) setActiveTab(0);

    if (location.state && location.state.snackbar) {
      SetShowSnackbar(location.state.snackbar.show);
      SetSnackbarMessage(location.state.snackbar.message);
    }
    SetLoading(true);
    GetLessons();
    GetBanner();
  }, []);

  if (!firstLoading && loading) return <SkeletonCoursePage />;
  return (
    <div style={{ position: "relative" }}>
      <SweetAlert
        success
        title={"Sucesso!"}
        onConfirm={() => {
          setShowSuccess(false);
        }}
        // onCancel={this.onCancel.bind(this)}
        show={show_success}
        confirmBtnText="Ok"
        cancelBtnText="Cancelar"
        confirmBtnBsStyle="info"
        // cancelBtnBsStyle="success"
        showCancel={false}
      >
        Aulas reordenadas com sucesso!
      </SweetAlert>

      <div className="card">
        <div className="card-body">
          <div className="flex jcsb">
            <h4 className="header">{course_name}</h4>
          </div>
          <TextReadMore
            title="Responsável: "
            element="div"
            limit={1000}
            style={{ width: "100%", marginBottom: 10 }}
          >
            {responsavel}
          </TextReadMore>
          <TextReadMore
            title="Descrição: "
            element="div"
            limit={120}
            style={{ width: "100%" }}
          >
            {course_description}
          </TextReadMore>
          <br />
          <div className="w100 flex align-center fdcolumn margin-bottom">
            <div
              className="course-banner"
              style={{
                backgroundImage: `url(${
                  banner_url ? STORAGE_URL + banner_url : ""
                })`,
                backgroundSize: "cover",
                width: "12em",
                height: "12em",
                borderRadius: "7px",
              }}
            />
          </div>
          <br />
          <Tabs
            value={activeTab}
            onChange={(_, value) => {
              setActiveTab(value);
              history.push({ search: `?tab=${value}` });
            }}
            style={{ backgroundColor: "#ececf1" }}
            indicatorColor="primary"
            textColor="primary"
            variant={"scrollable"}
            scrollButtons={"on"}
          >
            <Tab label="Aulas" />
            <Tab label="Usuários" />
            <Tab label="Grupos" />
            <Tab label="Pedidos de inscrição" />
            <Tab label="Avaliação" />
            <Tab label="Acompanhamento" />
            <Tab label="Feedback" />
            <Tab label="Estatística do curso" />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <p className="text-muted">
              Aqui ficam as aulas do curso "{course_name}"
            </p>
            <div className="flex jcsb flex-wrap">
              <Search
                placeholder=" Pesquisar a aula pelo Nome da aula"
                ValueChange={(value) => GetLessons(value)}
              />
              {/* <OnlyAdmin
                                component={ */}
              <div>
                <DefaultButton
                  onClick={() => dispatch(AdminAsUser({ adminAsUser: true }))}
                  bg="primary"
                  text="Ver curso como aluno"
                  style={{
                    marginRight: "5px",
                  }}
                />
                <DefaultButton
                  to="/lesson/register"
                  routeState={{
                    course_id: id,
                  }}
                  bg="confirm"
                  text="Cadastrar nova aula"
                />
              </div>
              {/* //     }
                            // /> */}
            </div>
            <br />
            {/* <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th nowrap="">Nome da aula</th>
                                        <th>Descrição</th>
                                        <th nowrap="">Data de upload</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading && lesson_cards}
                                </tbody>
                            </table>
                            <div className="w100 flex align-center jcc">
                                {lesson_cards.length == 0 && !loading ? "Nenhuma aula cadastrada" : ""}
                            </div>
                        </div> */}
            <div className="table-responsive">
              <Reorder.Group
                as={"table"}
                className="table table-striped"
                axis={"y"}
                values={tempLessons}
                onReorder={(value) => SetLessons([lessons, value])}
                style={{
                  borderColor: "transparent",
                  overflow: "hidden",
                }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th nowrap="">Nome da aula</th>
                    <th>Descrição</th>
                    <th nowrap="">Data de upload</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                {/* <tbody>{!loading && lesson_cards}</tbody> */}
                <tbody>
                  {!loading &&
                    tempLessons.map((lesson, index) => (
                      <LessonCard
                        title={lesson["title"]}
                        description={
                          lesson["description"] ? lesson["description"] : ""
                        }
                        date_created={lesson["created_at"]}
                        path={lesson["video"]}
                        key={lesson["id"]}
                        id={lesson["id"]}
                        user_id={user_id}
                        value={lesson}
                        update={() => GetLessons()}
                        onDelete={() => {
                          SetShowSnackbar(true);
                          SetSnackbarMessage("Aula deletada com sucesso");
                        }}
                        index={index}
                      />
                    ))}
                </tbody>
              </Reorder.Group>
            </div>
            <div className="flex align-center jcc w100">
              <CircularProgress className={loading ? "" : "hide"} />
            </div>

            <AnimatePresence>
              {JSON.stringify(lessons) != JSON.stringify(tempLessons) && (
                <SaveOrderButton
                  onClick={() => {
                    orderMutation.mutate();
                    SetLessons([tempLessons, tempLessons]);
                  }}
                  onCancel={() => SetLessons([lessons, lessons])}
                />
              )}
            </AnimatePresence>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <UsersInCourse
              course_name={course_name}
              course_id={id}
              load={reload_users}
              OnUpdate={() => setReload(Math.random())}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            {/* <AddToCourse/> */}
            <ListGroups course_name={course_name} course_id={id} />
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            <p className="text-muted">
              Aqui ficam os pedidos de acesso ao curso "{course_name}"
            </p>
            <SubRequestTable
              course_id={id}
              ShowSnackbar={(message) => {
                SetShowSnackbar(true);
                SetSnackbarMessage(message);
              }}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={4}>
            <Avaliacao curso_id={id} curso_name={course_name} />
            {/* <Tab title="Prova" eventKey="prova">
                        <Prova curso_id={id} /> */}
          </TabPanel>
          <TabPanel value={activeTab} index={5}>
            <Certificates curso_id={id} curso_name={course_name} />
            {/* <Tab title="Prova" eventKey="prova">
                        <Prova curso_id={id} /> */}
          </TabPanel>
          <TabPanel value={activeTab} index={6}>
            <FeedbackPage course_id={id} />
          </TabPanel>
          <TabPanel value={activeTab} index={7}>
            <Statistic course_id={id} />
          </TabPanel>

          <div className="w100 inline-flex jcsb margin-top-1">
            <DefaultButton
              bg="secondary"
              text="Voltar"
              icon={<RiArrowGoBackLine />}
              to={`/courses/list`}
            />
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={2000}
        onClose={() => SetShowSnackbar(false)}
        open={showSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

const SkeletonCoursePage = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="skeleton-title" />
        <div className="skeleton-small-text skeleton-shimmer" />
        <div className="skeleton-small-text skeleton-shimmer" />
        <br />
        <div className="w100 flex jcc align-center">
          <div
            className="skeleton-base skeleton-shimmer"
            style={{ width: "12em", height: "12em" }}
          />
        </div>
        <br />
        <div
          className="w100 skeleton-base skeleton-shimmer margin-bottom"
          style={{ minHeight: "48px" }}
        />
        <div
          className="w100 skeleton-base skeleton-shimmer margin-bottom"
          style={{ minHeight: "5em" }}
        />
        <div className="skeleton-small-button skeleton-shimmer" />
      </div>
    </div>
  );
};

export default CoursePage;

import React, { Component } from "react";

import { withRouter } from "react-router";

import {
  Form,
  FormControl as Input,
  InputGroup,
  Button,
  Select,
  Spinner,
} from "react-bootstrap";

import PasswordInput from "../../ReusableComponents/PasswordInput";

import Request from "../../../Request";

import SweetAlert from "react-bootstrap-sweetalert";
import LoadingScreen from "../../ReusableComponents/LoadingScreen";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import ImageLoaderInput from "../../ReusableComponents/ImageLoaderInput";
import Attention from "../../ReusableComponents/Attention";
import SignatureCanvas from "react-signature-canvas";
import AssinaturaInput from "../../ReusableComponents/AssinaturaInput";
import PhoneInput from "../../ReusableComponents/PhoneInput";

class RegisterUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirm_password: "",
      random_password: false,
      role: "",
      name: "",
      image: "",
      show_error: false,
      error_message: "",
      loading: false,
      type_assinatura: "Imagem",
      assinatura: "",
      cargo: "",
      phone: "",
      profession: "",
      professions: [],
    };

    this.Submit = this.Submit.bind(this);
    this.CheckPassword = this.CheckPassword.bind(this);
    this.CheckRole = this.CheckRole.bind(this);
  }

  async Submit(e) {
    e.preventDefault();
    let token = localStorage.getItem("token");

    if (!token || !this.CheckRole() || !this.CheckPassword()) return;
    else if (this.state.role == "Aluno" && this.state.phone == "") {
      this.setState({
        show_error: true,
        error_message: "Coloque um número de telefone",
      });
      return;
    } else if (this.state.role == "Aluno" && this.state.profession == "") {
      this.setState({
        show_error: true,
        error_message: "Selecione uma profissão",
      });
      return;
    }
    this.setState({ loading: true });

    let form = new FormData();
    form.append("email", this.state.email);
    form.append("name", this.state.name);
    form.append("password", this.state.password);
    form.append("image", this.state.image);
    form.append("role", this.state.role);
    form.append("profession_id", this.state.profession);
    form.append("phone", this.state.phone);
    if (this.state.role == "Admin") {
      if (this.state.type_assinatura == "Assinar") {
        form.append("assinatura", this.sigCanvas.toDataURL("image/png"));
      } else {
        form.append("assinatura", this.state.assinatura);
      }
      form.append("cargo", this.state.cargo);
    }

    let response = await Request.Post("register", form, {
      Authorization: `Bearer ${token}`,
    });
    if (!response["status"]) {
      if (response["errors"]) {
        this.setState({
          show_error: true,
          error_message: response["errors"][Object.keys(response["errors"])][0],
          loading: false,
        });
      } else {
        this.setState({
          show_error: true,
          error_message: response["message"],
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
        email: "",
        name: "",
        password: "",
        confirm_password: "",
        role: "",
      });
      this.props.history.push({
        pathname: "/accounts/list",
        state: {
          snackbar: {
            show: true,
            message: "Usuário adicionado com sucesso",
          },
        },
      });
    }
  }

  async GetProfessions() {
    let token = localStorage.getItem("token");
    if (!token) return;

    let response = await Request.Get("professions/all", {
      Authorization: `Bearer ${token}`,
    });
    if (!response) return;

    this.setState({
      professions: response,
    });
  }

  CheckPassword() {
    if (this.state.password != this.state.confirm_password) {
      this.setState({
        show_error: true,
        error_message: "As senhas não correspondem",
      });
      return false;
    }
    return true;
  }

  CheckRole() {
    if (this.state.role == "") {
      this.setState({
        show_error: true,
        error_message: "Selecione algum tipo de usuário",
      });
      return false;
    }
    return true;
  }

  componentDidMount() {
    this.GetProfessions();
  }

  render() {
    return (
      <div>
        <SweetAlert
          show={this.state.show_error}
          title="Atenção!"
          onConfirm={() => this.setState({ show_error: false })}
        >
          <p>{this.state.error_message}</p>
        </SweetAlert>
        <div className="card">
          <div className="card-body">
            <h2 className="title">Cadastrar usuários</h2>
            <p className="text-muted">
              Aqui são cadastrados todos os usuários do sistema
            </p>
            <br />
            <Form.Label>Imagem de perfil</Form.Label>
            <ImageLoaderInput
              ImageChange={(image) => this.setState({ image })}
            />
            <Form onSubmit={this.Submit} style={{ position: "relative" }}>
              <InputGroup>
                <div className="flex fdcolumn flex1 margin-bottom me-1">
                  <Form.Label>
                    E-mail <Attention />
                  </Form.Label>
                  <Input
                    type="email"
                    placeholder="E-mail"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    required
                    // title="Coloque o e-mail do usuário"
                    autoComplete="new-password"
                  />
                </div>
                <div className="flex fdcolumn flex1 margin-bottom">
                  <Form.Label>
                    Nome completo <Attention />
                  </Form.Label>
                  <Input
                    placeholder="Nome completo"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    required
                    autoComplete="new-password"
                    // title="Coloque o nome completo do usuário"
                  />
                </div>
              </InputGroup>
              <Form.Check
                type="checkbox"
                label="Senha aleatória (A senha aleatória será enviada para o e-mail do usuário)"
                className="margin-bottom"
                onChange={(e) =>
                  this.setState({ random_password: e.target.checked })
                }
                checked={this.state.random_password}
              />
              <InputGroup>
                <PasswordInput
                  label="Senha"
                  placeholder="Digite uma senha"
                  className="flex1"
                  disabled={this.state.random_password}
                  value={this.state.password}
                  OnChangeText={(e) =>
                    this.setState({ password: e.target.value })
                  }
                  // title="Coloque a senha do usuário"
                />
              </InputGroup>
              <InputGroup>
                <PasswordInput
                  label="Confirmar senha"
                  placeholder="Confirmar senha"
                  className="flex1"
                  disabled={this.state.random_password}
                  value={this.state.confirm_password}
                  OnChangeText={(e) =>
                    this.setState({ confirm_password: e.target.value })
                  }
                  // title="Confirme a senha do usuário"
                />
              </InputGroup>
              <Form.Label>
                Tipo de usuário <Attention />
              </Form.Label>
              <select
                className="form-select margin-bottom"
                onChange={(e) => this.setState({ role: e.target.value })}
                required
              >
                <option>Selecione o tipo de usuário</option>
                <option value="Aluno">Aluno</option>
                <option value="Admin">Admin</option>
              </select>

              {this.state.role == "Aluno" && (
                <InputGroup>
                  <div className="flex fdcolumn flex1 margin-bottom">
                    <Form.Label>
                      Telefone <Attention />
                    </Form.Label>
                    <PhoneInput
                      GetValue={(value) => this.setState({ phone: value })}
                    />
                  </div>
                </InputGroup>
              )}

              {this.state.role == "Aluno" && (
                <InputGroup>
                  <div className="flex fdcolumn flex1 margin-bottom">
                    <Form.Label>
                      Profissão <Attention />
                    </Form.Label>
                    <select
                      className="form-select margin-bottom"
                      onChange={(e) =>
                        this.setState({ profession: e.target.value })
                      }
                    >
                      <option value="">Selecione uma profissão</option>
                      {this.state.professions &&
                        this.state.professions.length != 0 &&
                        this.state.professions.map((value, index) => (
                          <option key={value["key"]} value={value["id"]}>
                            {value["name"]}
                          </option>
                        ))}
                    </select>
                  </div>
                </InputGroup>
              )}

              {this.state.role == "Admin" && (
                <InputGroup>
                  <div className="flex fdcolumn flex1 margin-bottom">
                    <Form.Label>
                      Cargo <Attention />
                    </Form.Label>
                    <Input
                      placeholder="Cargo"
                      value={this.state.cargo}
                      onChange={(e) => this.setState({ cargo: e.target.value })}
                      required
                      title="Coloque o cargo do usuário"
                    />
                  </div>
                </InputGroup>
              )}
              {this.state.role == "Admin" && (
                <InputGroup>
                  <div className="flex fdcolumn flex1 margin-bottom">
                    <Form.Label>
                      Assinatura <Attention />
                    </Form.Label>
                    <div>
                      <DefaultButton
                        text="Imagem"
                        bg={
                          this.state.type_assinatura == "Imagem"
                            ? "primary"
                            : "secondary"
                        }
                        onClick={() =>
                          this.setState({ type_assinatura: "Imagem" })
                        }
                        style={{
                          marginBottom: "10px",
                          marginRight: 10,
                          width: 180,
                        }}
                      />
                      <DefaultButton
                        text="Assinar"
                        bg={
                          this.state.type_assinatura == "Assinar"
                            ? "primary"
                            : "secondary"
                        }
                        onClick={() =>
                          this.setState({ type_assinatura: "Assinar" })
                        }
                        style={{ marginBottom: "10px", width: 180 }}
                      />
                    </div>
                    <div hidden={this.state.type_assinatura == "Imagem"}>
                      <div
                        style={{
                          border: "1px solid grey",
                          width: 500,
                          height: 150,
                        }}
                      >
                        <SignatureCanvas
                          canvasProps={{ width: 500, height: 150 }}
                          ref={(ref) => {
                            this.sigCanvas = ref;
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          width: 500,
                        }}
                      >
                        <a
                          style={{ textDecoration: "none" }}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.sigCanvas.clear();
                          }}
                        >
                          Limpar
                        </a>
                      </div>
                    </div>

                    <div hidden={this.state.type_assinatura == "Assinar"}>
                      <AssinaturaInput
                        ImageChange={(assinatura) =>
                          this.setState({ assinatura })
                        }
                      />
                    </div>
                  </div>
                </InputGroup>
              )}

              <br />
              <div className="w100 inline-flex jcsb margin-top-1">
                {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                <DefaultButton
                  bg="secondary"
                  text="Voltar"
                  icon={<RiArrowGoBackLine />}
                  to="/accounts/list"
                />
                {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                <DefaultButton
                  type="submit"
                  bg="confirm"
                  text="Criar usuário"
                  loadingtext="Criando usuário"
                  loading={this.state.loading}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RegisterUser);

import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import { LinearProgress, Typography, Button, formatMs } from "@material-ui/core";

import { STORAGE_URL } from "../../../variables";

import { RiEditBoxFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { BsFillStarFill } from "react-icons/bs";

import Request from "../../../Request";
import TextReadMore from "../../ReusableComponents/TextReadMore";
import ContentDotOverflow from "../../ReusableComponents/ContentDotOverflow";
import DefaultButton from "../../ReusableComponents/DefaultButton";

const CourseCard = props => {

    const [loading, SetLoading] = useState(false);
    const [redirect, SetRedirect] = useState(<></>);
    const [progress, SetProgress] = useState(0);

    const [last_lesson_id, SetLastLesson] = useState("");
    const [lastVideoId, SetLastVideo] = useState("");

    const CardClick = (e) => {
        // SetRedirect(<Redirect to={`/courses/course/${props.id}`}/>);
        if (props.NotIn || loading) {
            e.preventDefault();
            if (!props.requested) SubscriptionRequest();
        }
    }

    const SubscriptionRequest = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;
        SetLoading(true);

        let form = new FormData();
        form.append("course_id", props.id);
        form.append("user_id", props.user_id);
        let response = await Request.Post("courses/subscription/request", form, {"Authorization": `Bearer ${token}`});
        SetLoading(false);
        props.ShowSnackbar && props.ShowSnackbar("Pedido de acesso ao curso enviado");
        props.update();
    }

    const CancelSubscriptionRequest = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;
        SetLoading(true);

        let form = new FormData();
        form.append("course_id", props.id);
        form.append("user_id", props.user_id);

        let response = await Request.Post("courses/subscription/delete-request", form, {"Authorization": `Bearer ${token}`});
        SetLoading(false);

        props.ShowSnackbar && props.ShowSnackbar("Pedido de acesso ao curso cancelado");
        props.update();
    }

    const GetCompletion = async () => {
        SetLoading(true);
        let course_progress = await Request.Get(`courses/complete/${props.id}/${props.user_id}`);
        if (course_progress["status"] && course_progress["percentage"]) {
            SetProgress(course_progress["percentage"]);
        }
        SetLoading(false);
    }

    const GetLastLessonWatched = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let response = await Request.Get(`courses/getlastwatch/${props.id}/${props.user_id}`);
        console.log(response);
        if (response["status"]) {
            SetLastLesson(response["lesson_id"]);
            SetLastVideo(response["file_id"]);
            console.log(response)
        }
    }

    useEffect(() => {
        GetLastLessonWatched();
        GetCompletion();
    }, []);

    if (loading) return <SkeletonCourseCard/>
    return(
        <div key={props.id} id={props.id} className="course-card">
            <Link to={loading && props.NotIn ? "/courses/list" : `/courses/course/${props.id}?video=${lastVideoId}&lesson=${last_lesson_id}`} style={{textDecoration: "none"}} className="remove-a" id={props.id} onClick={CardClick}>
                <div className="course-card-bar w100"/>
                <div className="course-card-body flex fdrow jcsb">
                    <div>
                        <img src={STORAGE_URL + props.path} className="course-card-img"/>
                    </div>
                    <div className="fdcolumn flex1 jsstart course-card-text" style={{overflow: "hidden"}}>
                        <h2 className="course-card-title">
                            {props.name}
                        </h2>
                        {/* <ContentDotOverflow element="div">
                            {props.description}
                        </ContentDotOverflow> */}
                        <LinearProgress variant="determinate" value={progress} className={"margin-bottom" + (props.NotIn ? " hide" : "")}/>
                        <div className="inline-flex jcsb w100 align-center">
                            <div>
                            {(() => {
                                if (last_lesson_id && !props.NotIn) return (
                                    props.completed_course==false? (<Link to={`/courses/course/${props.id}`} className="remove-a">
                                        <Button variant="contained" color="primary">Continuar</Button>
                                    </Link>):(props.completed_exam==false? <Link to={`/courses/course_avaliation/${props.id}`} className="remove-a">
                                        <Button variant="contained" color="primary">Fazer avaliação</Button>
                                    </Link>:<Link to={`/courses/course/${props.id}`} className="remove-a">
                                        <Button variant="contained" color="primary">Visualizar</Button>
                                    </Link>)
                                );
                                else if (props.NotIn) {
                                    if (props.requested) return (
                                        <div>
                                            <p className="text-primary">Pedido de acesso pendente</p>
                                            <DefaultButton
                                                bg="danger"
                                                text="Cancelar pedido"
                                                onClick={CancelSubscriptionRequest}
                                            />
                                        </div>
                                    );
                                    else return (
                                        <div className="remove-a">
                                            <Button variant="contained" color="primary">Requisitar acesso</Button>
                                        </div>
                                    );
                                } else return (
                                    <Link to={`/courses/course/${props.id}`} className="remove-a">
                                        <Button variant="contained" color="primary">Iniciar</Button>
                                    </Link>
                                );
                            })()}
                            </div>
                            <Typography className={props.NotIn ? " hide" : ""}>{`${progress}%`}</Typography>
                        </div>
                        <div className="rating flex fdrow align-center">
                            <div 
                                className="five-star"
                                style={{
                                    position: "relative",
                                    whiteSpace: "nowrap",
                                    width: "fit-content"
                                }}
                            >
                                <div 
                                    className="stars"
                                    style={{
                                        position: "absolute",
                                        overflow: "hidden",
                                        width: (props.average_rate * 100) / 5 + "%",
                                        top: 0,
                                        left: 0
                                    }}
                                >
                                    {(() => {
                                        let rates = [];
                                        for (let i = 0; i < 5; i++) {
                                            rates.push(<BsFillStarFill
                                                key={i}
                                                className="star-over"
                                                size={16}
                                            />)
                                        }
                                        return rates;
                                    })()}
                                </div>
                                {(() => {
                                    let rates = [];
                                    for (let i = 0; i < 5; i++) {
                                        rates.push(<BsFillStarFill
                                            key={i}
                                            className="star-placeholder"
                                            size={16}
                                        />)
                                    }
                                    return rates;
                                })()}
                            </div>
                            <div style={{textIndent: "5px", paddingTop: "3px"}} className="d-inline-flex">
                                {props.rating_count != 0 ? props.rating_count : <div className="d-md-none d-block">{props.rating_count}</div>}
                                <div className="d-none d-md-block">
                                    {props.rating_count == 0 ? " Nenhum usuário avaliou o curso ainda" : (props.rating_count > 1 ? " Usuários avaliaram" : " Usuário avaliou")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="course-card-footer">
                </div>
            </Link>
        </div>
    );
};

export const SkeletonCourseCard = () => {
    return (
        <div className="course-card flex fdrow">
            <div className="course-card-body flex fdrow jcsb w100">
                <div className="skeleton-base skeleton-shimmer course-card-img"/>
                <div className="flex fdcolumn flex1" style={{padding: "5px"}}>
                    <div className="skeleton-title skeleton-shimmer"/>
                    <br/>
                    <div className="skeleton-medium-text skeleton-shimmer"/>
                    <br/>
                    <div className="skeleton-base skeleton-shimmer w100" style={{height: ".4em"}}/>
                    <br/>
                    <div className="flex w100 jcsb align-center">
                        <div className="skeleton-small-button skeleton-shimmer"/>
                        <div className="skeleton-base skeleton-shimmer" style={{width: "2em", height: "1em"}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseCard;
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { BsFillEyeFill } from "react-icons/bs";
import DeleteIcon from '@material-ui/icons/Delete';
import Request from "../../../../Request";
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import OnlyAdmin from "../../../ReusableComponents/OnlyAdmin";
import Search from "../../../ReusableComponents/Search";
import GroupBoard from "./GroupBoard";

const ListGroups = props => {

    const {
        course_name,
        course_id
    } = props;

    const [groups, SetGroups] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [search, SetSearch] = useState("");
    const [page, SetPage] = useState(1);
    const [maxPage, SetMaxPage] = useState(1);

    const GetGroups = async () => {
        SetLoading(true);
        console.log(search);
        let response = await Request.Get(`groupcourse/from-course/${course_id}?search=${search}&page=${page}`);
        console.log(response);
        if (!response) return;
        SetGroups(response.data);
        SetMaxPage(response.last_page);
        SetLoading(false);
    }

    useEffect(() => {
        GetGroups();
    }, [search, page]);

    return (
        <div>
            <p className="text-muted">Aqui ficam os grupos inseridos no curso "{course_name}"</p>
            <div className="flex jcsb flex-wrap">
                <Search placeholder="Pesquisar grupo pelo Nome do grupo" ValueChange={value => { SetSearch(value); SetPage(1); }} />
                <OnlyAdmin
                    component={
                        <DefaultButton
                            to={`/courses/course/add-group/${course_id}`}
                            routeState={{
                                course_id: course_id
                            }}
                            bg="confirm"
                            text="Adicionar grupo"
                        />
                    }
                />
            </div>
            <br />
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nome do grupo</th>
                            <th>Descrição</th>
                            <th>Quantidade de usuários</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groups && groups.length != 0 && groups.map((current, index) => (
                            <GroupBoard
                                key={index}
                                id={current["id"]}
                                course_id={course_id}
                                name={current["name"]}
                                description={current["description"]}
                                users_count={current["users_count"]}
                                loading={loading}
                                OnDelete={() => GetGroups()}
                            />
                        ))}
                    </tbody>
                </table>
                <div className="w100 flex align-center jcc">
                    {groups && groups.length == 0 && !loading ? "Sem grupos no curso" : ""}
                </div>
                <Pagination
                    className={(maxPage == 1 ? "hide" : "")}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    count={maxPage}
                    value={page}
                    onChange={(_, value) => SetPage(value)}
                />
            </div>
            <div className="w100 flex jcc align-center">
                <CircularProgress className={loading ? "" : "hide"} style={{ width: "30px", height: "30px" }} />
            </div>
            {/* <SweetAlert
                title="Atenção!"
                onConfirm={() => {
                    SetPopup(false);
                    RemoveUserFromCourse(user_id)
                }}
                onCancel={() => {
                    SetPopup(false);
                }}
                warning
                showCancel={true}
                cancelBtnStyle={{ color: "white", textDecoration: "none" }}
                cancelBtnCssClass="btn-danger"
                cancelBtnText="Cancelar"
                confirmBtnText="Excluir"
                show={showpopup}
            >{message_alert}</SweetAlert> */}
            {/* <Snackbar
                open={snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                autoHideDuration={3000}
                onClose={() => SetOpenSnackbar(false)}
                message={snackbar_message}
                action={
                    <IconButton onClick={() => SetOpenSnackbar(false)}>
                        <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                }
            /> */}
        </div >
    );
}

export default ListGroups;
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Request from "../../../../Request";
import Board from "./Board";

const FeedbackPage = props => {

    const {
        course_id
    } = props;

    const [feedbacks, SetFeedbacks] = useState([]);
    const [loading, SetLoading] = useState(false);

    const [page, SetPage] = useState(1);
    const [maxPage, SetMaxPage] = useState(1);

    const GetData = async () => {
        SetLoading(true);
        let response = await Request.Get(`courses/feedback/from-course/${course_id}?page=${page}`);
        SetLoading(false);
        console.log(response);
        if (!response) return;

        SetFeedbacks(response.feedbacks.data);
        SetMaxPage(response.feedbacks.last_page);
    }

    useEffect(() => {
        GetData();
    }, []);

    return (
        <div className="feedback-page">
            <p className="text-muted">Aqui é possível ver todos as avaliações feitas sobre o curso</p>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nome do aluno</th>
                            <th>Comentário</th>
                            <th>Nota</th>
                            <th>Data de upload</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(!loading && feedbacks && feedbacks.length != 0) && feedbacks.map((value, index) => (
                            <Board
                                name={value.user.name}
                                comment={value.comment}
                                rate={value.rate}
                                date={value.created_at}
                            />
                        ))}
                    </tbody>
                </table>
                <div className="w100 flex align-center jcc">
                    {feedbacks.length == 0 && !loading ? "Nenhum feedback registrado" : ""}
                </div>
            </div>
            <div className="flex align-center jcc w100">
                <CircularProgress className={loading ? "" : "hide"} />
            </div>
            <div
                className={(maxPage <= 1 ? "hide" : "")}
            >
                <Pagination
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    count={maxPage}
                    value={page}
                    onChange={(_, value) => {
                        SetPage(value);
                    }}
                />
            </div>
        </div>
    );
}

export default FeedbackPage;
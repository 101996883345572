import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { BsFillEyeFill } from "react-icons/bs";
import { MdDelete, MdModeEdit } from "react-icons/md";
import Request from "../../../Request";
import DefaultButton from "../../ReusableComponents/DefaultButton";

const ProfessionBoard = props => {

    const {
        id,
        name,
        date,
        OnDelete
    } = props;

    const [popup, SetPopup] = useState(false);

    const Delete = async () => {
        let form = new FormData();
        form.append("profession_id", id);

        let response = await Request.Post("professions/delete", form);
        console.log(response);
        if (!response) return;
        else if (response["status"]) OnDelete(response);
    }

    return (
        <tr>
            <td>{name}</td>
            <td>{new Date(Date.parse(date)).toLocaleDateString("pt-BR")}</td>
            <td style={{
                verticalAlign: "middle",
                whiteSpace: "nowrap"
            }}>
                <SweetAlert
                    show={popup}
                    title="Atenção!"
                    onConfirm={() => {
                        SetPopup(false);
                        Delete();
                    }}
                    onCancel={() => {
                        SetPopup(false);
                    }}
                    showCancel={true}
                    cancelBtnStyle={{color: "white", textDecoration: "none"}}
                    cancelBtnCssClass="btn-danger"
                    cancelBtnText="Cancelar"
                    confirmBtnText="Deletar"
                >
                    Deseja mesmo deletar a profissão "{name}"?
                </SweetAlert>
                {/* <DefaultButton
                    to={`/accounts/profile/${id}`}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Ver aluno`}
                    bg="warning"
                    icon={<BsFillEyeFill size={17} color="white"/>}
                    style={{marginRight: "2px"}}
                /> */}
                <DefaultButton
                    to={`/professions/edit/${id}`}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Editar profissão`}
                    bg="primary"
                    icon={<MdModeEdit color="white" size={17}/>}
                    style={{
                        margin: "0 2px"
                    }}
                />
                <DefaultButton
                    onClick={() => SetPopup(true)}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Deletar profissão`}
                    bg="danger"
                    icon={<MdDelete size={17} color="white"/>}
                />
            </td>
        </tr>
    );
}

export default ProfessionBoard;
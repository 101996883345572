import React from 'react';

import { API_URL, URL } from '../../../../variables';
import { connect } from 'react-redux';
import { logout } from '../../../../actions/AppActions';
import { Redirect } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Request from '../../../../Request';
import { BsDownload } from 'react-icons/bs';
import { Button } from '@material-ui/core';
import DefaultButton from '../../../ReusableComponents/DefaultButton';
import { MdCached } from 'react-icons/md';
import { RiArrowGoBackLine } from 'react-icons/ri';

import Feedback from "../../Courses/Users/Feedback/";
import { Form } from 'react-bootstrap';

function getShuffledArr(arr) {
    return [...arr].map((_, i, arrCopy) => {
        var rand = i + (Math.floor(Math.random() * (arrCopy.length - i)));
        [arrCopy[rand], arrCopy[i]] = [arrCopy[i], arrCopy[rand]]
        return arrCopy[i]
    })
}

class RespostasUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            redirect: false,
            path: '/processo',
            prova: {},
            questoes: [],
            curso: {},
            respostas: {},
            resultado: {},
            loading_resposta: {},
            show_success: false,
            show_error: false,
            msg_erro: '',
            loading_screen: true,
            loading_finish: false,
            user_course: {},
            flag: '',
            show_warning: false,
            completed_course: false,
            tentativa:{}
        }
        this.questoesRef = {};
        // this.videoRef=null;
        this.teste_certificate = React.createRef();

    }

    async buscar_questoes() {
        this.setState({ questoes: [], loading_screen: false, respostas: {}, loading_screen: true, flag: '' });

        let response = await Request.Get(`usercourse/course/answer/${this.props.match.params.tentativa_id}`, { "Authorization": `Bearer ${this.props.token}` });
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            this.props.logout();
            return;
        }

        let respostas = {};
        for (let i = 0; i < response.questoes.length; i++) {
            if (response.questoes[i].resposta != null) {
                respostas[response.questoes[i].id] = response.questoes[i].resposta;
            }
        }
        // response.questoes = getShuffledArr(response.questoes);
        this.setState({ questoes: response.questoes, curso: response.curso, loading_screen: false, respostas, tentativa: response.tentativa });

    }

    async componentDidMount() {
        this.buscar_questoes();

    }




    render() {
        return (
            <div className="card" >
                <div className="card-body">
                    <div className="container-fluid">
                        {this.state.loading_screen == false && this.state.questoes.length == 0 && this.state.completed_course === true && <div className="row mt-3">
                            <div className="col-12">

                                <h5 className="mt-0 header-title" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
                                    Avaliação {this.state.curso.name}</h5>
                                <hr />
                            </div>
                            <div className="col-12">
                                <p style={{ textAlign: 'center' }}>Nenhuma questão cadastrada</p>
                            </div>
                        </div>}



                        {this.state.loading_screen == false && this.state.questoes.length > 0 && <div className="row mt-3">

                            {this.state.redirect == true && <Redirect to={this.state.path} />}


                            <div className="col-12">

                                <h5 className="mt-0 header-title" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
                                    Avaliação {this.state.curso.name}</h5>
                                <hr />

                                <div className="flex fdcolumn align-center">
                                    <Form className="w100">
                                        <Form.Label>Pontuação: <span className="font-light">{this.state.tentativa.numero_acertos}/{(this.state.questoes.reduce((a,b)=>a+b.peso,0))}</span></Form.Label><br />
                                        <Form.Label>Questões: <span className="font-light">{this.state.tentativa.numero_questoes}</span></Form.Label><br />
                                        <Form.Label >Porcentagem: <span className="font-light">{this.state.tentativa.porcengetam_acerto}%</span></Form.Label><br/>
                                        <Form.Label >Aprovado: <span className="font-light">{this.state.tentativa.completed_exam==true?'Sim':'Não'}</span></Form.Label>
                                    </Form>
                                </div>
                                <br />
                                
                                <h6 className="mt-0 header-title" style={{  marginBottom: '1.5rem' }}>
                                   Questões respondidas:
                                </h6>
                                <div className="row">

                                    {this.state.questoes.map((item, id) => (
                                        <div key={id} className="col-2" style={{ marginTop: '10px' }}>

                                            <a onClick={() => {
                                                window.scrollTo(0, this.questoesRef[item.id].offsetTop + 200)
                                            }} style={{ cursor: 'pointer', color: "black", textDecoration: 'none' }} className="smooth-goto" >
                                                {(item.resposta == null) &&
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 65 }}>
                                                        <b>{id + 1})</b>
                                                        <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> - </div>
                                                    </div>}
                                                {item.resposta != null && <div style={{ display: 'flex', width: 65, justifyContent: 'space-between', alignItems: 'center' }}><b>{id + 1})</b>
                                                    <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', fontWeight: 'bold', color: 'black', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> {Object.keys(item.alternativas).map((item2, id2) => {
                                                        if (item.resposta == item.alternativas[item2].id) {
                                                            return item2;
                                                        }
                                                    })} </div></div>}

                                            </a>
                                        </div>
                                    ))}



                                </div>

                            </div >
                            <div className="col-12">


                                {this.state.questoes.map((item, id) => (
                                    <div ref={(ref) => this.questoesRef[item.id] = ref} key={id}>
                                        <hr style={{ borderTop: '1px solid rgba(0,0,0,.2)' }} />

                                        {/* <h6 className="mt-0 header-title" style={{ textAlign: 'right' }}>
                                        {item.categoria.name}</h6> */}

                                        <div className="row" style={{ fontWeight: 'normal' }}>
                                            <div className="col-12 col-sm-12">
                                                <p><b>Peso: </b>{item.peso}</p>
                                                <p><b>Resposta correta: </b>{Object.values(item.alternativas).map((item2,id3)=>{
                                                    if(item2.correta==1){
                                                        console.log(Object.keys(item.alternativas)[id3])
                                                        return Object.keys(item.alternativas)[id3];
                                                    }
                                                    return null;
                                                    }).filter((item3)=>item3!=null)[0]}</p>
                                                <p><b>Resposta do aluno: </b>{item.resposta?(Object.values(item.alternativas).map((item2,id3)=>{
                                                    if(item2.id==item.resposta){
                                                        return Object.keys(item.alternativas)[id3];
                                                    }
                                                    return null;
                                                    }).filter((item3)=>item3!=null)[0]):'-'}</p>

                                            </div>

                                            <div className="col-2 col-sm-1">
                                                <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                    <b>{id + 1})</b></h5>
                                            </div>
                                            <div className="col-10 col-sm-11">

                                                <div dangerouslySetInnerHTML={{ __html: item.enunciado }}></div>
                                                <form>
                                                    {Object.keys(item.alternativas).map((alternativa, id2) => (



                                                        // <div key={id2} style={{ color: 'black', textAlign: 'justify' }} className="row">
                                                        //     <div className="col-2 pt-3 pb-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        //         {alternativa}
                                                        //     </div>
                                                        //     <div dangerouslySetInnerHTML={{ __html: item.alternativas[alternativa].texto }} className="col-10 pt-3 pb-3" style={{}}>

                                                        //     </div>
                                                        // </div>
                                                        <div key={id2} style={{ display: 'flex', marginTop: 10 }}>
                                                            <div style={{ width: '10%' }}>
                                                                <p style={{ color: item.alternativas[alternativa].id == item.resposta ? 'green' : 'black' }}>{alternativa}</p>
                                                            </div>
                                                            <div style={{ width: '90%' }}>

                                                                <div style={{ color: item.alternativas[alternativa].id == item.resposta ? 'green' : 'black' }} dangerouslySetInnerHTML={{ __html: item.alternativas[alternativa].texto }}>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    ))}
                                                </form>

                                            </div>




                                        </div>


                                        {/* {!!Form::close() !!} */}

                                    </div>))}
                            </div>

                            <SweetAlert
                                danger
                                title={"Erro!"}
                                onConfirm={() => this.setState({ show_error: false })}
                                // onCancel={this.onCancel.bind(this)}
                                show={this.state.show_error}
                                confirmBtnText='Ok'
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="success"
                                showCancel={false}
                            >
                                {this.state.msg_erro}

                            </SweetAlert>




                        </div>}




                        {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                            <div className="spinner-border text-primary" role="status"  >
                            </div>
                        </div></div>}


                    </div>
                    <br />
                    <DefaultButton
                        icon={<RiArrowGoBackLine />}
                        text="Voltar"
                        bg="secondary"
                        // to={(current_user["role"] == "Admin" ? "/accounts/list" : `/`)}
                        to={"/courses/course/detail_user/" + this.props.match.params.course_id + '/' + this.props.match.params.user_id}
                    />
                </div>
            </div>


        );
    }

    onConfirm() {

    }

    onCancel() {

    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        redacoes: state.AppReducer.redacoes,
        provas: state.AppReducer.provas,
    }
);

export default connect(mapsStateToProps, { logout })(RespostasUser);

import React, { useState } from "react";

import {RiEditBoxFill} from "react-icons/ri";
import {MdDelete, MdModeEdit} from "react-icons/md";
import {BsFillEyeFill} from "react-icons/bs";

import SweetAlert from "react-bootstrap-sweetalert";

import { STORAGE_URL } from "../../../variables";
import { NavLink, Link, useHistory } from "react-router-dom";
import Request from "../../../Request";
import { Button } from "react-bootstrap";
import ContentDotOverflow from "../../ReusableComponents/ContentDotOverflow";
import DefaultButton from "../../ReusableComponents/DefaultButton";

const CourseCardTable = props => {

    const [showpopup, SetPopup] = useState(false);

    const Delete = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let form = new FormData();
        form.append("id", props.id);

        let response = await Request.Post("courses/delete", form, {"Authorization": `Bearer ${token}`});
        props.update();
        props.onDelete();
    }

    return(
        <>
           
                <tr className="card-tr">
                    <td>{props.name}</td>
                    <ContentDotOverflow element={"td"}>
                        {props.description}
                    </ContentDotOverflow>
                    <td className="td-icons">
                        <DefaultButton
                            icon={<BsFillEyeFill size={17} color="white"/>}
                            title="Visitar curso"
                            width="2.2em"
                            height="2.2em"
                            padding="0"
                            to={`/courses/course/${props.id}`}
                            bg="warning"
                        />
                        <DefaultButton
                            icon={<MdModeEdit color="white" size={17}/>}
                            title="Editar curso"
                            width="2.2em"
                            height="2.2em"
                            padding="0"
                            to={`/courses/edit/${props.id}`}
                            bg="primary"
                            style={{
                                margin: "0 2px"
                            }}
                        />
                        <DefaultButton
                            icon={<MdDelete size={17} color="white"/>}
                            bg="danger"
                            onClick={() => SetPopup(true)}
                            title="Deletar curso"
                            width="2.2em"
                            height="2.2em"
                            padding="0"
                        />
                        <SweetAlert
                            title="Atenção"
                            warning
                            onConfirm={() => {
                                SetPopup(false);
                                Delete();
                            }}
                            onCancel={() => {
                                SetPopup(false);
                            }}
                            showCancel={true}
                            cancelBtnStyle={{color: "white", textDecoration: "none"}}
                            cancelBtnCssClass="btn-danger"
                            cancelBtnText="Cancelar"
                            confirmBtnText="Excluir"
                            show={showpopup}
                        >
                            <p>{`Deseja mesmo excluir o curso "${props.name}"?`}</p><p>Isso irá excluir todas as aulas vínculadas a este curso</p>
                        </SweetAlert>
                    </td>
                </tr>
        </>
    );
}

export default CourseCardTable;
import React, { useEffect, useState } from "react";

import { Form, FormControl as Input, Button, Card,ProgressBar } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import Request from "../../../Request";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import Attention from "../../ReusableComponents/Attention";
import InputFile from "../../ReusableComponents/InputFile";

import LoadingScreen from "../../ReusableComponents/LoadingScreen";
import VideoLoaderInput from "../../ReusableComponents/VideoLoaderInput";
import { logout } from "../../../actions/AppActions";
import { useDispatch } from "react-redux";

const CreateLessons = props => {

    const [video, SetVideo] = useState("");

    const [title, SetTitle] = useState("");
    const [description, SetDescription] = useState("");
    const [course_id, SetCourse] = useState("");
    const [files, SetFiles] = useState([]);

    const [select_options, SetOptions] = useState([]);

    const [showpopup, SetPopup] = useState(false);
    const [popup_message, SetMessage] = useState("");

    const [loading, SetLoading] = useState(false);
    const [success, SetSuccess] = useState(false);

    const history = useHistory();
    const [progress, SetProgress] = useState(0);

    const Submit = async () => {
        if (video.length == 0 && files.length==0) {
            SetPopup(true);
            SetMessage("Adicione pelo menos um vídeo ou um arquivo");
            return;
        } else if (!title) {
            SetPopup(true);
            SetMessage("Coloque um título");
            return;
        } else if (!course_id) {
            SetPopup(true);
            SetMessage("Selecione um curso para adicionar a aula");
            return;
        }

        let token = localStorage.getItem("token");
        if (!token) return;

        let form = new FormData();
        if (video) for (let i = 0; i < video.length; i++) {
            form.append("videos_names[]", video[i].name_file);
            form.append("videos_covers[]", video[i].cover_file ?? 'none');
            delete video[i].cover_file;
            form.append("videos[]", video[i]);
        }
        // form.append("videos[]", video);
        if (files) for (let i = 0; i < files.length; i++) {
            form.append("files[]", files[i]);
            form.append("files_names[]", files[i].name_file);

        }
        form.append("title", title);
        form.append("description", description);
        form.append("course_id", course_id);

        SetLoading(true);

        let response = await Request.PostProgress("lessons", form, {"Authorization": `Bearer ${token}`},(progress)=>{SetProgress(progress)});
        
        if (response["status"]) {
            // SetPopup(true);
            // SetMessage(response["message"]);
            // SetSuccess(true);
            history.push({
                pathname: `/courses/course/${course_id}`,
                state: {
                    snackbar: {
                        show: true,
                        message: "Aula criada com sucesso"
                    }
                }
            ,search:'?'+new URLSearchParams({tab: 0}).toString()

            });
            return;

        }
        else{
            SetPopup(true);

            SetMessage(response["message"]);
            SetLoading(false);

        }

    }
    const dispatch = useDispatch();

    const CoursesOptions = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let response = await Request.Get("courses", {"Authorization": `Bearer ${token}`});

        let temp_options = [];
        if (response === undefined) return;
        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
        for (let i = 0; i < response.length; i++) {
            let element = (
                <option value={response[i]["id"]}>{response[i]["name"]}</option>
            );
            temp_options.push(element);
        }
        SetOptions(temp_options);
    }

    useEffect(() => {
        if (!props.location.state) CoursesOptions();
        else SetCourse(props.location.state.course_id);
    }, []);

    return(
        <div className="lessons" style={{position: "relative"}}>
            <SweetAlert 
                title={'Atenção'}
                warning
                show={showpopup}
                onConfirm={() => {
                    SetPopup(false);
                    if (success) history.push(`/courses/course/${course_id}`);
                }}
            >{popup_message}</SweetAlert>
            <div className="card">
                <div className="card-body">
                    <h2 className="title">Criar nova aula</h2>
                    <p className="text-muted">Aqui são criadas as aulas com os seus conteúdos</p>
                    <br/>
                    <Form>
                        <Form.Label>Vídeo(s)</Form.Label>
                        <VideoLoaderInput
                            VideoChange={videos => {
                                SetVideo(videos);
                            }}
                            multiple
                        />

                        <Form.Label>Arquivo(s)</Form.Label>
                        <InputFile
                            onChange={(value) => SetFiles(value)}
                            accept=".pdf,.doc"
                        />

                        <Form.Label>Título <Attention/></Form.Label>
                        <Input className="margin-bottom" placeholder="Título da aula" onChange={(e) => SetTitle(e.target.value)} value={title}/>

                        <Form.Label>Descrição <Attention/></Form.Label>
                        <Input className="margin-bottom" as="textarea" placeholder="Descrição da aula" onChange={(e) => SetDescription(e.target.value)} value={description}/>

                        <Form.Label className={(select_options.length == 0 ? "hide" : "")}>Selecionar curso <Attention/></Form.Label>
                        <select className={"form-select " + (select_options.length == 0 ? "hide" : "")} onChange={(e) => SetCourse(e.target.value)}>
                            <option value="">Selecionar curso</option>
                            {select_options}
                        </select>
                        {loading==false && <div className="w100 inline-flex jcsb margin-top-1">
                            <DefaultButton
                                bg="secondary"
                                to={`/courses/course/${course_id}`}
                                text="Voltar"
                                icon={<RiArrowGoBackLine/>}
                                search={'?'+new URLSearchParams({tab: 0}).toString()}
                            />
                            <DefaultButton
                                bg="confirm"
                                onClick={Submit}
                                text="Criar aula"
                                loading={loading}
                            />
                        </div>}
                        {loading==true && <ProgressBar animated now={progress} label={parseInt(progress)+'%'} />}

                    </Form>
                </div>
            </div>
        </div>
    );
}

export default CreateLessons;
import React, { useEffect, useState } from "react";

import SweetAlert from "react-bootstrap-sweetalert";

import { BsFillEyeFill } from "react-icons/bs";
import { MdDelete, MdDragHandle, MdModeEdit } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Request from "../../../Request";

import { useDragControls, Reorder } from "framer-motion";

import { STORAGE_URL } from "../../../variables";
import ContentDotOverflow from "../../ReusableComponents/ContentDotOverflow";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import OnlyAdmin from "../../ReusableComponents/OnlyAdmin";
import { IconButton } from "@material-ui/core";

const LessonCard = (props) => {
  const [showpopup, SetPopup] = useState(false);
  const [is_completed, SetCompletion] = useState(null);
  const dragControls = useDragControls();

  const history = useHistory();
  const { id } = useParams();

  const Delete = async () => {
    let token = localStorage.getItem("token");
    if (!token) return;

    let form = new FormData();
    form.append("id", props.id);

    let response = await Request.Post("lessons/delete", form, {
      Authorization: `Bearer ${token}`,
    });
    if (!response) return;
    props.update && props.update();
    props.onDelete && props.onDelete();
  };

  const IsLessonCompleted = async () => {
    let token = localStorage.getItem("token");
    if (!token) return;

    let response = await Request.Get(
      `lessons/complete/${props.id}/${props.user_id}`
    );
    SetCompletion(response["status"]);
  };

  // useEffect(() => IsLessonCompleted(), []);

  return (
    <Reorder.Item
      value={props.value}
      dragListener={false}
      dragControls={dragControls}
      as="tr"
    >
      {/* <tr id={props.id} completed={is_completed ? "true" : "false"}> */}
      <IconButton
        component="td"
        onPointerDown={(e) => dragControls.start(e)}
        style={{
          cursor: "pointer",
          touchAction: "pan-x",
        }}
      >
        <MdDragHandle />
      </IconButton>
        <td>
          {props.title}
          <SweetAlert
            title="Atenção!"
            onConfirm={() => {
              SetPopup(false);
              Delete();
            }}
            onCancel={() => {
              SetPopup(false);
            }}
            showCancel={true}
            cancelBtnStyle={{ color: "white", textDecoration: "none" }}
            cancelBtnCssClass="btn-danger"
            cancelBtnText="Cancelar"
            confirmBtnText="Excluir"
            show={showpopup}
          >{`Deseja mesmo excluir a aula ${props.title}?`}</SweetAlert>
        </td>
        <ContentDotOverflow element={"td"}>
          {props.description}
        </ContentDotOverflow>
        <td>
          {new Date(Date.parse(props.date_created)).toLocaleDateString("pt-BR")}
        </td>
        <td className="td-icons">
          {/* <Link to={{
                        pathname: `/lesson/${props.id}`,
                        state: {
                            from: 
                        }
                    }}> */}
          {/* <span 
                            onClick={() => history.push(`/lesson/${props.id}`)}
                            className="inline-flex align-center jcc bg-warning icon" 
                            style={{width: "2em", height: "2em", borderRadius: "7px"}}
                            title="Ver aula"
                            >
                            <BsFillEyeFill size={17} color="white"/>
                        </span> */}
          <DefaultButton
            icon={<BsFillEyeFill size={17} color="white" />}
            title="Ver aula"
            width="2.2em"
            height="2.2em"
            padding="0"
            to={`/lesson/${props.id}`}
            routeState={{
              from: id,
              title: props.title,
              description: props.description,
            }}
            bg="warning"
          />
          {/* </Link> */}
          <OnlyAdmin
            component={
              // <Link to={`/lesson/edit/${props.id}`} onClick={(e) => e.stopPropagation()}>
              //     <span
              //         className="inline-flex align-center jcc bg-primary icon"
              //         style={{width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px"}}
              //         data-toggle="tooltip"
              //         title="Editar aula"
              //     >
              //         <MdModeEdit color="white" size={17}/>
              //     </span>
              // </Link>
              <DefaultButton
                icon={<MdModeEdit color="white" size={17} />}
                title="Editar aula"
                width="2.2em"
                height="2.2em"
                padding="0"
                to={`/lesson/edit/${props.id}`}
                routeState={{
                  from: id,
                  title: props.title,
                  description: props.description,
                }}
                bg="primary"
                style={{
                  margin: "0 2px",
                }}
              />
            }
          />
          <OnlyAdmin
            component={
              // <span
              //     onClick={(e) => {
              //         SetPopup(true);
              //         e.stopPropagation();
              //     }}
              //     className="inline-flex align-center jcc bg-danger icon"
              //     style={{width: "2em", height: "2em", borderRadius: "7px"}}
              //     title="Deletar aula"
              // >
              //     <MdDelete size={17} color="white"/>
              // </span>
              <DefaultButton
                icon={<MdDelete size={17} color="white" />}
                title="Deletar aula"
                width="2.2em"
                height="2.2em"
                padding="0"
                onClick={() => SetPopup(true)}
                bg="danger"
              />
            }
          />
        </td>
      {/* </tr> */}
    </Reorder.Item>
  );
};

export default LessonCard;

import React, {useEffect, useState} from "react";

import {Table} from "react-bootstrap";

import Request from "../../../Request";

// import {} from "react-router";

import { MenuItem } from "react-pro-sidebar";
import { NavLink, useLocation } from "react-router-dom";

import { STORAGE_URL } from "../../../variables";
import CourseCardTable from "./CourseCardTable";
import Pagination from '@material-ui/lab/Pagination';
import { CircularProgress, Snackbar } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { logout } from "../../../actions/AppActions";

const CoursesImporter = (props) => {

    const [links, SetLinks] = useState([]);
    const [effect_counter, EffectCounter] = useState(0);
    const [loading, SetLoading] = useState(false);

    const [previous_search, SetPSearch] = useState("");

    const [page, SetPage] = useState(1);
    const [max_page, SetMaxPage] = useState(1);

    const [showSnackbar, SetShowSnackbar] = useState(false);
    const [snackbarMessage, SetSnackbarMessage] = useState("");
    const [snackbarSeverity, SetSnackbarSeverity] = useState("");

    const location = useLocation();

    const dispatch = useDispatch();

    const SummonLinks = async (search) => {
        if (!search && previous_search == "") props.Loading(true);
        let response;
        SetLoading(true);
        if (search) response = await Request.Get(`courses/search/${search}?page=${page}`);
        else response = await Request.Get(`courses?page=${page}`);
        let temp_links = [];
        if (response === undefined) return;
        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
        let courses = response["data"];
        if (courses !== undefined) for (let i = 0; i < courses.length; i++) {
            let element = (
                <CourseCardTable
                    name={courses[i]["name"]}
                    description={courses[i]["description"]}
                    path={courses[i]["image"]}
                    id={courses[i]["id"]}
                    key={i}
                    update={() => SummonLinks()}
                    onDelete={() => {
                        SetShowSnackbar(true);
                        SetSnackbarMessage("Curso deletado com sucesso");
                    }}
                />
            );
            temp_links.push(element);
        }
        // if (courses.length == 0) temp_links.push(<p>Sem cursos disponíveis</p>);
        SetMaxPage(response["last_page"]);
        SetLinks(temp_links);
        props.Loading(false);
        SetLoading(false);
    };

    useEffect(() => {
        SummonLinks(props.Search);
    }, [page]);

    useEffect(() => {
        SetPage(1);
    }, [props.Search]);

    useEffect(() => {
        if (effect_counter == 0) {//didmount
            SummonLinks();
            EffectCounter(effect_counter + 1);
            SetPSearch(props.Search);
        } else if (props.Search != previous_search) {
            SummonLinks(props.Search);
            SetPSearch(props.Search);
        }
    });

    useEffect(() => {
        if (location.state && location.state.snackbar) {
            SetShowSnackbar(location.state.snackbar.show);
            SetSnackbarMessage(location.state.snackbar.message);
            SetSnackbarSeverity(location.state.snackbar.severity);
        }
        // return () => {
        //     Request.abort_control.abort();
        // };
    }, []);

    return(
        <>
            <div className="table-responsive">
                <table 
                    className="table table-striped table-hover" 
                    text-center=""
                >
                    <thead>
                        <tr>
                            <th nowrap="">Nome do curso</th>
                            <th style={{width: "40%"}}>Descrição</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && links}
                    </tbody>
                </table>
                <div className="w100 flex align-center jcc">
                    {links.length == 0 && !loading ? "Nenhum curso cadastrado" : ""}
                </div>
            </div>
            <div className="flex align-center jcc w100">
                <CircularProgress className={loading ? "" : "hide"}/>
            </div>
            <Pagination 
                className={(max_page == 1 ? "hide" : "")}
                style={{display: "flex", justifyContent: "flex-end"}} 
                count={max_page} 
                value={page} 
                onChange={(_, value) => SetPage(value)}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                ContentProps={{
                    // style: {
                    //     backgroundColor: ""
                    // }
                }}
                autoHideDuration={2000}
                onClose={() => SetShowSnackbar(false)}
                open={showSnackbar}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </>
    );

};

export default CoursesImporter;
import React, {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";

import Request from "../../../Request";
import CourseCard, { SkeletonCourseCard } from "./CourseCard";
import Pagination from '@material-ui/lab/Pagination';
import { CircularProgress, Snackbar } from "@material-ui/core";
import { logout } from "../../../actions/AppActions";

const UserCoursesImporter = props => {
    const [links, SetLinks] = useState([]);
    const [loading, SetLoading] = useState(false);

    const [previous_search, SetPSearch] = useState("");

    const [page, SetPage] = useState(1);
    const [max_page, SetMaxPage] = useState(1);

    const [snackbar, SetSnackbar] = useState(false);
    const [snackbarMessage, SetSnackbarMessage] = useState("");

    const [cardSkeletonList] = useState(new Array(2).fill(<SkeletonCourseCard/>));

    const user_id = useSelector(store => store.AppReducer.user.id);
    const dispatch = useDispatch();

    const SummonLinks = async (search) => {
        const token = localStorage.getItem("token");
        if (!token) return;
        if (!search && props.Loading && previous_search == "") props.Loading(true);
        SetLoading(true);
        let response;
        if (props.NotIn) {
            // if (search) response = await Request.Get(`usercourse/user/has-not/${user_id}/search/${search}?page=${page}`, {"Authorization": `Bearer ${token}`});
            response = await Request.Get(`usercourse/user/has-not/${user_id}?page=${page}`, {"Authorization": `Bearer ${token}`});
        } else {
            if (search) response = await Request.Get(`usercourse/user/has/${user_id}/search/${search}?page=${page}`, {"Authorization": `Bearer ${token}`});
            else response = await Request.Get(`usercourse/user/has/${user_id}?page=${page}`, {"Authorization": `Bearer ${token}`});
        }
        let temp_links = [];
        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response === undefined || !response["data"]) return;
        console.log(response)
        let courses = response["data"];
        for (let i = 0; i < courses.length; i++) {
            let element = (
                <CourseCard
                    name={courses[i]["name"]}
                    completed_course={courses[i]["completed_course"]}
                    completed_exam={courses[i]["completed_exam"]}
                    
                    rating_count={courses[i]["rating"]["total_rates"]}
                    average_rate={courses[i]["rating"]["average_rate"]}
                    description={courses[i]["description"]}
                    path={courses[i]["image"]}
                    id={courses[i]["id"]}
                    key={i}
                    user_id={user_id}
                    update={() => SummonLinks()}
                    ShowSnackbar={(message) => {
                        SetSnackbar(true);
                        SetSnackbarMessage(message);
                    }}
                    NotIn={props.NotIn}
                    requested={props.NotIn ? courses[i]["requested"] : false}
                />
            );
            temp_links.push(element);
        }
        if (courses.length == 0) temp_links.push(<p key={"not"} style={{marginRight:'1rem',marginLeft:'1rem'}}>{props.NotIn ? "Você está em todos os cursos!" : "Sem cursos disponíveis"}</p>);
        SetMaxPage(response["last_page"]);
        SetLinks(temp_links);
        SetLoading(false);
        props.Loading && props.Loading(false);
    };

    useEffect(() => {
        SummonLinks(props.Search);
    }, [page, props.Search]);

    useEffect(() => {
        SetPage(1);
    }, [props.Search]);

    // useEffect(() => {
    //     return () => {
    //         Request.abort_control.abort();
    //     };
    // }, []);
    return(
        <>
            <div className="cards-group">
                {/* {!loading && links} */}
                {loading ? cardSkeletonList : links}
            </div>
            <div className="flex align-center jcc" hidden={!loading}>
                {/* <CircularProgress className={loading ? "" : "hide"}/> */}
                <div className="skeleton-medium-dot skeleton-shimmer"/>
                <div className="skeleton-medium-dot skeleton-shimmer"/>
                <div className="skeleton-medium-dot skeleton-shimmer"/>
            </div>
            <Pagination 
                className={(max_page == 1 ? "hide" : "")}
                style={{display: "flex", justifyContent: "flex-end"}} 
                count={max_page} 
                value={page} 
                onChange={(_, value) => SetPage(value)}
            />
            <Snackbar
                message={snackbarMessage}
                open={snackbar}
                onClose={() => SetSnackbar(false)}
                autoHideDuration={2000}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            />
        </>
    );

}

export default UserCoursesImporter;
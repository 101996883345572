import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Request from "../../Request";
import LoadingScreen from "./LoadingScreen";

const OnlyAdmin = ({component: Component}) => {

    const user = useSelector(store => store.AppReducer.user);
    
    const [render_component, SetComponent] = useState(<></>);
    
    useEffect(() => {
        if (user.role == "Admin") SetComponent(Component);
    }, [user]);

    return(
            render_component
    );

}

export default OnlyAdmin;
import React, { useEffect, useState, useRef } from "react";
import { Form, FormControl as Input, Button, InputGroup } from "react-bootstrap";
import { Link, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import Request from "../../../../Request";
import { STORAGE_URL } from "../../../../variables";
import ImageLoaderInput from "../../../ReusableComponents/ImageLoaderInput";
import PasswordInput from "../../../ReusableComponents/PasswordInput";

import default_profile from "../../../../assets/images/default.png";
import { useSelector } from "react-redux";
import { MdModeEdit } from "react-icons/md";
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";
import { BsFillEyeFill } from "react-icons/bs";
import CourseGroup from "../../Profile/CourseGroup";
import Certificate from "../../Profile/Certificate";

const UserCourseDetail = props => {

    const [name, SetName] = useState("");
    const [tentativas, SetTentativas] = useState([]);

    const [email, SetEmail] = useState("");
    const [role, SetRole] = useState("");
    const [image, SetImage] = useState("");
    const [phone, SetPhone] = useState("");
    const [profession, SetProfession] = useState("");
    const [courses, SetCourses] = useState([]);
    const [usercourses, SetUserCourses] = useState([]);
    const [currentCourse, SetCurrentCourse] = useState([]);

    const [firstLoading, SetFirstLoading] = useState(false);

    const current_user = useSelector(store => store.AppReducer.user);

    const { course_id, user_id } = useParams();
    const location = useLocation();
    const history = useHistory();

    const unfinishedTryFlag = useRef(false);

    const GetData = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        // let response = await Request.Get(`register/id/${id}`);
        let response = await Request.Get(`usercourse/course/in/${course_id}/${user_id}`, { "Authorization": `Bearer ${token}` });
        console.log(response)
        if (!response) return;

        SetName(response["name"]);
        SetEmail(response["email"]);
        SetRole(response["role"]);
        SetImage(response["image"]);
        SetTentativas(response["tentativas"]);
        SetCurrentCourse(response?.currentCourse);

        if (response["phone"]) SetPhone(response["phone"]);
        if (response["profession"]) SetProfession(response["profession"]["name"]);
        
        //getting courses and user-course relation

        response = await Request.Get(`register/id/${user_id}`);
        if (!response) return;
        
        if (response?.status === true) {
            SetCourses(response?.user?.courses);
            SetUserCourses(response?.user?.usercourses);
        }

        SetFirstLoading(true);
    };

    useEffect(() => {
        GetData();
    }, []);

    useEffect(() => {
        GetData();
    }, [location]);



    if (!firstLoading) return <SkeletonProfilePage />
    else return (
        <div className="card">
            <div className="card-body">
                <h3>Dados no curso <b>{currentCourse?.name}</b></h3>
                <div className="flex jcsb">
                    <h4>{name}</h4>
                    {/* <Link to={{
                        pathname: `/accounts/edit/${id}`,
                        state: {
                            from: id
                        }
                    }} onClick={(e) => e.stopPropagation()}>
                        <span 
                            className="inline-flex align-center jcc bg-primary icon" 
                            style={{width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px"}}
                            data-toggle="tooltip"
                            title="Editar este curso"
                        >
                            <MdModeEdit color="white" size={17}/>
                        </span>
                    </Link> */}

                </div>
                <div className="flex fdcolumn align-center">
                    <img src={(image ? STORAGE_URL + image : default_profile)} style={{ width: "7em", height: "7em", borderRadius: "100%", objectFit: "cover" }} className="skeleton-shimmer" onError={(e) => e.target.src = default_profile} />
                    <hr className="w100" />
                    <Form className="w100">
                        <Form.Label>Nome: <span className="font-light">{name}</span></Form.Label><br />
                        <Form.Label>E-mail: <span className="font-light">{email}</span></Form.Label><br />
                        <Form.Label className={phone ? "" : "hide"}>Telefone: <span className="font-light">{phone}</span></Form.Label>{phone && <br />}
                        <Form.Label className={profession ? "" : "hide"}>Profissão: <span className="font-light">{profession}</span></Form.Label>{profession && <br />}
                        <Form.Label>Tipo de usuário: <span className="font-light">{role}</span></Form.Label><br />
                    </Form>
                </div>

                <h4 className="mt-3 header-title" style={{ fontSize: '1.1rem', color: 'black' }}>Tentativas Avaliação:</h4>
                 <br />
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                {/* <th>Acertos</th>
                                <th>Nº de questões</th> */}
                                <th>Porcentagem de Acerto</th>
                                <th>Aprovado</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tentativas?.map((current, index) => {
                                let unfinished = !current?.porcengetam_acerto && !current?.numero_acertos && !current?.numero_questoes;
                                console.log(unfinished)
                                if (unfinished) unfinishedTryFlag.current = true;
                                return (
                                    <tr id={current["id"]} key={index} hidden={unfinished}>
                                        {/* <td>
                                            {current["numero_acertos"]}
                                        </td>
                                        <td>
                                            {current["numero_questoes"]}
                                        </td> */}
                                        <td>{current["porcengetam_acerto"]}%</td>
                                        <td>{current["completed_exam"]==true?'Sim':'Não'}</td>

                                        <td style={{ width: "10em" }}>
                                            {/* <a target={'_blank'} href={API_URL+'courses/certificado/'+course_id+'/'+current["id"]} >
                                            Ver certificado
                                        </a> */}
                                            
                                            <DefaultButton
                                                icon={<BsFillEyeFill size={17} color="white" />}
                                                title="Ver respostas"
                                                width="2.2em"
                                                height="2.2em"
                                                padding="0"
                                                to={`/courses/course/answer/${course_id}/${user_id}/${current["id"]}`}
                                                bg="warning"
                                                style={{ marginRight: "2px" }}
                                                routeState={{
                                                    from: `/courses/course/${course_id}`
                                                }}
                                            />
                                            
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="w100 flex align-center jcc">
                        {tentativas.length == 0 ? "Nenhuma tentativa realizada" : ""}
                        {tentativas.length != 0 && unfinishedTryFlag.current ? "Nenhuma tentativa foi finalizada!" : ""}
                    </div>
                </div>
                <br/>

                {/* <h4>Cursos</h4>
                <CourseGroup
                    courses={courses}
                />
                <br/>

                <h4>Certificados</h4>
                <Certificate
                    usercourses={usercourses}
                /> */}
                <DefaultButton
                    icon={<RiArrowGoBackLine />}
                    text="Voltar"
                    bg="secondary"
                    // to={(current_user["role"] == "Admin" ? "/accounts/list" : `/`)}
                    to={`/courses/course/${course_id}`}
                    search={'?' + new URLSearchParams({ tab: 1 }).toString()}
                />
            </div>
        </div>
    );
}

const SkeletonProfilePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title skeleton-shimmer"></div>
                <div className="flex fdcolumn align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{ width: "7em", height: "7em", borderRadius: "100%" }}></div>
                    <hr />
                    <div className="w100 margin-bottom">
                        <div className="skeleton-medium-text skeleton-shimmer"></div>
                        <div className="skeleton-medium-text skeleton-shimmer"></div>
                        <div className="skeleton-medium-text skeleton-shimmer"></div>
                        <div className="skeleton-medium-text skeleton-shimmer"></div>
                        <div className="skeleton-medium-text skeleton-shimmer"></div>
                    </div>
                </div>
                <div className="skeleton-small-button skeleton-shimmer" />
            </div>
        </div>
    );
}

export default UserCourseDetail;
import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import OnlyAdmin from "../../ReusableComponents/OnlyAdmin";
import Search from "../../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill, BsDownload } from "react-icons/bs";
import { API_URL } from "../../../variables";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import { logout } from "../../../actions/AppActions";
import { CircularProgress } from "@material-ui/core";
// import ModalQuestao from "./ModalQuestao";



const Certificates = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_users, setLoadingUsers] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, total_pages: 1 });

    const user_id = useSelector(store => store.AppReducer.user.id);


    const [search, setSearch] = useState('');
    const { curso_id, curso_name } = props;
    const [show_warning, setShowWarning] = useState(false);
    // const [questao_id, setQuestaoId] = useState(false);

    // const [questaoSelect, setQuestaoSelect] = useState({});

    // const [showModal, setShowModal] = useState(false);
    let refs = [];
    const teste_certificate = useRef();
    const dispatch = useDispatch();

    const GetUsers = async (page, search) => {

        let response = await Request.Get(`courses/certificates/${curso_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
        console.log(response?.users);
        if (response?.pagination?.current_page == page) {
            setUsers(response?.users);

            setPagination(response?.pagination);
            setLoadingScreen(false);
            setLoadingUsers(false);

        }


    }



    useEffect(() => {
        GetUsers(1, search_oficial);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <p className="text-muted">Aqui estão todos os usuários que já completaram o curso "{curso_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">

                        <Search placeholder="Pesquisar pelo campo Nome do usuário" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setSearchOficial(value);
                                setLoadingUsers(true);

                                GetUsers(1, value)

                            }, 500);
                        }} />
                        <a hidden
                        ref={teste_certificate}
                        href={API_URL + 'courses/certificado/' + props.curso_id + '/' + user_id}

                        >
                            
                        </a>
                        <DefaultButton
                            onClick={() => {
                                teste_certificate.current.click();
                            }}
                            bg="confirm"
                            text="Visualizar o certificado"
                        />


                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Nome do usuário</th>
                                        <th>E-mail</th>
                                        <th>Aprovado</th>
                                        <th>Último Desempenho</th>

                                        <th>Baixou Certificado?</th>


                                        <th data-priority="3">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_users == false && users?.map((item, id) => (

                                        <tr key={id}>
                                            <td >{item.name}</td>
                                            {/* <td>Questão {id + 1}</td> */}

                                            <td>{item.email}

                                            </td>

                                            <td>{item.usercourses[0].completed_exam == true ? 'Sim' : 'Não'}

                                            </td>
                                            {item.usercourses[0].has_questions==true && item.usercourses[0].numero_acertos!=null && <td>
                                                {item.usercourses[0].numero_acertos}/{item.usercourses[0].numero_questoes}
                                            </td>}

                                            {item.usercourses[0].has_questions==true && item.usercourses[0].numero_acertos==null && <td>
                                               Não completou avaliação
                                            </td>}
                                            {item.usercourses[0].has_questions==false &&  <td>
                                               Sem questões
                                            </td>}

                                            {/* <td>
                                                {item?.courseStats?.numero_questoes ? item?.courseStats?.numero_acertos + "/" + item?.courseStats?.numero_questoes : "Sem questões"}
                                            </td> */}

                                            <td>{item.courseStats.download_certificate == true ? 'Sim' : 'Não'}

                                            </td>
                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={URL + 'storage/' + URL + 'storage/' + item.arquivo} /></td> */}
                                           

                                            
                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={URL + 'storage/' + URL + 'storage/' + item.arquivo} /></td> */}
                                            
                                            <td>
                                                {item.usercourses[0]?.completed_course && (item.usercourses[0]?.has_questions ? item.usercourses[0]?.completed_exam : true) && <a
                                                    href={API_URL + 'courses/certificado/' + props.curso_id + '/' + item["id"]}
                                                    className="inline-flex align-center jcc bg-success icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar certificado"
                                                    target="_blank"
                                                >
                                                    <BsDownload size={17} color="white" />
                                                </a>}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_users == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress/>
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_users == false && users?.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum usuário completou este curso</p>

                        </div>
                    </div>}
                    {/* <ModalQuestao showModal={showModal} setShowModal={(e)=>setShowModal(e)} questao={questaoSelect.questao} questao_number={questaoSelect.questao_number}  /> */}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingUsers(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            GetUsers(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"></div> */}
                    <CircularProgress/>
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingUsers(true);
                        // DeleteQuestao(questao_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao deletar esta questão, ela não estará mais disponível no sistema
                </SweetAlert>


            </div>

        </div>
    )
}

export default Certificates;

import React, { useEffect, useRef, useState } from "react";
import { Form, FormControl as Input, Button, InputGroup } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import Request from "../../../Request";
import ImageLoaderInput from "../../ReusableComponents/ImageLoaderInput";
import Attention from "../../ReusableComponents/Attention";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import SignatureCanvas from 'react-signature-canvas'
import AssinaturaInput from "../../ReusableComponents/AssinaturaInput";
import { URL } from "../../../variables";
import PhoneInput from "../../ReusableComponents/PhoneInput";
import { login, logout } from "../../../actions/AppActions";
import PasswordInput from "../../ReusableComponents/PasswordInput";
const ProfileEdit = props => {

    const [name, SetName] = useState("");
    const [original_name, SetOriginalName] = useState("");
    const [email, SetEmail] = useState("");
    const [roles, SetRoles] = useState([]);
    const [role, SetRole] = useState("");
    const [roleId, SetRoleId] = useState("");
    const [image, SetImage] = useState("");
    const [image_path, SetPath] = useState("");
    const [assinatura, SetAssinatura] = useState("");
    const [cargo, SetCargo] = useState("");
    const [type_assinatura, SetTypeAssinatura] = useState("Imagem");
    const [assinatura_atual, SetAssinaturaAtual] = useState(null);
    const [professions, SetProfessions] = useState([]);
    const [professionId, SetProfessionId] = useState("");
    const [phone, SetPhone] = useState("");

    const [loading, SetLoading] = useState(false);
    const [firstLoading, SetFirstLoading] = useState(false);

    const [poptitle, SetPopTitle] = useState("Atenção");
    const [popmessage, SetPopMessage] = useState("");
    const [popshow, SetPop] = useState(false);
    const [confirm_password,setConfirmPassword] = useState('');
    const [password,setPassword] = useState('');

    const { id } = useParams();

    const current_user = useSelector(store => store.AppReducer.user);

    const history = useHistory();
    const dispatch = useDispatch();

    const Submit = async (e) => {
        e.preventDefault();
        let token = localStorage.getItem("token");
        if (!token) return;
        if (!name) {
            SetPop(true);
            SetPopMessage("Insira um Nome");
            return;
        } else if (!email) {
            SetPop(true);
            SetPopMessage("Insira um E-mail");
            return;
        } else if (!role) {
            SetPop(true);
            SetPopMessage("Dê um tipo ao usuário");
            return;
        } else if (role == "Aluno" && !phone) {
            SetPop(true);
            SetPopMessage("Insira um número de telefone");
            return;
        } else if (role == "Aluno" && !professionId) {
            SetPop(true);
            SetPopMessage("Selecione uma profissão");
            return;
        }
        SetLoading(true);

        // else if (!image) {
        //     SetPop(true);
        //     SetPopMessage("Insira uma imagem");
        // }
        let form = new FormData();
        form.append("id", id);
        form.append("name", name);
        form.append("password", password);
        form.append("password_confirmation", confirm_password);

        form.append("email", email);
        form.append("image", image);
        form.append("role", role);
        form.append("cargo_id", roleId);

        if (role == 'Admin') {
            if (type_assinatura == 'Assinar') {
                form.append("assinatura", sigCanvas.current.toDataURL('image/png'));
            }
            else {
                form.append("assinatura", assinatura);
            }
            form.append("cargo", cargo);
        } else if (role == "Aluno") {
            form.append("phone", phone);
            form.append("profession_id", professionId);
        }
        let response = await Request.Post("register/edit", form, { "Authorization": `Bearer ${token}` });
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (!response) {
            SetPop(true);
            SetPopMessage("Erro ao se comunicar com o servidor");
        } else if (response["status"]) {
            dispatch(login({ token, user: response['user'] }))
            if (current_user.role == "Admin") history.push({
                pathname: "/accounts/list",
                state: {
                    snackbar: {
                        show: true,
                        message: "Usuário editado com sucesso"
                    }
                }
            });
            else history.push({
                pathname: `/accounts/profile/${id}`,
                state: {
                    snackbar: {
                        show: true,
                        message: "Perfil editado com sucesso"
                    }
                }
            });
            return;
        } else if (response?.status === false) {
            SetPop(true);
            SetPopMessage(response["message"]);
        } else if (!response?.status) {
            SetPop(true);
            SetPopMessage(Object.values(response?.errors)[0][0]);
        }

        SetLoading(false);
    }

    const Auth = () => {
        if (current_user["id"] == id || current_user["role"] == "Admin") return true;
        else return false;
    }

    const GetData = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;
        SetLoading(true);

        let response = await Request.Get(`register/id/${id}`);
        if (!response) return;
        console.log(response);
        response=response['user'];
        SetName(response["name"]);
        SetOriginalName(response["name"]);
        SetEmail(response["email"]);
        SetAssinaturaAtual(response["assinatura"]);
        SetCargo(response["cargo"] ?? '');
        SetProfessionId(response["profession_id"]);
        SetPhone(response["phone"]);

        SetRoleId(response["cargo_id"]);
        SetRole(response["role"]);
        SetImage(response["image"]);
        SetLoading(false);
        SetFirstLoading(true);
    };

    const GetRoles = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let response = await Request.Get("roles", { "Authorization": `Bearer ${token}` });
        if (!response) return;

        SetRoles(response);
    }

    const GetProfessions = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let response = await Request.Get("professions/all", { "Authorization": `Bearer ${token}` });
        if (!response) return;

        SetProfessions(response);
    }

    useEffect(() => {
        GetRoles();
        GetProfessions();
        GetData();
    }, []);

    let sigCanvas = useRef();
    if (!Auth()) return (<Redirect to="/" />);
    else if (!firstLoading) return <SkeletonProfileEdit />;
    return (
        <div className="card">
            <SweetAlert
                title={poptitle}
                onConfirm={() => SetPop(false)}
                show={popshow}
            >
                {popmessage}
            </SweetAlert>
            {/* {loading == true &&
                <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center mt-3" >
                    <div className="spinner-border text-primary" role="status"  >
                    </div>
                </div></div>
            } */}
            {loading == false && <div className="card-body">
                <div className="flex jcsb">
                    <h4 className="title">Página de edição</h4>
                </div>
                <p className="text-muted">Editando o perfil de: "{original_name}"</p>
                <br />
                <Form.Label>Imagem de perfil</Form.Label>
                <ImageLoaderInput path={image} ImageChange={(image) => SetImage(image)} />
                <div className="flex fdcolumn align-center">
                    <Form className="w100" onSubmit={Submit}>
                        <div className="flex fdcolumn flex1 margin-bottom">

                            <Form.Label>Nome <Attention /></Form.Label>
                            <Input placeholder="Nome" value={name} onChange={(e) => SetName(e.target.value)} required />
                        </div>
                        <div className="flex fdcolumn flex1 margin-bottom">

                            <Form.Label>E-mail <Attention /></Form.Label>
                            <Input placeholder="E-mail" value={email} onChange={(e) => SetEmail(e.target.value)} required />
                        </div>
                        <InputGroup>
                            <PasswordInput
                                label="Senha"
                                placeholder="Digite uma senha"
                                className="flex1"
                                value={password}
                                OnChangeText={(e) => setPassword(e.target.value )}
                                
                                title="Coloque a senha do usuário"
                            />
                        </InputGroup>
                        <InputGroup>
                            <PasswordInput
                                label="Confirmar senha"
                                placeholder="Confirmar senha"
                                className="flex1"
                                value={confirm_password}
                                OnChangeText={(e) => setConfirmPassword(e.target.value )}
                                title="Confirme a senha do usuário"
                                
                            />
                        </InputGroup>
                        {current_user["role"] == "Admin" && <div className="flex fdcolumn flex1 margin-bottom">

                            <Form.Label>Tipo de usuário <Attention /></Form.Label>

                            <select className="form-select " onChange={(e) => {
                                SetRoleId(e.target.value);
                                let rolesIndex = roles.findIndex(x => x["id"] == e.target.value);
                                SetRole(roles[rolesIndex]["name"]);
                            }} value={roleId} required>
                                <option value="">Selecione o tipo de usuário</option>
                                {(roles && roles.length != 0) && roles.map(value => (
                                    <option
                                        value={value["id"]}
                                        key={value["key"]}
                                    >
                                        {value["name"]}
                                    </option>
                                ))}
                                {/* <option value="Aluno">Aluno</option>
                                <option value="Admin">Admin</option> */}
                            </select>
                        </div>}
                        {role == "Aluno" &&
                            <InputGroup>
                                <div className="flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>Telefone <Attention /></Form.Label>
                                    <PhoneInput initialValue={phone} GetValue={(value) => SetPhone(value)} />
                                </div>
                            </InputGroup>}
                        {(role == "Aluno" && current_user["role"] == "Admin") &&
                            <InputGroup>
                                <div className="flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>Profissão <Attention /></Form.Label>
                                    <select className="form-select margin-bottom" onChange={(e) => SetProfessionId(e.target.value)} value={professionId}>
                                        <option value="">Selecione uma profissão</option>
                                        {(professions && professions.length != 0) && professions.map(value => (
                                            <option
                                                key={value["key"]}
                                                value={value["id"]}
                                            >{value["name"]}</option>
                                        ))}
                                    </select>
                                </div>
                            </InputGroup>}
                        {role == "Admin" && current_user["role"] == "Admin" && <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label>Cargo <Attention /></Form.Label>
                                <Input
                                    placeholder="Cargo"
                                    value={cargo}
                                    onChange={(e) => SetCargo(e.target.value)}
                                    required
                                    title="Coloque o cargo do usuário"
                                />
                            </div>
                        </InputGroup>}
                        {role == "Admin" && current_user["role"] == "Admin" && <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label>Assinatura <Attention /></Form.Label>
                                {assinatura_atual != null && <a target="_blank" className="margin-bottom" style={{ textDecoration: 'none' }} href={URL + 'storage/' + assinatura_atual} >Visualizar assinatura atual</a>}
                                <div>
                                    <DefaultButton
                                        text="Imagem"
                                        bg={type_assinatura == 'Imagem' ? "primary" : "secondary"}
                                        onClick={() => SetTypeAssinatura('Imagem')}
                                        style={{ marginBottom: "10px", marginRight: 10, width: 180 }}
                                    />
                                    <DefaultButton
                                        text="Assinar"
                                        bg={type_assinatura == 'Assinar' ? "primary" : "secondary"}
                                        onClick={() => SetTypeAssinatura('Assinar')}
                                        style={{ marginBottom: "10px", width: 180 }}
                                    />
                                </div>
                                <div hidden={type_assinatura == 'Imagem'}>

                                    <div style={{ border: '1px solid grey', width: 500, height: 150 }}>
                                        <SignatureCanvas canvasProps={{ width: 500, height: 150 }} ref={sigCanvas} />

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: 500 }}>
                                        <a style={{ textDecoration: 'none' }} href="#" onClick={(e) => {
                                            e.preventDefault();
                                            sigCanvas.current.clear();
                                        }}>Limpar</a>
                                    </div>
                                </div>

                                <div hidden={type_assinatura == 'Assinar'}>
                                    <AssinaturaInput ImageChange={assinatura => SetAssinatura(assinatura)} />
                                </div>

                            </div>
                        </InputGroup>}
                        <div className="w100 inline-flex jcsb margin-top-1">
                            {/* <Link to={`/accounts/profile/${id}`}> */}
                            {/* <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button> */}
                            <DefaultButton
                                icon={<RiArrowGoBackLine />}
                                text="Voltar"
                                bg="secondary"
                                to={(current_user["role"] == "Admin" ? "/accounts/list" : `/accounts/profile/${id}`)}
                            />
                            {/* </Link> */}
                            {/* <Button variant="success" type="submit">Salvar mudanças</Button> */}
                            <DefaultButton
                                bg="confirm"
                                text="Salvar alterações"
                                type="submit"
                                loading={loading}
                            />
                        </div>
                    </Form>
                </div>
            </div>}
        </div>
    );
}

const SkeletonProfileEdit = () => {
    return (
        <div className="card">
            <div className="card-body">
                <h3 className="title margin-bottom">Página de edição</h3>
                <p className="text-muted">Editando o perfil de: ...</p>
                <Form.Label>Imagem de perfil <Attention /></Form.Label>
                <div className="skeleton-base skeleton-shimmer" style={{ width: "7em", height: "9em", marginRight: "1em" }} />

                <Form.Label>Nome <Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{ height: "2em" }} />

                <Form.Label>E-mail<Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{ height: "2em" }} />

                <Form.Label>Tipo de usuário<Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{ height: "2em" }} />

                <Form.Label>Cargo<Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{ height: "2em" }} />

                <div className="flex w100 jcsb">
                    <div className="skeleton-small-button skeleton-shimmer" />
                    <div className="skeleton-medium-button skeleton-shimmer" />
                </div>
            </div>
        </div>
    );
}

export default ProfileEdit;
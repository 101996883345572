import React, { useState } from "react";

import { Button, FormControl as Input } from "react-bootstrap";

import {AiOutlineSearch} from "react-icons/ai";

const Search = React.forwardRef((props, ref) => {

    const [input_value, SetValue] = useState("");

    return(
        <div className="flex fdrow" style={{flexBasis: "50%"}}>
            <Input
                ref={ref}
                placeholder={props.placeholder} 
                value={input_value}
                className="wauto rounded-left flex1 margin-0"
                style={{borderRight: "none"}}
                onChange={(e) => {
                    SetValue(e.target.value);
                    if (!props.WaitConfirm) (props.ValueChange && props.ValueChange(e.target.value));
                }}
            />
            <Button
                className="bg-white rounded-right"
                style={{borderLeft: "none", borderColor: "rgba(0, 0, 0, 0.125)"}}
                onClick={() => (props.ValueChange && props.ValueChange(input_value))}
            ><AiOutlineSearch color="black"/></Button>
        </div>
    );
});

export default Search;
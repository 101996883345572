import React, { Component } from "react";

import { Form, FormControl as Input, Button, InputGroup, Spinner } from "react-bootstrap";

import { Link, Redirect, withRouter } from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";

import { ImImages } from "react-icons/im";
import { RiArrowGoBackLine } from "react-icons/ri";

import Request from "../../../Request";
import ImageLoaderInput from "../../ReusableComponents/ImageLoaderInput";
import LoadingScreen from "../../ReusableComponents/LoadingScreen";
import Attention from "../../ReusableComponents/Attention";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MdDelete } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";

class CreateCourse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            section: "",
            image: "",
            loading: false,
            show_sweet: false,
            message_sweet: "",
            title_sweet: '',
            success: false,
            loading_screen: true,
            users: [],
            selectUser: {},
            search: '',
            loading_users: false,
            carga_horaria:''
        };

        this.input_image = React.createRef();

        this.Submit = this.Submit.bind(this);
    }


    async GetUsers(search, screen = false) {
        const token = localStorage.getItem("token");
        if (!token) return;
        if (screen == true) {
            this.setState({ loading_screen: true })
        }
        else {
            this.setState({ loading_users: true, users: [] })

        }

        let response = await Request.Get(`register/admins?search=${search}`, { "Authorization": `Bearer ${token}` });

        if (!response) return;
        console.log(response);
        this.setState({ loading_screen: false, users: response, loading_users: false });

    }

    async Submit(e) {
        e.preventDefault();
        let token = localStorage.getItem("token");
        if (!token) return;

        let form = new FormData();
        form.append("name", this.state.name);
        form.append("description", this.state.description);
        form.append("section", this.state.section);
        form.append("image", this.state.image);
        form.append("carga_horaria", this.state.carga_horaria);
        form.append("responsavel_id", this.state.selectUser.id==undefined?'':this.state.selectUser.id);

        this.setState({ loading: true });

        let response = await Request.Post("courses", form, { "Authorization": `Bearer ${token}` });
        console.log(response);
        if (response["errors"]) {
            let errors = Object.values(response["errors"]);
            this.setState({
                show_sweet: true,
                message_sweet: errors.length > 0 ? errors[0] : "",
                title_sweet: 'Erro!',
                loading: false
            });
        } else if (response["status"]) {
            this.props.history.push({
                pathname: "/courses/list",
                state: {
                    snackbar: {
                        show: true,
                        message: "Curso criado com sucesso",
                        severity: "success"
                    }
                }
            });
        } else this.setState({ loading: false })
    }

    componentDidMount() {
        this.GetUsers(this.state.search, true);
    }

    render() {
        return (
            <div className="courses">
                <SweetAlert
                warning
                    title={this.state.title_sweet}
                    show={this.state.show_sweet}
                    onConfirm={() => {
                        this.setState({ show_sweet: false });
                    }}
                >
                    {this.state.message_sweet}
                </SweetAlert>
                <div className="card">
                    {this.state.loading_screen == false && <div className="card-body">
                        <h2 className="title">Criar novo curso</h2>
                        <p className="text-muted">Aqui são criados os cursos do sistema</p>
                        <br />
                        <Form style={{ position: "relative" }} onSubmit={this.Submit}>

                            <Form.Label>Imagem do curso <Attention /></Form.Label>
                            <ImageLoaderInput ImageChange={(image) => this.setState({ image: image })} />

                            <Form.Label>Nome do curso <Attention /></Form.Label>
                            <Input
                                value={this.state.name}
                                placeholder="Nome do curso"
                                onChange={(e) => this.setState({ name: e.target.value })}
                                required
                                className="margin-bottom"
                            />

                            <Form.Label>Descrição do curso <Attention /></Form.Label>
                            <Input
                                value={this.state.description}
                                onChange={(e) => this.setState({ description: e.target.value })}
                                as="textarea"
                                placeholder="Descrição do curso"
                                className="margin-bottom"
                            />

                            <Form.Label>Carga horária (Em horas) <Attention /></Form.Label>
                            <Input
                                value={this.state.carga_horaria}
                                onChange={(e) => this.setState({ carga_horaria: e.target.value })}
                                placeholder="Carga horária do curso (Em horas)"
                                className="margin-bottom"
                                type="number"
                                min="1"
                            />

                            <div className="flex jcsb flex-wrap margin-bottom">
                                <Form.Label>Responsável do Curso <Attention /></Form.Label>

                                <Autocomplete
                                    id="add-user-field"
                                    disableCloseOnSelect={false}
                                    blurOnSelect
                                    options={this.state.users}
                                    value={[this.state.selectUser]}
                                    multiple
                                    getOptionLabel={option => option.name ? option.name : ""}
                                    getOptionSelected={(option, value) => option.id == value.id}

                                    renderOption={(option, i) => (
                                        <div key={i} onClick={(e) => this.setState({ selectUser: option, search: '' }, () => {
                                            this.GetUsers(this.state.search, false);

                                        })} className="flex fdrow jcsb w100 align-center" >
                                            {option.name}
                                            {/* <CircularProgress className={loading ? "" : "hide"} style={{width: "20px", height: "20px"}}/> */}
                                            {/* <DefaultButton
                                    icon={<AddIcon size={17} style={{color: "white"}}/>}
                                    // onClick={() => AddUserToCourse(option["id"])}
                                    onClick={() => UpdateUserList(option["id"])}
                                    title="Adicionar usuário ao curso"
                                    bg="confirm"
                                    padding={0}
                                    width="2.2em"
                                    height="2.2em"
                                /> */}
                                        </div>
                                    )}
                                    style={{ width: "100%" }}
                                    noOptionsText={(
                                        this.state.loading_users == true ? <CircularProgress style={{ width: "20px", height: "20px" }} /> : <p>Nenhum usuário encontrado</p>
                                    )}
                                    renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                            <Input
                                                {...params.inputProps}
                                                placeholder="Procure por um usuário para ser o responsável do curso"
                                                value={this.state.search}
                                                onChange={(e) => this.setState({ search: e.target.value }, () => {
                                                    this.GetUsers(this.state.search, false);
                                                })}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="table-responsive margin-bottom">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>E-mail</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.selectUser['id'] != undefined && <tr >
                                            <td>{this.state.selectUser["name"]}</td>
                                            <td>{this.state.selectUser["email"]}</td>
                                            <td>
                                                <DefaultButton
                                                    icon={<MdDelete size={17} color="white" />}
                                                    bg="danger"
                                                    onClick={() => {
                                                        this.setState({ selectUser: {} })
                                                    }}
                                                    title="Remover usuário da lista"
                                                    width="2.2em"
                                                    height="2.2em"
                                                    padding="0"
                                                />
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                                <div className="w100 flex align-center jcc">
                                    {this.state.selectUser['id'] == undefined && "Nenhum responsável selecionado"}
                                </div>
                            </div>

                            {/* <Form.Label>Área do curso</Form.Label> */}
                            {/* <select className="form-select margin-bottom" value={this.state.section} onChange={(e) => this.setState({section: e.target.value})}>
                                <option value="">Área do curso</option>
                                <option value="opcaoteste">para teste</option>
                            </select> */}
                            <div className="w100 inline-flex jcsb margin-top-1">
                                <DefaultButton
                                    to={`/courses/list`}
                                    bg="secondary"
                                    text="Voltar"
                                    icon={<RiArrowGoBackLine />}
                                />
                                <DefaultButton
                                    type="submit"
                                    bg="confirm"
                                    loading={this.state.loading}
                                    text="Criar curso"
                                    loadingtext="Criando curso"
                                />
                            </div>
                        </Form>
                    </div>}
                    {/* {this.state.loading_screen == true && <div className="row">
                        <div className="col-12 d-flex justify-content-center align-items-center mt-3" >
                            <div className="spinner-border text-primary" role="status"  >
                            </div>
                    </div>
                    </div>} */}
                    {this.state.loading_screen &&
                        <Skeleton/>
                    }
                </div>
            </div>
        );
    }
}

const Skeleton = () => {
    return (
        <div className="card-body">
                <h3 className="margin-bottom title">Criar novo curso</h3>
                <p className="text-muted">Aqui são criados os cursos do sistema</p>
                <Form.Label>Imagem do curso <Attention /></Form.Label>
                <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "9em", marginRight: "1em"}}/>

                <Form.Label>Nome do curso <Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{height: "2em"}}/>

                <Form.Label>Descrição do curso<Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{height: "2em"}}/>

                <Form.Label>Carga horária (Em horas)<Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{height: "2em"}}/>

                <Form.Label>Responsável do Curso<Attention /></Form.Label>
                <div className="skeleton-base w100 skeleton-shimmer margin-bottom" style={{height: "2em"}}/>

                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{height: "4em"}}/>

                <div className="flex w100 jcsb">
                    <div className="skeleton-small-button skeleton-shimmer"/>
                    <div className="skeleton-medium-button skeleton-shimmer"/>
                </div>
        </div>
    );
}

export default withRouter(CreateCourse);
import { CircularProgress } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { RiArrowGoBackLine } from "react-icons/ri";

import { useLocation, useParams } from "react-router";
import Request from "../../../Request";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import Search from "../../ReusableComponents/Search";
import ProfessionBoard from "./ProfessionBoard";

const Professions = props => {

    const [professions, SetProfessions] = useState([]);
    
    const [loading, SetLoading] = useState(false);
    const [firstLoading, SetFirstLoading] = useState(false);

    const [page, SetPage] = useState(1);
    const [maxPage, SetMaxPage] = useState(1);

    const [search, SetSearch] = useState("");

    const location = useLocation();

    const {id} = useParams();

    const GetData = async () => {
        SetLoading(true);
        let response = await Request.Get(`professions?search=${search}&page=${page}`);
        if (response) {
            SetProfessions(response.data);
            SetMaxPage(response.last_page);
        }
        SetLoading(false);
        SetFirstLoading(true);
    }

    useEffect(() => {
        GetData();
    }, [search, page]);

    if (!firstLoading) return <Skeleton/>
    return (
        <div className="card">
            <div className="card-body">
                <h2 className="title">Profissões</h2>
                <p className="text-muted">Aqui são listados todos as profissões do sistema</p>
                <br />
                <div className="flex jcsb flex-wrap margin-bottom">
                    <Search
                        placeholder="Pesquisar profissões pelo Nome" 
                        ValueChange={value => SetSearch(value)}
                    />
                    <DefaultButton
                        bg="confirm"
                        to={`/professions/add`}
                        text="Adicionar profissões"
                    />
                </div>
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Data criado</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!loading && professions && professions.length != 0) && professions.map((value, index) => (
                                <ProfessionBoard
                                    key={index}
                                    id={value.id}
                                    name={value.name}
                                    date={value.created_at}
                                    OnDelete={(data) => GetData()}
                                />
                            ))}
                        </tbody>
                    </table>
                    <div className="w100 flex align-center jcc">
                        {!loading && (professions && professions.length == 0) ? "Nenhum aluno achado" : ""}
                    </div>
                </div>
                <div className="w100 flex jcc align-center">
                    {loading && <CircularProgress/>}
                </div>
                <div
                    className={(maxPage <= 1 ? "hide" : "")}
                >
                    <Pagination
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={maxPage}
                        value={page}
                        onChange={(_, value) => {
                            SetPage(value);
                        }}
                    />
                </div>
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to={location.state && location.state.from ? location.state.from : `/groups`}
                />
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div>
                    <div className="skeleton-title skeleton-shimmer"/>
                    <div className="skeleton-small-text skeleton-shimmer"/>
                </div>
                <br/>
                <div className="w100 flex jcsb">
                    <div className="skeleton-base skeleton-shimmer" style={{width: "50%"}}/>
                    <div className="skeleton-medium-button skeleton-shimmer"/>
                </div>
                <br/>
                <div className="skeleton-base skeleton-shimmer w100" style={{height: "10em"}}/>
            </div>
        </div>
    );
}

export default Professions;
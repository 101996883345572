import React, { useEffect, useState } from "react";

import { FormControl as Input } from "react-bootstrap";

import { BsDownload, BsFillStarFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Request from "../../../../../Request";
import DefaultButton from "../../../../ReusableComponents/DefaultButton";

import SweetAlert from "react-bootstrap-sweetalert";
import "./index.css";
import { API_URL } from "../../../../../variables";

const Feedback = (props) => {
  const { course_id, user_course, ...other } = props;

  const [rate, SetRate] = useState(0);
  const teste_certificate = React.createRef();
  const [comment, SetComment] = useState("");
  const [feedbackExist, SetFeedbackExist] = useState(false);
  const [show_warning, SetShowWarning] = useState(false);
  const [swal_warning, SetSwalWarning] = useState("");
  const [show_success, SetShowSuccess] = useState(false);

  const [loading, SetLoading] = useState(false);

  const user_id = useSelector((store) => store.AppReducer.user.id);
  const history = useHistory();

  const messages = ["Péssimo", "Ruim", "Mais ou menos", "Bom", "Excelente"];

  const GetData = async () => {
    SetLoading(true);
    let response = await Request.Get(
      `courses/feedback/one/${course_id}/${user_id}`
    );
    SetLoading(false);
    console.log(response);
    if (!response) return;

    response["comment"] && SetComment(response["comment"]);
    response["rate"] && SetRate(response["rate"]);
    response["comment"] && response["rate"] && SetFeedbackExist(true);
  };

  const Submit = async () => {
    if (rate == 0) {
      SetSwalWarning("Você precisas escolher quantas estrelas o curso merece!");
      SetShowWarning(true);
      return;
    } else if (comment.length < 1) {
      SetSwalWarning("Você precisa comentar sobre o curso!");
      SetShowWarning(true);
      return;
    }

    let form = new FormData();
    form.append("course_id", course_id);
    form.append("user_id", user_id);
    form.append("rate", rate);
    form.append("comment", comment);

    let response = await (feedbackExist
      ? Request.Post(`courses/feedback/update`, form)
      : Request.Post(`courses/feedback/insert`, form));
    console.log(response);
    if (response["status"]) {
      SetFeedbackExist(true);
      SetShowSuccess(true);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <div className="d-flex flex-column">
      <SweetAlert
        success
        title={"Sucesso!"}
        onConfirm={() => {
          SetShowSuccess(false);
        }}
        // onCancel={this.onCancel.bind(this)}
        show={show_success}
        confirmBtnText="Ok"
        cancelBtnText="Cancelar"
        confirmBtnBsStyle="info"
        // cancelBtnBsStyle="success"
        showCancel={false}
      >
        Sua avaliação foi enviada com sucesso! Agora você pode baixar o
        certificado!
      </SweetAlert>

      <div className="feedback w100" {...other} hidden={feedbackExist}>
        <h2 className="title">Avalie o curso!</h2>
        <br />
        <div className="rate">
          <div
            className="star-rate"
            style={{
              position: "absolute",
              zIndex: 99,
            }}
          >
            {(() => {
              let rates = [];
              for (let i = 0; i < rate; i++) {
                rates.push(
                  <BsFillStarFill
                    key={i}
                    className="star-over"
                    size={25}
                    onClick={() => SetRate(i + 1)}
                  />
                );
              }
              return rates;
            })()}
          </div>
          <div className="stars-placeholders">
            {(() => {
              let rates = [];
              for (let i = 0; i < 5; i++) {
                rates.push(
                  <BsFillStarFill
                    key={i}
                    className="star-placeholder"
                    style={{
                      ...(rate >= i + 1 && { visibility: "hidden" }),
                    }}
                    size={25}
                    onClick={() => SetRate(i + 1)}
                  />
                );
              }
              return rates;
            })()}
          </div>
          <p className="text-muted" hidden={rate == 0}>
            {rate} estrelas: {messages[rate - 1]}
          </p>
        </div>
        <br />
        <div className="comment w100">
          <Input
            placeholder="Adicione um comentário para a sua avaliação!"
            as="textarea"
            value={comment}
            onChange={(e) => SetComment(e.target.value)}
          />
        </div>
        <br />
        <DefaultButton
          text={feedbackExist ? "Mudar avaliação" : "Mandar avaliação"}
          bg="confirm"
          onClick={Submit}
          loading={loading}
        />
        <SweetAlert
          warning
          title={"Atenção!"}
          onConfirm={()=>SetShowWarning(false)}
          // onCancel={this.onCancel.bind(this)}
          show={show_warning}
          confirmBtnText="Ok"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="info"
          // cancelBtnBsStyle="success"
          showCancel={false}
        >
          {swal_warning}
        </SweetAlert>
      </div>
      <a
        hidden
        target={"_blank"}
        ref={teste_certificate}
        href={
          API_URL +
          "courses/certificado/" +
          user_course.course_id +
          "/" +
          user_course.user_id
        }
      ></a>

      <span
        className="text-muted mt-5"
        hidden={feedbackExist}
      >
        Para baixar o certificado, você precisa antes avaliar o curso!
      </span>

      <DefaultButton
        onClick={() => {
          teste_certificate.current.click();
        }}
        bg="confirm"
        disabled={!feedbackExist}
        icon={<BsDownload style={{ marginRight: 5 }} />}
        text="Baixar certificado"
        style={{ width: "100%", marginTop: 15 }}
      />
    </div>
  );
};

export default Feedback;

import React from "react";

import "../../css/homepage.css";
import UserCoursesImporter from "../Cadastros/Courses/UserCoursesImporter";
import Logo from '../../assets/images/logo.png';

const UserHomepage = () => {
    return (
        <div className="home">
            {/* <div className="head">
                <div id="top">
                    <div className="headline">
                        KZT - Solução de vídeos
                    </div>
                </div>
                
            </div> */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img className="img-fluid" src={Logo} alt="" height="100" style={{ height: '200px' }} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }}>

                <h4 style={{ textAlign: 'center', fontWeight: 500 }}>KZT - Solução de vídeos</h4>
            </div>
            <div className="card mt-3">
                <div className="card-body">

                    <div id="in-courses">
                        <h3 className="text-black">Continue de onde parou</h3>
                        <div style={{ marginLeft: '-1rem', marginRight: '-1rem' }}>

                            <UserCoursesImporter />
                        </div>

                    </div>
                    <div id="not-in-courses">
                        <h3 className="text-black">Outros cursos</h3>
                        <div style={{ marginLeft: '-1rem', marginRight: '-1rem' }}>

                        <UserCoursesImporter
                            NotIn
                        />
                        </div>

                    </div>
                </div>
            </div>

            <div className="footer">

            </div>
        </div>
    );
}

export default UserHomepage;
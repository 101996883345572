import React, { useEffect, useState } from "react";
import Request from "../../../../Request";

import "./index.css";
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Form, FormControl as Input } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";

const Statistic = props => {

    const {
        course_id
    } = props;

    const [lessonCount, SetLessonCount] = useState(0);
    const [userCount, SetUserCount] = useState(0)
    const [courseCompletions, SetCourseCompletions] = useState(0);
    const [courseIncompletions, SetCourseIncompletions] = useState(0);
    const [examCompletions, SetExamCompletions] = useState(0);
    const [certificatesDownloaded, SetCertificatesDownloaded] = useState(0);
    const [rightAverage, SetRightAverage] = useState(0);
    const [progressAverage, SetProgressAverage] = useState(0);
    const [totalDocs, SetTotalDocs] = useState(0);
    const [totalVideos, SetTotalVideos] = useState(0);
    const [totalSubRequests, SetTotalSubRequests] = useState(0);
    const [groupsInside, SetGroupsInside] = useState(0);
    const [totalExamQuestions, SetTotalExamQuestions] = useState(0);

    const [fim_periodo, setFimPeriodo] = useState('');
    const [inicio_periodo, setInicioPeriodo] = useState('');
    const [loading, SetLoading] = useState(false);



    const GetData = async () => {
        SetLoading(true);
        let query='?';
        if(inicio_periodo!=''){
            query+='start='+inicio_periodo;
        }
        if(fim_periodo!=''){
            query+=(query=='?'?'':'&')+ 'end='+fim_periodo;
        }
        let response = await Request.Get(`courses/statistics/${course_id}${query}`);
        console.log(response);
        if (!response || !response["status"]) return;

        SetLoading(false);

        SetLessonCount(response.lesson_count);
        SetUserCount(response.user_count);
        SetCourseCompletions(response.course_completions);
        SetCourseIncompletions(response.not_course_completions);
        SetExamCompletions(response.exam_completions);
        SetCertificatesDownloaded(response.certificates_downloads);
        SetRightAverage(response.right_average);
        SetProgressAverage(response.progress_average);
        SetTotalDocs(response.total_docs);
        SetTotalVideos(response.total_videos);
        SetTotalSubRequests(response.total_sub_request);
        SetGroupsInside(response.groups_inside);
        SetTotalExamQuestions(response.total_exam_questions);
    }

    useEffect(() => {
        GetData();
    }, [inicio_periodo,fim_periodo]);

    return (
        <div className="statistics">
            <p className="text-muted">Aqui ficam as informações gerais do curso</p>
            <br />
            <div className="row">

                <div className="d-flex margin-bottom">
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginRight:15}}>
                        <span style={{ color: 'black', fontWeight: 'bold' }}>Período: </span>
                    </div>
                    <div>
                    <Input 
                        placeholder="De"
                        value={inicio_periodo}
                        type={"date"}
                        style={{width:200,marginRight:15}}
                        onChange={(e) => setInicioPeriodo(e.target.value)}
                    />
                    
                    </div>
                   
 <Input 
                        placeholder="Até"
                        value={fim_periodo}
                        type={"date"}
                        style={{width:200,marginRight:15}}

                        onChange={(e) => setFimPeriodo(e.target.value)}
                    />
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginRight:15}}>

                     <a style={{cursor:'pointer',fontSize:13,color:'#73B054'}} onClick={()=>{
                        setInicioPeriodo('');
                        setFimPeriodo('');

                    }}>Limpar</a>
                    </div>

                <CircularProgress className={loading ? "" : "hide"} style={{ width: "30px", height: "30px" }} />

               

                    
                </div>
                

            </div>
            
            <div className="table-responsive">
                <table className="table table-striped left-align-header">
                    <tbody>
                        <tr>
                            <th>Quantidade de aulas</th>
                            <td>{lessonCount}</td>
                        </tr>
                        <tr>
                            <th>Usuários</th>
                            <td>{userCount}</td>
                        </tr>
                        <tr>
                            <th>Usuários que viram todo o curso</th>
                            <td>{courseCompletions}</td>
                        </tr>
                        <tr>
                            <th>Usuários que não viram todo o curso</th>
                            <td>{courseIncompletions}</td>
                        </tr>
                        <tr>
                            <th>Usuários que fizeram a prova</th>
                            <td>{examCompletions}</td>
                        </tr>
                        <tr>
                            <th>Usuários que baixaram o certificado</th>
                            <td>{certificatesDownloaded}</td>
                        </tr>
                        <tr>
                            <th>Média de acertos</th>
                            <td>{rightAverage}</td>
                        </tr>
                        <tr>
                            <th>Total de questões</th>
                            <td>{totalExamQuestions}</td>
                        </tr>
                        <tr>
                            <th>Média de progresso do curso</th>
                            <td>{progressAverage}%</td>
                        </tr>
                        <tr>
                            <th>Documentos</th>
                            <td>{totalDocs}</td>
                        </tr>
                        <tr>
                            <th>Vídeos</th>
                            <td>{totalVideos}</td>
                        </tr>
                        <tr>
                            <th>Pedidos de inscrição ativos</th>
                            <td>{totalSubRequests}</td>
                        </tr>
                        <tr>
                            <th>Grupos no curso</th>
                            <td>{groupsInside}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Statistic;
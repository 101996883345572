import React, { useEffect, useState } from "react";

import {FormControl as Input} from "react-bootstrap";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Request from "../../../../Request";
import { useHistory, useParams } from "react-router";

import {MdDelete} from "react-icons/md";
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";

const AddToCourse = props => {

    const [groups, SetGroups] = useState([]);
    const [toAddGroups, SetToAddGroups] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [search, SetSearch] = useState("");

    const {course_id} = useParams();

    const history = useHistory();

    const GetGroups = async () => {
        let response = await Request.Get(`groupcourse/not-from-course/${course_id}?search=${search}`);
        if (!response) return;
        SetGroups(response);
    }

    const UpdateGroupList = (user_id) => {
        let toAddGroupsTemp = toAddGroups;
        console.log(toAddGroupsTemp)

        if (!toAddGroupsTemp.find(x => x.id == user_id))
            toAddGroupsTemp.push(groups.find(x => x.id == user_id));
        console.log(toAddGroupsTemp)
        SetToAddGroups([...toAddGroupsTemp]);
        SetSearch("");
    }

    const RemoveGroupFromGroupList = index => {
        let toAddGroupsTemp = toAddGroups;
        toAddGroupsTemp.splice(index, 1);
        SetToAddGroups([...toAddGroupsTemp]);
    }

    const Submit = async () => {
        let form = new FormData();
        form.append("course_id", course_id);
        // form.append("group_id", toAddGroups[0]["id"]);//colocar um for aqui pra adicionar mais grupos de uma vez

        for (let i = 0; i < toAddGroups.length; i++) form.append("group_id[]", toAddGroups[i]["id"]);

        SetLoading(true);
        let response = await Request.Post("groupcourse/add-group", form);
        if (!response) return;
        else if (response["status"]) history.push({
            pathname: `/courses/course/${course_id}`,
            search: `?${new URLSearchParams({tab: "2"}).toString()}`
        });
        SetLoading(false);
    }

    useEffect(() => {
        GetGroups();
        console.log(search)
    }, [search]);

    return (
        <div>
            <Autocomplete
                id="add-group-field"
                disableCloseOnSelect={false}
                blurOnSelect
                options={groups.filter(user => !toAddGroups.map(value => value["id"]).includes(user["id"]))}
                value={toAddGroups}
                multiple
                getOptionSelected={(option,value) => option.id ==value.id}

                getOptionLabel={option => option.name ? option.name : ""}
                renderOption={(option, i) => (
                    <div key={i} onClick={(e) => UpdateGroupList(option["id"])} className="flex fdrow jcsb w100 align-center" >
                        {option.name}
                        {/* <CircularProgress className={loading ? "" : "hide"} style={{width: "20px", height: "20px"}}/> */}
                        {/* <DefaultButton
                            icon={<AddIcon size={17} style={{color: "white"}}/>}
                            // onClick={() => AddUserToCourse(option["id"])}
                            onClick={() => UpdateUserList(option["id"])}
                            title="Adicionar usuário ao curso"
                            bg="confirm"
                            padding={0}
                            width="2.2em"
                            height="2.2em"
                        /> */}
                    </div>
                )}
                style={{ width: "100%" }}
                noOptionsText={(
                    <p>Nenhum grupo encontrado</p>
                )}
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <Input
                            {...params.inputProps}
                            placeholder="Procurar grupos que não estão no curso"
                            autoFocus
                            value={search}
                            onChange={(e) => SetSearch(e.target.value)}
                        />
                    </div>
                )}
            />
            <div className="table-responsive margin-bottom">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Descrição</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            toAddGroups.map((value, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{value["name"]}</td>
                                        <td>{value["description"]}</td>
                                        <td>
                                            <DefaultButton
                                                icon={<MdDelete size={17} color="white" />}
                                                bg="danger"
                                                onClick={() => RemoveGroupFromGroupList(index)}
                                                title="Remover grupo da lista"
                                                width="2.2em"
                                                height="2.2em"
                                                padding="0"
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                <div className="w100 flex align-center jcc">
                    {toAddGroups.length == 0 && !loading ? "Nenhum grupo selecionado" : ""}
                </div>
            </div>
            <div className="w100 inline-flex jcsb mt-2">
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to={`/courses/course/${course_id}`}
                    search={'?'+new URLSearchParams({tab: 2}).toString()}
                />
                <DefaultButton
                    bg="confirm"
                    text={toAddGroups.length <= 1 ? "Adicionar grupo" : "Adicionar grupos"}
                    onClick={() => Submit()}
                    loading={loading}
                    disabled={toAddGroups.length == 0}
                />
            </div>
        </div>
    );
}

export default AddToCourse;
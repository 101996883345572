import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { BsFillEyeFill } from "react-icons/bs";
import ContentDotOverflow from "../../../ReusableComponents/ContentDotOverflow";
import DefaultButton from "../../../ReusableComponents/DefaultButton";

const Board = props => {

    const {
        name,
        comment,
        rate,
        date
    } = props;

    const [popup, SetPopup] = useState(false);

    return (
        <tr>
            <td>{name}</td>
            {/* <ContentDotOverflow element="td">
                {comment}
            </ContentDotOverflow> */}
            <td style={{
                whiteSpace: "nowrap",
                width: "50px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "50px"
            }}>{comment}</td>
            <td>{rate}</td>
            <td>{new Date(Date.parse(date)).toLocaleDateString("pt-BR")}</td>
            <td>
                <SweetAlert
                    onConfirm={() => SetPopup(false)}
                    confirmBtnText="Fechar"
                    show={popup}
                >{comment}</SweetAlert>
                <DefaultButton
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Ver feedback com mais detalhes`}
                    bg="warning"
                    icon={<BsFillEyeFill size={17} color="white"/>}
                    onClick={() => SetPopup(true)}
                />
            </td>
        </tr>
    );
}

export default Board;
import React, { useEffect, useState } from 'react';
import { URL } from '../variables';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../Header';
import Aside from '../SideBar/Aside';

import Unidades from "./Cadastros/Unidade/Unidades";
import CriarUnidade from "./Cadastros/Unidade/CriarUnidade";
import EditarUnidade from "./Cadastros/Unidade/EditarUnidade";
import RegisterUser from './Cadastros/Users/RegisterUser';

import '../SideBar/styles/App.scss';
import { mudarDados } from '../actions/AppActions';
import { Route, Switch } from 'react-router';
import UserList from './Cadastros/Users/UserList';
import CreateCourse from './Cadastros/Courses/CreateCourse';
import Courses from './Cadastros/Courses/Courses';
import CoursePage from './Cadastros/Courses/CoursePage';
import EditCourse from './Cadastros/Courses/EditCourse';
import CreateLessons from './Cadastros/Lessons/CreateLessons';
import EditLesson from './Cadastros/Lessons/EditLesson';
import ProfilePage from './Cadastros/Profile/ProfilePage';
import ProfileEdit from './Cadastros/Profile/ProfileEdit';
import { Redirect } from 'react-router-dom';
import LessonPage from './Cadastros/Lessons/LessonPage';
import Request from '../Request';
import CreateAvaliacao from './Cadastros/Courses/Avaliacao/CreateAvaliacao';
import EditAvaliacao from './Cadastros/Courses/Avaliacao/EditAvaliacao';
import UsersNotInCourse from './Cadastros/Courses/Users/UsersNotInCourse';
import UserCourseDetail from './Cadastros/Courses/Users/UserCourseDetail';

import UsersCoursePage from './Cadastros/Courses/Users/UsersCoursePage';
import UsersLessonPage from './Cadastros/Lessons/Users/UsersLessonPage';
import Dashboard from './Dashboard';
import UserHomepage from './UserHomepage/UserHomepage';
import Prova from './Cadastros/Courses/Avaliacao/Prova';
import Group from './Cadastros/UserGroup/UserGroup';
import CreateGroup from './Cadastros/UserGroup/CreateGroup';
import EditGroup from './Cadastros/UserGroup/EditGroup';
import ViewGroup from './Cadastros/UserGroup/ViewGroup/';
import AddUser from './Cadastros/UserGroup/ViewGroup/AddUser';
import AddToCourse from './Cadastros/Courses/Group/AddToCourse';
import Profissions from './Cadastros/Profissions';
import CreateProfession from './Cadastros/Profissions/CreateProfession';
import EditProfession from './Cadastros/Profissions/EditProfession';
import AddProfession from './Cadastros/UserGroup/ViewGroup/AddProfession';
import RespostasUser from './Cadastros/Courses/Users/RespostasUser';


const Home = (props) => {
    const toggled = useSelector(store => store.AppReducer.toggled)
    const collapsed = useSelector(store => store.AppReducer.collapsed)
    const user = useSelector(store => store.AppReducer.user);
    const token = useSelector(store => store.AppReducer.token);
    const adminAsUser = useSelector(store => store.AppReducer.adminAsUser);

    const dispatch = useDispatch();
    const [loading_home, setLoadingHome] = useState(false);
    const [image, setImage] = useState(true);
    // const [toggled, setToggled] = useState(false);
    const handleCollapsedChange = (checked) => {
        dispatch(mudarDados({ collapsed: checked }));

    };

    const GetRole = async () => {
        let response = await Request.Get(`roles/${user["cargo_id"]}`)
        if (!response) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');

            dispatch({type:'logout',payload:{}});
        }
        else{
            user.role = response["name"];
            dispatch({type:'login',payload:{token:token,user:user}});
            dispatch({
                type: "change_admin", 
                payload: {
                    is_admin: (response["name"] == "Admin" ? true : false)
                }
            });
        }
        
    }

    const handleImageChange = (checked) => {
        setImage(checked);
    };

    const handleToggleSidebar = (value) => {
        // setToggled(value);
        dispatch(mudarDados({ toggled: value }));
    };

    const HandlePath = (admin_component, user_path, allowAdminAsUser = false) => {
        if (user.role == "Admin") {
            if (adminAsUser && allowAdminAsUser) return user_path;
            return admin_component;
        }
        else return user_path;
    }

    useEffect(() => {
        const event = (e) => {
            if (window.innerWidth <= 768) {
                dispatch(mudarDados({ toggled: false, collapsed: false }));

            }
            else {

                dispatch(mudarDados({ toggled: true, collapsed: true }));

            }
        };
        window.addEventListener('resize', event)
        if (window.innerWidth <= 768) {
            dispatch(mudarDados({ toggled: false, collapsed: false }));
        }
        else {
            dispatch(mudarDados({ toggled: true, collapsed: true }));
        }
        GetRole();

        return () => {
            window.removeEventListener('resize', event);

            // Anything in here is fired on component unmount.
        }
    }, [])

    const marginLeft = (toggled==false || window.innerWidth<=768)?0:(collapsed==false?270:80);

    return (
        <div className={`app ${toggled ? 'toggled' : ''}`}>
            <Aside
                image={image}
                collapsed={collapsed}
                toggled={toggled}
                collapsed={collapsed}

                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}

            />
            <Header
                image={image}
                toggled={toggled}
                collapsed={collapsed}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
                handleImageChange={handleImageChange}
            />
            <div className="content-page" >
                <div className="content" style={{marginLeft: marginLeft,transition: 'all 0.3s ',paddingBottom:100,background:'#f8f8fa'}}>
                    <div className="container-fluid">
                        <Switch>
                            <Route exact path={`${props.match.url}`} render={(props2) => HandlePath(<Dashboard {...props2} />, <UserHomepage {...props}/>)} />
                            <Route exact path={`${props.match.url}courses/course/add-user/:course_id`} render={(props2) => HandlePath(<UsersNotInCourse/>, <Redirect to="/courses/list"/>)} />
                            <Route exact path={`${props.match.url}courses/course/add-group/:course_id`} render={(props2) => HandlePath(<AddToCourse/>, <Redirect to="/courses/list"/>)} />

                            <Route exact path={`${props.match.url}courses/course/detail_user/:course_id/:user_id`} render={(props2) => HandlePath(<UserCourseDetail {...props2} />, <Redirect to="/courses/list"/>)} />
                            <Route exact path={`${props.match.url}courses/course/answer/:course_id/:user_id/:tentativa_id`} render={(props2) => HandlePath(<RespostasUser {...props2} />, <Redirect to="/courses/list"/>)} />
                            

                            <Route exact path={`${props.match.url}accounts/register`} render={(props2) => HandlePath(<RegisterUser {...props2} />, <Redirect to="/"/>)} />
                            <Route exact path={`${props.match.url}accounts/list`} render={(props2) => HandlePath(<UserList {...props2} />, <Redirect to="/"/>)} />
                            <Route exact path={`${props.match.url}accounts/profile/:id`} render={(props2) => <ProfilePage {...props2} />} />
                            <Route exact path={`${props.match.url}accounts/edit/:id`} render={(props2) => <ProfileEdit {...props2} />} />

                            <Route exact path={`${props.match.url}groups`} render={(props2) => HandlePath(<Group {...props2} />, <Redirect to="/"/>)} />
                            <Route exact path={`${props.match.url}groups/create`} render={(props2) => HandlePath(<CreateGroup {...props2} />, <Redirect to="/"/>)} />
                            <Route exact path={`${props.match.url}groups/edit/:id`} render={(props2) => HandlePath(<EditGroup {...props2} />, <Redirect to="/"/>)} />
                            <Route exact path={`${props.match.url}groups/view/:id`} render={(props2) => HandlePath(<ViewGroup {...props2} />, <Redirect to="/"/>)} />
                            <Route exact path={`${props.match.url}groups/add/users/:id`} render={(props2) => HandlePath(<AddUser {...props2} />, <Redirect to="/"/>)} />
                            <Route exact path={`${props.match.url}groups/add/professions/:id`} render={(props2) => HandlePath(<AddProfession {...props2} />, <Redirect to="/"/>)} />

                            <Route exact path={`${props.match.url}professions`} render={(props2) => HandlePath(<Profissions {...props2} />, <Redirect to="/"/>)} />
                            <Route exact path={`${props.match.url}professions/add`} render={(props2) => HandlePath(<CreateProfession {...props2} />, <Redirect to="/"/>)} />
                            <Route exact path={`${props.match.url}professions/edit/:id`} render={(props2) => HandlePath(<EditProfession {...props2} />, <Redirect to="/"/>)} />
        
                            <Route exact path={`${props.match.url}courses/register`} render={(props2) => HandlePath(<CreateCourse {...props2} />, <Redirect to="/courses/list"/>)} />
                            <Route exact path={`${props.match.url}courses/list`} render={(props2) => <Courses {...props2} />} />
                            <Route exact path={`${props.match.url}courses/course/:id`} render={(props2) => HandlePath(<CoursePage {...props2} />, <UsersCoursePage {...props2}/>, true)} />
                            <Route exact path={`${props.match.url}courses/edit/:id`} render={(props2) => HandlePath(<EditCourse {...props2} />, <Redirect to="/courses/list"/>)} />

                            <Route exact path={`${props.match.url}lesson/register`} render={(props2) => HandlePath(<CreateLessons {...props2} />, <Redirect to="/"/>)} />
                            <Route exact path={`${props.match.url}lesson/edit/:id`} render={(props2) => HandlePath(<EditLesson {...props2} />, <Redirect to="/lesson/:id"/>)} />
                            <Route exact path={`${props.match.url}lesson/:lesson_id`} render={(props2) => <LessonPage {...props2}/>}/>

                            <Route exact path={`${props.match.url}questoes/register/:id`} render={(props2) => HandlePath(<CreateAvaliacao {...props2} />, <Redirect to="/"/>)} />
                            <Route exact path={`${props.match.url}questoes/edit/:id`} render={(props2) => HandlePath(<EditAvaliacao {...props2} />, <Redirect to="/"/>)} />

                            <Route exact path={`${props.match.url}courses/course_avaliation/:curso_id`} render={(props2) => <Prova {...props2} />} />

                        </Switch>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Home;
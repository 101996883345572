import React, { useState, useRef, useEffect } from "react";

// import {FormControl as Input, Button} from "react-bootstrap";
import { Button, Fab, IconButton, Modal } from "@material-ui/core";

import { ImImages } from "react-icons/im";
import { AiOutlineClose } from "react-icons/ai";
import { RiDeleteBin5Line, RiVideoUploadFill } from "react-icons/ri";

import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from '@material-ui/icons/Image';


import { STORAGE_URL } from "../../variables";

import "../../css/video-input.css";
import DefaultButton from "./DefaultButton";
import { Form, FormControl as Input, Card,ProgressBar } from "react-bootstrap";

const VideoLoaderInput = ({ VideoChange, multiple = false,existing_videos=[],HandleExistingVideoDeletion=()=>{},changeExisting }) => {

    const [videos, SetVideos] = useState([]);
    const [videos_size, SetSize] = useState(0);
    const [video_select, SetVideoSelect] = useState(null);

    const video_input = useRef(null);
    const image_input = useRef(null);

    const HandleInputReceive = () => {
        let files = video_input.current.files;
        let temp_videos = videos;

        for (let i = 0; i < files.length; i++) {
            temp_videos.push(files[i]);
        }

        SetVideos([...temp_videos]);
        video_input.current.value = "";
    }

    const HandleVideoDeletion = index => {
        let temp_videos = videos;
        temp_videos.splice(index, 1);
        SetVideos([...temp_videos]);
    }

    useEffect(() => {
        VideoChange && VideoChange(videos);

        let temp_size = 0;
        for (let i = 0; i < videos.length; i++) {
            temp_size += videos[i].size;
        }
        SetSize(temp_size);
    }, [videos]);

    return (
        <div className="video-input">
            <input
                type="file"
                accept="video/*"
                className="hide"
                multiple={multiple}
                ref={video_input}
                onChange={HandleInputReceive}
            />

            <input
                type="file"
                hidden
                accept="image/*"
                className="hide"
                ref={image_input}
                onChange={(e)=>{
                    if(!e.target.files || e.target.files[0]==undefined){
                        return;
                    }
                    if(video_select.type=='videos'){
                        let videos_ = [...videos]
                        videos_[video_select.id].cover_file=e.target.files[0];
                        videos_[video_select.id].cover=URL.createObjectURL(e.target.files[0]);
                        SetVideos(videos_);
                    }
                    else{
                        let videos_ = [...existing_videos]
                        videos_[video_select.id].cover_file=e.target.files[0];
                        videos_[video_select.id].cover=URL.createObjectURL(e.target.files[0]);
                        changeExisting(videos_);
                    }
                }}
            />


            <div className="video-collection">
                {
                    existing_videos.map((value, i) => (
                        <VideoInputBox
                            key={i}
                            index={value["id"]}
                            videoName={value["name"]}
                            videoCover={(value['cover_file']?'':(value["cover"]?STORAGE_URL:''))+ (value["cover"]??'')}
                            index_file={i}
                            onChangeName={(e)=>{
                                console.log(value['cover_file'])
                                let videos_new = [...existing_videos];
                                videos_new[i].name = e;
                                changeExisting(videos_new);
                            }}
                            coverImage={()=>{
                                SetVideoSelect({type:'existing_videos',id:i});
                                image_input.current.click();
                            }}
                            url={STORAGE_URL + value["path"]}
                            onClose={index => HandleExistingVideoDeletion(index, i)}
                        />
                    ))
                }
                {
                    videos.map((value, index) => (
                        <VideoInputBox
                            key={index}
                            video={value}
                            videoCover={value.cover}
                            id={index}
                            index={index}
                            newVideo
                            onChangeName={(e)=>{
                                let videos_new = [...videos];
                                videos_new[index].name_file = e;
                                SetVideos(videos_new);
                            }}
                            coverImage={()=>{
                                SetVideoSelect({type:'videos',id:index});
                                image_input.current.click();
                            }}
                            onClose={(index) => HandleVideoDeletion(index)}
                        />
                    ))
                }
            </div>

            <div className={(videos_size == 0 ? "hide" : "")}>
                <p className="text-muted">Tamanho total dos vídeos: <b>{(videos_size / 1000000).toFixed(2)} MB</b></p>
            </div>

            <DefaultButton
                text="Selecionar vídeos"
                bg="primary"
                onClick={() => video_input.current.click()}
                style={{ marginBottom: "10px" }}
            />
        </div>
    );
}

export const VideoInputBox = ({ video, index,coverImage, onClose, url = "", newVideo = false, index_file = 1, videoName,onChangeName,videoCover }) => {

    const [name, SetName] = useState("");
    const [modal, SetModal] = useState(false);

    const thumb_ref = useRef();

    useEffect(() => {
        if (video === undefined) return;
        thumb_ref.current.src = URL.createObjectURL(video);
        SetName(video.name);
    }, [video]);

    useEffect(() => {
        if (videoName != "" && videoName !== undefined && videoName !== null && name=='') SetName(videoName);


    }, [videoName]);

    useEffect(() => {
        onChangeName(name);
    }, [name]);

    return (
        <div className={"video-input-box " + (newVideo ? "video-new" : "")}>
            <IconButton
                id="remove-button"
                size="small"
                onClick={() => (onClose && onClose(index))}
            >
                <CloseIcon style={{
                    width: "0.7em",
                    color: "white"
                }} />
            </IconButton>

            <video
                autoPlay={false}
                controls={false}
                poster={videoCover ?? ''}
                className="video-thumb"
                ref={thumb_ref}
                src={url}
                style={{height:150}}
                onError={(e)=>{
                    alert(`Error ${e.target.error.code}; details: ${e.target.error.message}`)
                }}
                // onClick={() => alert(0)}
            />
            <div className="video-name" style={{display:'flex'}}>
                <Input  placeholder="Nome do vídeo"  onChange={(e) => SetName(e.target.value)} value={name}/>
                <IconButton
                // id="remove-button"
                size="small"
                onClick={() => {
                    coverImage();
                }}
            >
                <ImageIcon style={{
                    width: "0.7em",
                    color: "black"
                }} />
            </IconButton>
                {/* {name ? name : "Video sem nome"} */}
            </div>
            {/* <Modal
                open={modal}
                onClose={SetModal(false)}
            >
                <video 
                    autoPlay={false}
                    controls={false}
                    className="video-thumb"
                    ref={thumb_ref}
                    src={url}
                />
            </Modal> */}
        </div>
    );
}

export default VideoLoaderInput;
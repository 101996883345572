import { CircularProgress } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Request from "../../../../../Request";
import Board from "./Board";

const ProfessionsTable = props => {

    const {
        search
    } = props;

    const [professions, SetProfessions] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [page, SetPage] = useState(1);
    const [maxPage, SetMaxPage] = useState(1);

    const {id} = useParams();

    const GetData = async () => {
        SetLoading(true);
        let response = await Request.Get(`groupprofession/in-professions/${id}?search=${search}&page=${page}`);
        SetLoading(false);
        
        if (!response) return;

        SetProfessions(response.data);
        SetMaxPage(response.last_page);
    }

    useEffect(() => {
        GetData();
    }, [search, page]);

    return (
        <div>
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Data que foi inserido</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(!loading && professions && professions.length != 0) && professions.map((value, index) => (
                            <Board
                                key={index}
                                id={value.id}
                                name={value.name}
                                date={value.created_at}
                                group_id={id}
                                OnDelete={(data) => GetData()}
                            />
                        ))}
                    </tbody>
                </table>
                <div className="w100 flex align-center jcc">
                    {!loading && (professions && professions.length == 0) ? "Nenhuma profissão achada" : ""}
                </div>
            </div>
            <div className="w100 flex jcc align-center">
                {loading && <CircularProgress/>}
            </div>
            <div
                className={(maxPage <= 1 ? "hide" : "")}
            >
                <Pagination
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    count={maxPage}
                    value={page}
                    onChange={(_, value) => {
                        SetPage(value);
                    }}
                />
            </div>
        </div>
    );
}

export default ProfessionsTable;
import React, {Component} from "react";
import { Button, Card, FormControl as Input } from "react-bootstrap";
import { GiRead } from "react-icons/gi";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DefaultButton from "../../ReusableComponents/DefaultButton";

import LoadingScreen from "../../ReusableComponents/LoadingScreen";
import OnlyAdmin from "../../ReusableComponents/OnlyAdmin";
import Search from "../../ReusableComponents/Search";
import CourseCardTable from "./CourseCardTable";
import CoursesImporter from "./CoursesImporter";
import UserCoursesImporter from "./UserCoursesImporter";

class Courses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            search_term: ""
        }
    }

    render() {
        const {admin} = this.props;
        return(
            <div className="courses" style={{position: "relative"}}>
                <Card>
                    <div className="card-body">
                        <h2 className="title">Todos os cursos</h2>
                        <p className="text-muted">{(()=>{ if (admin == "Admin") return 'Aqui são listados todos os cursos do sistema'; else return 'Aqui são listados todos os cursos que você está cadastrado'; })()}</p>
                        <hr/>
                        <div className="flex jcsb flex-wrap margin-bottom">
                            <Search placeholder="Pesquisar curso pelo Nome do curso" ValueChange={value => this.setState({search_term: value})}/>
                            <OnlyAdmin
                                component={
                                    <DefaultButton
                                        to="/courses/register"
                                        bg="confirm"
                                        text="Cadastrar novo curso"
                                    />
                                }
                            />
                        </div>
                        {
                            (() => {
                                if (admin == "Admin") return <CoursesImporter Search={this.state.search_term} Loading={(e) => this.setState({loading: e})}/>;
                                else return <UserCoursesImporter Search={this.state.search_term} Loading={(e) => this.setState({loading: e})}/>
                            })()
                        }
                    </div>
                </Card>
            </div>
        );
    }
}

const MapPropsToState = store => {
    return {
        admin: store.AppReducer.user.role
    }
}

export default connect(MapPropsToState)(Courses);
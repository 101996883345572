import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { BsFillEyeFill } from "react-icons/bs";
import { MdDelete, MdModeEdit } from "react-icons/md";
import Request from "../../../Request";
import DefaultButton from "../../ReusableComponents/DefaultButton";

const UserGroupBoard = props => {

    const {
        id,
        name,
        description,
        date,
        OnDelete
    } = props;

    const [popup, SetPopup] = useState(false);

    const Delete = async () => {
        let form = new FormData();
        form.append("group_id", id);
        let response = await Request.Post("usergroup/delete", form);
        if (response) OnDelete();
    }

    return (
        <tr>
            <td>{name}</td>
            <td>{description}</td>
            <td>{new Date(Date.parse(date)).toLocaleDateString("pt-BR")}</td>
            <td style={{
                verticalAlign: "middle",
                whiteSpace: "nowrap"
            }}>
                <SweetAlert
                    show={popup}
                    title="Atenção!"
                    onConfirm={() => {
                        SetPopup(false);
                        Delete();
                    }}
                    onCancel={() => {
                        SetPopup(false);
                    }}
                    showCancel={true}
                    cancelBtnStyle={{color: "white", textDecoration: "none"}}
                    cancelBtnCssClass="btn-danger"
                    cancelBtnText="Cancelar"
                    confirmBtnText="Excluir"
                >
                    Deseja mesmo deletar o grupo "{name}"?
                </SweetAlert>
                <DefaultButton
                    to={`/groups/view/${id}`}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Ver grupo`}
                    bg="warning"
                    icon={<BsFillEyeFill size={17} color="white"/>}
                />
                <DefaultButton
                    to={`/groups/edit/${id}`}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Editar grupo`}
                    bg="primary"
                    icon={<MdModeEdit color="white" size={17}/>}
                    style={{
                        margin: "0 2px"
                    }}
                />
                <DefaultButton
                    onClick={() => SetPopup(true)}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Deletar grupo`}
                    bg="danger"
                    icon={<MdDelete size={17} color="white"/>}
                />
            </td>
        </tr>
    );
}

export default UserGroupBoard;
import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Request from "../../../../Request";
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import Board from "./Board";

const InactiveUsersTable = props => {

    const {
        search
    } = props;

    const [users, SetUsers] = useState([]);
    const [loading, SetLoading] = useState(false);

    const GetData = async () => {
        SetLoading(true);
        let response = await Request.Get(`register/inactive?search=&page=0`)
        SetLoading(false);
        console.log("inactive");
        console.log(response?.users);
        if (response?.status === true) {
            SetUsers(response?.users);
        }
    }

    useEffect(GetData, [search]);

    return (
        <div className="inativeTableContainer">
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Tipo</th>
                            <th>Data criado</th>
                            <th>Data inativado</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && users?.map((user, userKey) => (
                            <Board
                                key={userKey}
                                id={user?.id}
                                name={user?.name}
                                email={user?.email}
                                role={user?.role?.name}
                                createdAt={user?.created_at}
                                deletedAt={user?.deleted_at}
                                onUpdate={GetData}
                            />
                        ))}
                    </tbody>
                </table>
                <div className="w100 flex align-center jcc">
                    {users.length == 0 && !loading ? "Nenhum usuário achado" : ""}
                </div>
            </div>
        </div>
    );
}

export default InactiveUsersTable;
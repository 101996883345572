import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import '../../../../css/modal.css';

const ModalQuestao = props => {
    const { questao, showModal, setShowModal, questao_number } = props;
    return (
        questao != null && <Modal
            show={showModal == true}
            dialogClassName="modal_width"
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton closeLabel=''><Modal.Title>Questão {questao_number} - Peso: {questao.peso}<br /></Modal.Title></Modal.Header>
            <Modal.Body>
                <div className="custom_scrollbar">

                    <div dangerouslySetInnerHTML={{ __html: questao.enunciado }}>

                    </div>
                    <hr />
                    <p><b>Alternativas:</b></p>
                    <div style={{ display: 'flex',marginTop:10 }}>
                        <div style={{ width: '10%' }}>
                            <p style={{color:questao.correta=='a)'?'green': 'black'}}>a)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                            <div style={{color:questao.correta=='a)'?'green': 'black'}} dangerouslySetInnerHTML={{ __html: questao.alternativas['a)'].texto }}>

                            </div>
                        </div>

                    </div>
                    <div style={{ display: 'flex',marginTop:10 }}>
                        <div style={{ width: '10%' }}>
                            <p style={{color:questao.correta=='b)'?'green': 'black'}}>b)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                            <div style={{color:questao.correta=='b)'?'green': 'black'}} dangerouslySetInnerHTML={{ __html: questao.alternativas['b)'].texto }}>

                            </div>
                        </div>

                    </div>
                    <div style={{ display: 'flex',marginTop:10 }}>
                        <div style={{ width: '10%' }}>
                            <p style={{color:questao.correta=='c)'?'green': 'black'}}>c)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                            <div style={{color:questao.correta=='c)'?'green': 'black'}} dangerouslySetInnerHTML={{ __html: questao.alternativas['c)'].texto }}>

                            </div>
                        </div>

                    </div>
                    <div style={{ display: 'flex',marginTop:10 }}>
                        <div style={{ width: '10%' }}>
                            <p style={{color:questao.correta=='d)'?'green': 'black'}}>d)</p>
                        </div>
                        <div style={{ width: '90%' }}>

                            <div style={{color:questao.correta=='d)'?'green': 'black'}} dangerouslySetInnerHTML={{ __html: questao.alternativas['d)'].texto }}>

                            </div>
                        </div>

                    </div>

                </div>

            </Modal.Body>
        </Modal>

    );
}

export default ModalQuestao;
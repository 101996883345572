import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { BsFillEyeFill } from "react-icons/bs";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Request from "../../../Request";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import { useHistory } from "react-router-dom";
import { logout } from "../../../actions/AppActions";
import { useDispatch } from "react-redux";

const SubRequestTable = props => {

    const {
        course_id,
        ShowSnackbar
    } = props;

    const [users, SetUsers] = useState([]);
    const [loading, SetLoading] = useState(false);

    const [userSelected, SetSelectedUser] = useState("");
    const [popup, SetPopup] = useState(false);
    const [confirmPopup, SetConfirmPopup] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const GetSubRequests = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        SetLoading(true);
        let response = await Request.Get(`courses/subscription/from-course/${course_id}`, {"Authorization": `Bearer ${token}`});
        if (response === undefined) {
            SetLoading(false);
            return;
        }
        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (!response.hasOwnProperty("status")) SetUsers(response);
        else SetUsers([]);//se tem status significa que o retornou algum erro e não uma array com a query
        SetLoading(false);
    }

    const Delete = async () => {
        if (!userSelected) return;
        let token = localStorage.getItem("token");
        if (!token) return;

        let form = new FormData();
        form.append("course_id", course_id);
        form.append("user_id", userSelected.id);

        SetLoading(true);
        let response = await Request.Post("courses/subscription/delete-request", form, {"Authorization": `Bearer ${token}`});
        SetLoading(false);
        ShowSnackbar && ShowSnackbar("Pedido de acesso rejeitado");
        GetSubRequests();
    }

    const Confirm = async () => {
        if (!userSelected) return;
        let token = localStorage.getItem("token");
        if (!token) return;

        let form = new FormData();
        form.append("course_id", course_id);
        form.append("user_id", userSelected.id);

        SetLoading(true);
        let response = await Request.Post("courses/subscription/confirm", form, {"Authorization": `Bearer ${token}`});
        SetLoading(false);
        ShowSnackbar && ShowSnackbar("Usuário adicionado ao curso");
        GetSubRequests();
    }

    useEffect(() => {
        GetSubRequests();
    }, [history.location && history.location.search]);

    return (
        <div id="requests-content">
            <SweetAlert
                id="reject-alert"
                title="Atenção!"
                onConfirm={() => {
                    SetPopup(false);
                    Delete();
                }}
                onCancel={() => {
                    SetPopup(false);
                    SetSelectedUser("");
                }}
                showCancel={true}
                cancelBtnStyle={{color: "white", textDecoration: "none"}}
                cancelBtnCssClass="btn-danger"
                cancelBtnText="Cancelar"
                confirmBtnText="Rejeitar"
                show={popup}
            >{`Deseja rejeitar o pedido de matricula de ${userSelected.name}?`}</SweetAlert>
            <SweetAlert
                id="confirm-alert"
                title="Atenção!"
                onConfirm={() => {
                    SetConfirmPopup(false);
                    Confirm();
                }}
                onCancel={() => {
                    SetConfirmPopup(false);
                    SetSelectedUser("");
                }}
                showCancel={true}
                cancelBtnStyle={{color: "white", textDecoration: "none"}}
                cancelBtnCssClass="btn-danger"
                cancelBtnText="Cancelar"
                confirmBtnText="Confirmar"
                show={confirmPopup}
            >
                {`Deseja aceitar o pedido de matricula de ${userSelected.name}`}
            </SweetAlert>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(!loading && users.length != 0) && users.map((user, index) => (
                            <tr key={index} id={user.id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>
                                    <DefaultButton
                                        icon={<BsFillEyeFill size={17} color="white"/>}
                                        title="Ver perfil"
                                        width="2.2em"
                                        height="2.2em"
                                        padding="0"
                                        to={`/accounts/profile/${user.id}`}
                                        bg="warning"
                                    />
                                    <DefaultButton
                                        icon={<CheckIcon size={17} color="white"/>}
                                        title="Aceitar pedido de inscrição"
                                        width="2.2em"
                                        height="2.2em"
                                        padding="0"
                                        bg="confirm"
                                        style={{margin: "0 2px"}}
                                        onClick={() => {
                                            SetSelectedUser(user);
                                            SetConfirmPopup(true);
                                        }}
                                    />
                                    <DefaultButton
                                        icon={<CloseIcon size={17} color="white"/>}
                                        title="Rejeitar pedido de inscrição"
                                        width="2.2em"
                                        height="2.2em"
                                        padding="0"
                                        bg="danger"
                                        onClick={() => {
                                            SetPopup(true);
                                            SetSelectedUser(user);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className={"w100 flex jcc align-center"}>
                <CircularProgress className={(loading ? "" : " hide")}/>
                <p className={(!loading && users.length == 0 ? "" : " hide")}>Sem pedidos de matriculas</p>
            </div>
        </div>
    );
}

export default SubRequestTable;
import React, { useState } from "react";
import { Form, FormControl as Input } from "react-bootstrap";
import Request from "../../../Request";
import Attention from "../../ReusableComponents/Attention";
import DefaultButton from "../../ReusableComponents/DefaultButton";

import { useHistory } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";

const CreateProfession = props => {

    const [name, SetName] = useState("");

    const [loading, SetLoading] = useState(false);

    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const history = useHistory();

    const Create = async () => {

        if (!name) {
            SetPopup(true);
            SetPopupMessage("O campo nome não pode estar em branco");
        }

        let form = new FormData();
        form.append("name", name);

        SetLoading(true);
        let response = await Request.Post("professions/create", form);
        if (!response) return;

        if (response["status"]) history.push("/professions");
        else if (response["status"] === false) {
            SetPopup(true);
            SetPopupMessage(response["message"]);
        }

        SetLoading(false);

    }

    return (
        <div className="card">
            <div className="card-body">
                <SweetAlert
                    show={popup}
                    title="Atenção!"
                    onConfirm={() => {
                        SetPopup(false);
                    }}
                    confirmBtnText="Ok"
                >
                    {popupMessage}
                </SweetAlert>
                <h2 className="title">Cadastrar profissão</h2>
                <p className="text-muted">Aqui são cadastrados todos profissões do sistema</p>
                <br />
                <Form className="margin-bottom">
                    <Form.Label>Nome <Attention/></Form.Label>
                    <Input 
                        placeholder="Nome"
                        value={name}
                        onChange={(e) => SetName(e.target.value)}
                        className="margin-bottom"
                    />
                </Form>
                <br/>
                <div className="w100 inline-flex jcsb margin-top-1">
                    <DefaultButton
                        text="Voltar"
                        to="/professions"
                        bg="secondary"
                    />

                    <DefaultButton
                        text="Criar profissão"
                        bg="confirm"
                        onClick={Create}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}

export default CreateProfession;
import React, { useEffect, useState } from "react";

import { CircularProgress, Snackbar } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import Search from "../../ReusableComponents/Search";
import Request from "../../../Request"
import GroupBoard from "./GroupBoard";

const Group = props => {

    const [search, SetSearch] = useState("");
    const [loading, SetLoading] = useState(false);
    const [groups, SetGroups] = useState([]);
    const [maxPage, SetMaxPage] = useState(1);
    const [page, SetPage] = useState(1);
    const [snackbar, SetSnackbar] = useState(false);
    const [snackbarMessage, SetSnackbarMessage] = useState("");

    const GetGroups = async () => {
        SetLoading(true);
        let response = await Request.Get(`usergroup/groups?search=${search}&page=${page}`);
        if (!response) return;
        SetGroups(response["data"]);
        SetMaxPage(response["last_page"]);
        SetLoading(false);
    }

    useEffect(() => {
        GetGroups();
    }, [search, page]);

    return(
        <div style={{position: "relative"}}>
            <div className="card">
                <div className="card-body">
                    <h2 className="title">Lista de grupos de usuários</h2>
                    <p className="text-muted">Aqui são listados todos os grupos de usuários do sistema</p>
                    <hr/>
                    <div className="flex jcsb flex-wrap margin-bottom">
                        <Search
                           placeholder="Pesquisar grupos pelo Nome do grupo" 
                           ValueChange={value => SetSearch(value)}
                        />
                        <DefaultButton
                            bg="confirm"
                            to="/groups/create"
                            text="Cadastrar novo grupo"
                        />
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Nome do grupo</th>
                                    <th>Descrição</th>
                                    <th>Data criado</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(!loading && groups.length != 0 && groups) && groups.map((value, index) => (
                                    <GroupBoard
                                        key={index}
                                        id={value.id}
                                        name={value.name}
                                        description={value.description}
                                        date={value.created_at}
                                        OnDelete={() => GetGroups()}
                                    />
                                ))}
                            </tbody>
                        </table>
                        <div className="w100 flex align-center jcc">
                            {groups.length == 0 && !loading ? "Nenhum grupo cadastrado" : ""}
                        </div>
                    </div>
                    <div className="flex align-center jcc w100">
                        <CircularProgress className={loading ? "" : "hide"}/>
                    </div>
                    <div
                        className={(maxPage <= 1 ? "hide" : "")}
                    >
                        <Pagination    
                            style={{ display: "flex", justifyContent: "flex-end" }}
                            count={maxPage}
                            value={page}
                            onChange={(_, value) => {
                                SetPage(value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={2000}
                onClose={() => SetSnackbar(false)}
                open={snackbar}
                message={snackbarMessage}
            />
        </div>
    );
}

export default Group;
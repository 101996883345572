import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaVideo } from "react-icons/fa";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import OnlyAdmin from "../../../ReusableComponents/OnlyAdmin";
import Search from "../../../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import ModalQuestao from "./ModalQuestao";
import { logout } from "../../../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";



const Avaliacao = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_questoes, setLoadingQuestoes] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [questoes, setQuestoes] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, total_pages: 1,last_page:1 });



    const [search, setSearch] = useState('');
    const { curso_id,curso_name } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [questao_id, setQuestaoId] = useState(false);

    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const GetQuestoes = async (page, search) => {

        let response = await Request.Get(`courses/questoes/list/${curso_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.pagination.current_page == page) {
            for (let i=0;i<response.questoes.length;i++){
                response.questoes[i].ref = React.createRef();
                response.questoes[i].refText = React.createRef();

            }
            setQuestoes(response.questoes);

            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingQuestoes(false);

            for (let i=0;i<response.questoes.length;i++){
                if (response.questoes[i].ref.current && response.questoes[i].ref.current !== null)
                response.questoes[i].refText.current.innerHTML = response.questoes[i].ref.current.textContent.substring(0,100)+ (response.questoes[i].ref.current.textContent.length>100?'...':'');
            }
        }
    }

    const DeleteQuestao = async () => {

        let response = await Request.Delete(`courses/questoes/delete/${questao_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
        GetQuestoes(pagination.current_page, search_oficial);



    }

    useEffect(() => {
        GetQuestoes(1, search_oficial);
    }, []);

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>
                <p className="text-muted">Aqui ficam as questões cadastradas no curso "{curso_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">

                        <Search placeholder="Pesquisar pelo Enunciado" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                setSearchOficial(value);
                                setLoadingQuestoes(true);

                                GetQuestoes(1, value)

                            }, 500);
                        }} />

                        <OnlyAdmin
                            component={
                                <Link to={{
                                    pathname: "/questoes/register/" + curso_id,
                                    // state: {
                                    //     course_id: id
                                    // }
                                }} style={{ textDecoration: "none" }}>
                                    <Button variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar questão</p></Button>
                                </Link>
                            }
                        />
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Enunciado</th>
                                        <th>Correta</th>

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_questoes == false && questoes.map((item, id) => (

                                        <tr key={id}>
                                            <td ref={item.refText}></td>
                                            {/* <td>Questão {id + 1}</td> */}

                                            <td>{item.correta}
                                            <div hidden ref={item.ref} dangerouslySetInnerHTML={{ __html: item.enunciado }}></div>
                                            
                                            </td>

                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={URL + 'storage/' + URL + 'storage/' + item.arquivo} /></td> */}

                                            <td>
                                                <span
                                                    onClick={() => {
                                                        console.log(item.ref.current.textContent.substring(0,100))
                                                        setQuestaoSelect({
                                                            questao:item,
                                                            questao_number:id+1
                                                        });
                                                        setShowModal(true);
                                                    }}
                                                    className="inline-flex align-center jcc bg-warning icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Ver aula"
                                                >
                                                    <BsFillEyeFill size={17} color="white" />
                                                </span>
                                                <Link to={`/questoes/edit/${item.id}`} onClick={(e) => e.stopPropagation()}>
                                                    <span
                                                        className="inline-flex align-center jcc bg-primary icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                        data-toggle="tooltip"
                                                        title="Editar questão"
                                                    >
                                                        <MdModeEdit color="white" size={17} />
                                                    </span>
                                                </Link>
                                                <span
                                                    onClick={(e) => {
                                                        setQuestaoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Deletar questão"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span>
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_questoes == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress/>
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_questoes == false && questoes.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma questão cadastrada</p>

                        </div>
                    </div>}
                    <ModalQuestao showModal={showModal} setShowModal={(e)=>setShowModal(e)} questao={questaoSelect.questao} questao_number={questaoSelect.questao_number}  />

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingQuestoes(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            GetQuestoes(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress/>
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingQuestoes(true);
                        DeleteQuestao(questao_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao deletar esta questão, ela não estará mais disponível no sistema
                </SweetAlert>


            </div>

        </div>
    )
}

export default Avaliacao;

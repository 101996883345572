import React, { useEffect, useState } from "react";



import Logo from '../assets/images/logo.png';

const Dashboard = () => {



    return (
        <div className="card">
            <div className="card-body">
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img className="img-fluid" src={Logo} alt="" height="100" style={{ height: '200px' }} />
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }}>

                    <h4 style={{ textAlign: 'center',fontWeight:500 }}>KZT - Solução de vídeos.</h4>
                </div>

            </div>

        </div>
    );
}

export default Dashboard;
import React, { useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import DeleteIcon from '@material-ui/icons/Delete';
import SweetAlert from "react-bootstrap-sweetalert";
import Request from "../../../../Request";

const GroupBoard= props => {

    const {
        id,
        course_id,
        name,
        description,
        users_count,
        loading = false,
        OnDelete
    } = props;

    const [popup, SetPopup] = useState(false);

    const Delete = async () => {
        let form = new FormData();
        form.append("group_id", id);
        form.append("course_id", course_id);

        let response = await Request.Post("groupcourse/remove-group", form);

        if (!response) return;

        if (response["status"]) OnDelete();
    }

    return (
        <tr id={id} className={loading ? "hide" : ""}>
            <td>
                {name}
            </td>
            <td>
                {description}
            </td>
            <td>
                {users_count}
            </td>
            <td style={{ width: "5em" }}>
                <SweetAlert
                    show={popup}
                    title="Atenção!"
                    onConfirm={() => {
                        SetPopup(false);
                        Delete();
                    }}
                    onCancel={() => {
                        SetPopup(false);
                    }}
                    showCancel={true}
                    cancelBtnStyle={{color: "white", textDecoration: "none"}}
                    cancelBtnCssClass="btn-danger"
                    cancelBtnText="Cancelar"
                    confirmBtnText="Excluir"
                >
                    Deseja mesmo deletar o grupo "{name}"?
                </SweetAlert>
                <DefaultButton
                    icon={<BsFillEyeFill size={17} color="white"/>}
                    title="Ver grupo"
                    width="2.2em"
                    height="2.2em"
                    padding="0"
                    to={`/groups/view/${id}`}
                    routeState={{
                        from: `/courses/course/${course_id}`
                    }}
                    bg="warning"
                    style={{marginRight: "2px"}}
                />
                <DefaultButton
                    icon={<DeleteIcon size={17} style={{ color: "white" }} />}
                    onClick={() => SetPopup(true)}
                    title="Remover grupo do curso"
                    bg="danger"
                    padding={0}
                    width="2.2em"
                    height="2.2em"
                />
            </td>
        </tr>
    );
}

export default GroupBoard;
import React, { useState } from "react";

import SweetAlert from "react-bootstrap-sweetalert";

import "../../../css/userboard.css";

import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";

import DefaultButton from "../../ReusableComponents/DefaultButton";
import { Snackbar } from "@material-ui/core";
import Request from "../../../Request";

const UserBoard = props => {

    const [popup, SetPopup] = useState(false);
    const [snackbar, SetSnackbar] = useState(false);
    const [snackmessage, SetSnackmessage] = useState("");

    const history = useHistory();

    const Delete = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let form = new FormData();
        form.append("id", props.id);

        let response = await Request.Post("register/delete", form, {"Authorization": `Bearer ${token}`});
        
        if (!response["status"]) {
            SetSnackbar(true);
            SetSnackmessage(response["message"]);
        } else if (response["status"]) {
            SetSnackbar(true);
            props.onDelete();
        }
    }

    return(
        <tr key={props.i} id={props.id}>
            <td>{props.name}</td>
            <td>{props.email}</td>
            <td>{props.role}</td>
            <td>{new Date(Date.parse(props.created_date)).toLocaleDateString("pt-BR")}</td>
            <td style={{
                verticalAlign: "middle",
                whiteSpace: "nowrap"
            }}>
                <SweetAlert
                    show={popup}
                    title="Atenção!"
                    onConfirm={() => {
                        SetPopup(false);
                        Delete();
                    }}
                    onCancel={() => {
                        SetPopup(false);
                    }}
                    showCancel={true}
                    cancelBtnStyle={{color: "white", textDecoration: "none"}}
                    cancelBtnCssClass="btn-danger"
                    cancelBtnText="Cancelar"
                    confirmBtnText="Desativar"
                >
                    Deseja mesmo desativar a conta de "{props.name}"?
                </SweetAlert>
                <DefaultButton
                    to={`/accounts/profile/${props.id}`}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Ver perfil`}
                    bg="warning"
                    icon={<BsFillEyeFill size={17} color="white"/>}
                />
                <DefaultButton
                    to={`/accounts/edit/${props.id}`}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Editar perfil`}
                    bg="primary"
                    icon={<MdModeEdit color="white" size={17}/>}
                    style={{
                        margin: "0 2px"
                    }}
                />
                <DefaultButton
                    onClick={() => SetPopup(true)}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Desativar conta`}
                    bg="danger"
                    icon={<MdDelete size={17} color="white"/>}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    autoHideDuration={5000}
                    onClose={() => SetSnackbar(false)}
                    open={snackbar}
                    message={snackmessage != "" ? snackmessage : "Usuário deletado!"}
                />
            </td>
        </tr>
    );
}

export default UserBoard;
import React, { useEffect, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { FormControl as Input } from "react-bootstrap";
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import { MdDelete } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useHistory, useParams } from "react-router";
import Request from "../../../../Request";

const AddProfession = props => {

    const [professions, SetProfessions] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [snackbar, SetOpenSnackbar] = useState(false);
    const [snackbar_message, SetMessageSnackbar] = useState("");
    const [search, SetSearch] = useState('');

    const [toAddProfessions, SetToAddProfessions] = useState([]);

    const {id} = useParams();

    const history = useHistory();

    const GetProfessions = async () => {
        let response = await Request.Get(`groupprofession/out-professions/${id}?search=${search}`);
        if (!response) return;
        
        SetProfessions(response);
    }

    const UpdateUserList = (user_id) => {
        let toAddProfessionsTemp = toAddProfessions;
        console.log(toAddProfessionsTemp)

        if (!toAddProfessionsTemp.find(x => x.id == user_id))
            toAddProfessionsTemp.push(professions.find(x => x.id == user_id));
        console.log(toAddProfessionsTemp)
        SetToAddProfessions([...toAddProfessionsTemp]);
        SetSearch("");
    }

    const RemoveUserFromUserList = index => {
        let toAddProfessionsTemp = toAddProfessions;
        toAddProfessionsTemp.splice(index, 1);
        SetToAddProfessions([...toAddProfessionsTemp]);
    }

    const Submit = async () => {
        let form = new FormData();
        for (let i = 0; i < toAddProfessions.length; i++) form.append("profession_id[]", toAddProfessions[i]["id"]);
        form.append("group_id", id);
        
        SetLoading(true);

        let response = await Request.Post("groupprofession/insert", form);
        
        if (!response) return;

        if (response["status"]) history.push(`/groups/view/${id}`)
        
        SetLoading(false);
    }

    useEffect(() => {
        GetProfessions();
    }, [search]);

    return (
        <div className="card">
            <div className="card-body">
                <h2 className="title">Adicionar profissão ao grupo</h2>
                <p className="text-muted">Aqui são adicionados as profissões ao grupo em questão. Nota: Todos os usuários, que são da profissão, serão adicionados ao grupo</p>
                <br />
                <div className="flex jcsb flex-wrap margin-bottom">
                    <Autocomplete
                        id="add-user-field"
                        disableCloseOnSelect={false}
                        blurOnSelect
                        options={professions.filter(user => !toAddProfessions.map(value => value["id"]).includes(user["id"]))}
                        value={toAddProfessions}
                        multiple
                        getOptionSelected={(option, value) => option.id === value.id}

                        getOptionLabel={option => option.name ? option.name : ""}
                        renderOption={(option, i) => (
                            <div 
                                key={i} 
                                onClick={(e) => UpdateUserList(option["id"])} 
                                className="flex fdrow jcsb w100 align-center" >
                                {option.name}
                            </div>
                        )}
                        style={{ width: "100%" }}
                        noOptionsText={(
                            <p>Nenhuma profissão encontrada</p>
                        )}
                        renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                                <Input
                                    {...params.inputProps}
                                    placeholder="Procurar profissões que não estão no curso"
                                    autoFocus
                                    value={search}
                                    onChange={(e) => SetSearch(e.target.value)}
                                />
                            </div>
                        )}
                    />
                </div>
                <div className="table-responsive margin-bottom">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                toAddProfessions.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{value["name"]}</td>
                                            <td>{value["email"]}</td>
                                            <td>
                                                <DefaultButton
                                                    icon={<MdDelete size={17} color="white" />}
                                                    bg="danger"
                                                    onClick={() => RemoveUserFromUserList(index)}
                                                    title="Remover profissão da lista"
                                                    width="2.2em"
                                                    height="2.2em"
                                                    padding="0"
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    <div className="w100 flex align-center jcc">
                        {toAddProfessions.length == 0 && !loading ? "Nenhuma profissão selecionado" : ""}
                    </div>
                </div>
                <div className="w100 flex align-center jcc">
                    <CircularProgress className={loading ? "" : "hide"} style={{ width: "30px", height: "30px" }} />
                </div>
                <div className="w100 inline-flex jcsb mt-2">
                    <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to={`/groups/view/${id}`}
                        search={'?'+new URLSearchParams({tab: 1}).toString()}
                    />
                    <DefaultButton
                        bg="confirm"
                        text={toAddProfessions.length <= 1 ? "Adicionar profissão" : "Adicionar profissões"}
                        onClick={() => Submit()}
                        loading={loading}
                        disabled={toAddProfessions.length == 0}
                    />
                </div>
            </div>
        </div>
    );
}

export default AddProfession;
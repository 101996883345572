import React, { useState } from "react";

import { FormControl as Input, Button, Form } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Attention from "./Attention";

const PasswordInput = (props) => {

    const [show, setShow] = useState(false);

    return(
        <div className="password-group margin-bottom" style={{width: props.width}}>
            <Form.Label>{props.label} <Attention/></Form.Label>
            <div className="fdrow flex">
                <Input 
                    type={(show ? "text" : "password")} 
                    placeholder={props.placeholder} 
                    className={props.className + " flex1"}
                    value={props.value}
                    onChange={(e) => (props.OnChangeText && props.OnChangeText(e))}
                    required={props.required}
                    autoComplete="new-password"
                    disabled={props.disabled}
                    style={{
                        borderTopLeftRadius: "7px",
                        borderBottomLeftRadius: "7px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px"
                    }}
                />
                <Button 
                    onClick={() => setShow(!show)} 
                    style={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderTopRightRadius: "7px",
                        borderBottomRightRadius: "7px"
                    }}
                >{(show ? <AiFillEyeInvisible/> : <AiFillEye/>)}</Button>
            </div>
        </div>
    );
}

PasswordInput.defaultProps = {
    width: "100%"
}

export default PasswordInput;
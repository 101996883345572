import React from "react";

import {Spinner} from "react-bootstrap";

const LoadingScreen = props => {

    return(
        <div 
            className={"loading-screen flex align-center jcc fdcolumn " + (props.loading ? "" : "hide")}
            style={{
                position: "absolute",
                left: "0",
                top: "0",
                backgroundColor: "#f8f8fa",
                zIndex: "999",
                width: "100%",
                height: "100%"
            }}
        >
            <div>
                <Spinner animation="grow" variant={props.variant || "primary"}/>
                <Spinner animation="grow" variant={props.variant || "primary"} style={{animationDelay: ".1s"}}/>
                <Spinner animation="grow" variant={props.variant || "primary"} style={{animationDelay: ".2s"}}/>
            </div>
            <div style={{
                fontSize: "1.1em"
            }}>
                {props.message}
            </div>
        </div> 
    );
}

export default LoadingScreen;
import { keys } from "@material-ui/core/styles/createBreakpoints";
import { BsDownload } from "react-icons/bs";
import { API_URL } from "../../../../variables";

const Certificate = props => {

    const {
        usercourses
    } = props;

    return (
        <div className="responsive-table">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Nome do curso</th>
                        <th>Baixou certificado?</th>
                        <th>Desempenho</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {usercourses?.map((value, key) => (value?.completed_course && (value?.has_questions ? value?.completed_exam : true)) && (
                        <tr
                            key={key}
                            hidden={!value?.completed_course}
                            aria-questions={value?.numero_questoes}
                            aria-rightanswers={value?.numero_acertos}
                            aria-completedcourse={value?.completed_course}
                            aria-completedexam={value?.completed_exam}
                            aria-show={value?.numero_questoes > 0 ? value?.completed_exam : value?.completed_course}
                        >
                            <td>{value?.course?.name}</td>
                            <td>{value?.download_certificate ? "Sim" : "Não"}</td>
                            <td>{value?.numero_acertos} / {value?.numero_questoes}</td>
                            <td>
                                <a
                                    href={API_URL + 'courses/certificado/' + value?.course?.id + '/' + value?.user_id}
                                    className="inline-flex align-center jcc bg-success icon"
                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                    title="Baixar certificado"
                                    target="_blank"
                                >
                                    <BsDownload size={17} color="white" />
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Certificate;
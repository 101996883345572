import { useCallback, useState } from "react";
import Logo from "../assets/images/logo.png";
import { logout } from "../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { URL } from "../variables";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ChangePasswordPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const token = localStorage.getItem("token");

  const [msgErro, setMsgErro] = useState("");
  const [show_success, setShowSuccess] = useState(false);
  const [loading_save, setLoadingSave] = useState(false);

  const [old_password, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");

  const logout_ = useCallback((e) => {
    e.preventDefault();

    localStorage.removeItem("token");
    localStorage.removeItem("user");

    dispatch(logout());
    history.push("/login");
  }, []);

  const changePassword = (e) => {
    e.preventDefault();

    setLoadingSave(true);

    fetch(`${URL}api/auth/change_password`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        old_password: old_password,
        password: password,
        password_confirmation: password_confirmation,
      }),
    }).then( async (res) => {
      try {
        let resp = await res.json();
        console.log(res);
        console.log(resp);
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = "";
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1) erro += errors[i] + "\n";
            else erro += errors[i];
          }
          console.log(erro);

          setMsgErro(erro);
          setShowSuccess(false);
          setLoadingSave(false);
        } else {
          setLoadingSave(false);
          setShowSuccess(true);
          setMsgErro("");
        }
      } catch (error) {
        console.log(error);
        // this.setState({
        //   loading: false,
        //   msgErro: "Erro ao salvar",
        //   refresh: false,
        //   loadingMore: false,
        //   loading_save: false,
        // });
      }
    });
  };

  return (
    <div style={{ height: "100vh" }}>
      <SweetAlert
        success
        title={"Sucesso!"}
        onConfirm={() => {
          setShowSuccess(false);
          history.push("/");
        }}
        // onCancel={this.onCancel.bind(this)}
        show={show_success}
        confirmBtnText='Ok'
        cancelBtnText='Cancelar'
        confirmBtnBsStyle="info"
        // cancelBtnBsStyle="success"
        showCancel={false}
    >
        Senha alterada com sucesso!

    </SweetAlert>

      <div className="row" style={{ backgroundColor: "#ecf0f1", margin: 0 }}>
        <div
          className="col-md-12 col-sm-12 col-12 col-lg-12 col-xl-12 d-block"
          style={{ overflow: "hidden" }}
        >
          <div
            className="row"
            style={{
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="col-md-6 col-12 col-sm-12 col-lg-4 col-xl-4"
              style={{ margin: 0, padding: 0 }}
            >
              <div className="card" style={{ margin: 0, maxWidth: 400 }}>
                <div
                  className="card-body"
                  style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                >
                  <div className="text-center">
                    <a className="logo">
                      <img
                        src={Logo}
                        style={{
                          height: "100px",
                          width: "100%",
                          objectFit: "contain",
                        }}
                        height="80"
                        alt="logo"
                      />
                    </a>
                  </div>

                  <div style={{ padding: "1rem", paddingBottom: 0 }}>
                    <h4 className="font-18 m-b-5 text-center header-title">
                      Alterar senha
                    </h4>
                    <p className="text-muted text-center">
                      Altere sua senha para continuar.
                    </p>

                    <form
                      className="form-horizontal m-t-30"
                      onSubmit={(e) => changePassword(e)}
                    >
                      <div className="form-group row">
                        <label
                          className="col-sm-12 col-form-label"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          Senha atual <b style={{ color: "red" }}>*</b>
                        </label>

                        <div
                          className="col-12"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <input
                            id="password"
                            type="password"
                            value={old_password}
                            onChange={(e) => setOldPassword(e.target.value)}
                            className="form-control"
                            name="password"
                            required
                            autoComplete="current-password"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-12 col-form-label"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          Nova senha <b style={{ color: "red" }}>*</b>
                        </label>

                        <div
                          className="col-12"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            name="password"
                            required
                            autoComplete="current-password"
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          className="col-sm-12 col-form-label"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          Confirme a nova senha{" "}
                          <b style={{ color: "red" }}>*</b>
                        </label>

                        <div
                          className="col-12"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <input
                            id="password"
                            type="password"
                            value={password_confirmation}
                            onChange={(e) =>
                              setPasswordConfirmation(e.target.value)
                            }
                            className="form-control"
                            name="password"
                            required
                            autoComplete="current-password"
                          />
                        </div>
                      </div>

                      <div className="form-group row ">
                        <div className="col-sm-12" style={{ padding: 0 }}>
                          <span
                            className="invalid-feedback"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            role="alert"
                          >
                            <strong
                              style={{
                                textAlign: "center",
                                fontSize: "0.8rem",
                              }}
                            >
                              {msgErro}
                            </strong>
                          </span>
                        </div>
                        {loading_save == false && (
                          <div
                            className="col-sm-12 text-right"
                            style={{ padding: 0 }}
                          >
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              style={{
                                width: "100%",
                                marginTop: "15px",
                                height: "3rem",
                              }}
                              type="submit"
                            >
                              Entrar
                            </button>
                          </div>
                        )}
                        {loading_save == true && (
                          <div
                            className="col-12 d-flex justify-content-center align-items-center"
                            style={{ marginTop: "2rem" }}
                          >
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            ></div>
                          </div>
                        )}
                        <div
                          className="col-sm-12 text-right"
                          style={{ padding: 0 }}
                        >
                          <button
                            onClick={(e) => logout_(e)}
                            className="btn btn-secondary w-md waves-effect waves-light"
                            style={{
                              width: "100%",
                              marginTop: "15px",
                              height: "3rem",
                            }}
                          >
                            Deslogar
                          </button>
                        </div>
                      </div>

                      <div className="form-group m-t-10 mb-0 row">
                        <div
                          className="col-12 m-t-20"
                          style={{ textAlign: "center" }}
                        >
                          {/* <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm',$faculdade->slug) }}">
                                            <i className="mdi mdi-lock"></i>{{ __('Esqueceu sua senha?') }}
                                        </a>  */}
                          {/* {this.state.faculdade == null && <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm','admin') }}">
                                                    <i className="mdi mdi-lock"></i>Esqueceu sua senha?
                                        </a>} */}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="text-center mt-4">
                    <p>© 2023 KZT</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, {useEffect, useRef, useState} from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import { Accordion, AccordionSummary, AccordionDetails, Menu, MenuItem, Button, ButtonBase, Checkbox, CircularProgress } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Request from "../../../Request";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import UsersLessonPage from "../Lessons/Users/UsersLessonPage";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { API_URL, STORAGE_URL } from "../../../variables";
import { Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../ReusableComponents/TabPanel";
import VideocamIcon from '@material-ui/icons/Videocam';
import DescriptionIcon from '@material-ui/icons/Description';
import LaunchIcon from '@material-ui/icons/Launch';
import Video from "../../ReusableComponents/Video";

const LessonPage = (props) => {

    const [lesson, SetLesson] = useState([]);
    const [videosLinks, SetVideosLinks] = useState([]);
    const [videosIds, SetVideosIds] = useState([]);

    const [videosCovers, SetVideosCovers] = useState([]);
    
    const [videosPaths, SetVideosPaths] = useState([]);

    const [loading, SetLoading] = useState(false);
    const [firstLoading, SetFirstLoading] = useState(false);

    const [currentVideoPath, SetCurrentVideoPath] = useState("");

    const [name, SetName] = useState("");
    const [description, SetDescription] = useState("");

    const [activeDocumentPath, SetActiveDocumentPath] = useState("");

    const [tabValue, SetTabValue] = useState(0);

    const userId = useSelector(store => store.AppReducer.user.id);

    const history = useHistory();
    const location = useLocation();
    const {lesson_id} = useParams();
    const query = new URLSearchParams(location.search);

    const GetLessonData = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let response = await Request.Get(`lessons/lesson/${lesson_id}/${userId}`, {"Authorization": `Bearer ${token}`});

        if (!response || (!response.status && response.status !== undefined)) {
            history.push("/courses/list");
            return;
        }
        SetLesson(response);

        let tVideos = [];
        let tVideosPaths = [];
        let tVideosCovers = [];
        let tVideosIds = [];



        for (let i = 0; i < response["files"]["videos"].length; i++) {
            tVideos.push(response["files"]["videos"][i]["id"]);
            tVideosPaths.push(response["files"]["videos"][i]["path"])
            tVideosCovers.push(response["files"]["videos"][i]["cover"])
            tVideosIds.push(response["files"]["videos"][i]["id"])

        }

        let tDocsLinks = [];

        let tDocs = response?.files?.documents;
        if (tDocs) for (let j = 0; j < tDocs.length; j++) tDocsLinks.push(tDocs[j]);

        console.log(tDocsLinks);
        console.log(tVideos[0]);
        console.log(tVideosPaths[0]);


        if ((!query.get("video") && !query.get("doc")) && tVideos.length != 0) {
            // SetCurrentVideoPath(tVideosPaths[0]);
            history.push(`?video=${tVideos[0]}`);
        }
        else if ((!query.get("doc") && !query.get("video") && tDocsLinks.length != 0)) history.push(`?doc=${tDocsLinks[0]?.id}`);

        SetVideosPaths([...tVideosPaths]);
        SetVideosLinks([...tVideos]);
        SetVideosCovers([...tVideosCovers]);
        SetVideosIds([...tVideosIds]);


        SetFirstLoading(true);
    }

    useEffect(() => {
        if (!query.get("doc")) {
            SetActiveDocumentPath("");
            return;
        }
        let docs = lesson?.files?.documents;
        let doc = docs.filter(x => x.id == query.get("doc"));

        if (doc.length == 0) return;

        SetActiveDocumentPath(doc[0]?.path);
    }, [query]);

    useEffect(() => {
        GetLessonData();
    }, []);

    if (!firstLoading) return <SkeletonLessonPage/>
    return (
        <div className="card">
            <div className="card-body">
                <h2 className="title">{lesson.title}</h2>
                <br/>
                <div className="course-page flex fdrow margin-bottom">
                    <div 
                        id="left-side"
                        className={"flex3"}
                        style={{
                            width: "-webkit-fill-available"
                        }}
                    >
                        <Video
                            url_cover={(() => {
                                console.log();
                                if (query.get("video") && videosPaths && videosCovers && videosCovers[0]) {
                                    let index = videosIds.indexOf(query.get("video"));
                                    let url = STORAGE_URL +videosCovers[index];
                                    return url;
                                }
                                else {
                                    if (videosLinks.length !== 0 && !query.get("doc") && videosPaths && videosCovers && videosCovers[0]) {
                                        return STORAGE_URL + videosCovers[0];
                                    }
                                    else return "";
                                } 
                            })()}
                            src={(() => {
                                console.log();
                                if (query.get("video") && videosPaths) {
                                    let index = videosIds.indexOf(query.get("video"));

                                    let url = STORAGE_URL +videosPaths[index];
                                    return url;
                                }
                                else {
                                    if (videosLinks.length !== 0 && !query.get("doc") && videosPaths) {
                                        history.push(`?video=${videosLinks[0]}`);
                                        return STORAGE_URL + videosPaths[0];
                                    }
                                    else return "";
                                } 
                            })()}
                            speeds={[0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]}
                            previousVideoLink={(() => {
                                let currentIndex = videosLinks.findIndex(x => x == query.get("video"));
                                if (currentIndex === 0) return null;
                                else {
                                    if (videosLinks[currentIndex - 1] === undefined) return null;
                                    let previousVideoId = videosLinks[currentIndex - 1];
                                    return `/lesson/${lesson_id}?video=${previousVideoId}`;
                                }
                            })()}
                            nextVideoLink={(() => {
                                let currentIndex = videosLinks.findIndex(x => x == query.get("video"));
                                if (currentIndex === videosLinks.length) return null;
                                else {
                                    if (videosLinks[currentIndex + 1] === undefined) return null;
                                    let nextVideoId = videosLinks[currentIndex + 1];
                                    return `/lesson/${lesson_id}?video=${nextVideoId}`;
                                }
                            })()}
                            hidden={videosLinks.length == 0 || !query.get("video")}
                        />
                        <div>
                            <iframe 
                                src={activeDocumentPath ? `${STORAGE_URL}${activeDocumentPath}` : ""}
                                hidden={!activeDocumentPath}
                                style={{
                                    width: "100%",
                                    height: "30vw",
                                    minHeight: "22em"
                                }}
                            />
                        </div>
                        <Tabs 
                            value={tabValue}
                            onChange={(_, value) => SetTabValue(value)}
                            style={{ backgroundColor: "#ececf1", minHeight: "51px" }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant={"scrollable"}
                            scrollButtons={"on"}
                        >
                            <Tab label="Sobre a aula"/>
                            <Tab label="Conteúdo da aula" className="d-lg-none d-block"/>
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            {lesson.description}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} className="d-lg-none d-block">
                            <div className="w100 flex jcc">
                                {loading && lesson === undefined ? <CircularProgress/> : ""}
                            </div>
                            {(lesson !== undefined && lesson.files) && lesson.files.videos.map((video, videoIndex) => (
                                <ButtonBase 
                                    className="w100 flex align-center jcsb" 
                                    style={{
                                        whiteSpace: "nowrap",
                                        padding: "2px",
                                        backgroundColor: (query.get("video") == video.id ? "rgba(0, 0, 0, 0.135)" : "initial")
                                    }} 
                                    key={"video_" + videoIndex}
                                    onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth'
                                        });
                                        history.push(`?video=${video.id}`);
                                        SetCurrentVideoPath(video.path);
                                    }}
                                >
                                    <div style={{padding: "9px"}}>
                                        {/* <Checkbox 
                                            disabled
                                            checked={video.completed}
                                        /> */}
                                        {video.name !== null ? video.name : "Video sem nome"}
                                    </div>
                                    <VideocamIcon style={{color: "gray"}}/>
                                </ButtonBase>
                            ))}
                            {(lesson && lesson.files) && lesson.files.documents.map((document, index) => (
                                <ButtonBase 
                                    className="w100 flex align-center jcsb" 
                                    style={{
                                        whiteSpace: "nowrap", 
                                        padding: "2px",
                                        cursor: "pointer",
                                        backgroundColor: (query.get("doc") == document.id ? "rgba(0, 0, 0, 0.135)" : "initial")
                                    }} 
                                    key={"file" + index}
                                    onClick={() => {
                                        history.push(`?doc=${document.id}&lesson=${document.lesson_id}`);
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth'
                                        });
                                    }}
                                >
                                    <div className="text-truncate" style={{padding: "9px", maxWidth: "250px"}}>
                                        {document["name"] ? document["name"] : "Arquivo"}
                                    </div>
                                    <div>
                                        <LaunchIcon 
                                            style={{ color: "gray" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                window.open(STORAGE_URL + document["path"]);
                                            }}
                                            className="icon"
                                        />
                                        <DescriptionIcon style={{ color: "gray" }} />
                                    </div>
                                </ButtonBase>
                            ))}
                        </TabPanel>
                    </div>
                    <div 
                        id="right-side"
                        className="flex1 d-lg-block d-none"
                    >
                        <AccordionSummary 
                            style={{
                                fontWeight: 700,
                                borderBottom: "3px solid #351c75",
                                fontSize: "1.2em",
                                background: "#ececf1"
                            }}
                        >
                            <div className="flex jcc w100">Conteúdo da aula</div>
                        </AccordionSummary>
                        <div className={"w100 flex jcc" + (loading && lesson === undefined ? "" : " hide")} style={{marginTop: "5em"}}>
                            <CircularProgress/>
                        </div>
                        <div 
                            className="course-content"
                            style={{
                                maxHeight: "31.8vw",
                                overflowY: "scroll"
                            }}
                        >  
                            {/* <Accordion 
                                style={{
                                    background: "#ececf1"
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    style={{
                                        fontWeight: "700",
                                        textTransform: "capitalize"
                                    }}
                                >
                                    <div>
                                        {lesson && lesson["title"]}
                                    </div>
                                </AccordionSummary> */}
                                {/* <AccordionDetails 
                                    className="flex fdcolumn "
                                    style={{padding: 0}}
                                > */}
                                    {(lesson !== undefined && lesson.files) && lesson.files.videos.map((video, videoIndex) => (
                                        <ButtonBase 
                                            className="w100 flex align-center jcsb" 
                                            style={{
                                                whiteSpace: "nowrap",
                                                padding: "2px",
                                                backgroundColor: (query.get("video") == video.id ? "rgba(0, 0, 0, 0.135)" : "initial")
                                            }} 
                                            key={"video_" + videoIndex}
                                            onClick={() => {
                                                history.push(`?video=${video.id}`);
                                                SetCurrentVideoPath(video.path);
                                            }}
                                        >
                                            <div style={{padding: "9px"}}>
                                                {/* <Checkbox 
                                                    disabled
                                                    checked={video.completed}
                                                /> */}
                                                {video.name !== null ? video.name : "Video sem nome"}
                                            </div>
                                            <VideocamIcon style={{color: "gray"}}/>
                                        </ButtonBase>
                                    ))}
                                    {(lesson && lesson.files) && lesson.files.documents.map((document, index) => (
                                        <ButtonBase 
                                            className="w100 flex align-center jcsb" 
                                            style={{
                                                whiteSpace: "nowrap", 
                                                padding: "2px",
                                                cursor: "pointer",
                                                backgroundColor: (query.get("doc") == document.id ? "rgba(0, 0, 0, 0.135)" : "initial")
                                            }} 
                                            key={"file" + index}
                                            onClick={() => {
                                                history.push(`?doc=${document.id}&lesson=${document.lesson_id}`);
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth'
                                                });
                                            }}
                                        >
                                            <div className="text-truncate" style={{padding: "9px", maxWidth: "250px"}}>
                                                {document["name"] ? document["name"] : "Arquivo"}
                                            </div>
                                            <div>
                                                <LaunchIcon 
                                                    style={{ color: "gray" }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.open(STORAGE_URL + document["path"]);
                                                    }}
                                                    className="icon"
                                                />
                                                <DescriptionIcon style={{ color: "gray" }} />
                                            </div>
                                        </ButtonBase>
                                    ))}
                                {/* </AccordionDetails> */}
                            {/* </Accordion> */}
                        </div>
                    </div>
                </div>

                <div className="w100 inline-flex jcsb margin-top-1">
                    <DefaultButton
                        to={(lesson && lesson.course_id) ? `/courses/course/${lesson.course_id}` : "/courses/list"}
                        bg="secondary"
                        icon={<RiArrowGoBackLine/>}
                        text="Voltar"
                    />
                </div>
            </div>
        </div>
    );
}

const SkeletonLessonPage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title skeleton-shimmer"/>
                <br/>
                <div className="w100 flex fdrow margin-bottom" style={{height: "32vw"}}>
                    <div className="skeleton-base skeleton-shimmer flex3" style={{marginRight: "1px"}}/>
                    <div className="skeleton-base skeleton-shimmer flex1 d-lg-block d-none"/>
                </div>
                <div className="skeleton-medium-text skeleton-shimmer"/>
                <div className="skeleton-medium-text skeleton-shimmer"/>
                <div className="skeleton-medium-text skeleton-shimmer" style={{width: "45%"}}/>
                <div className="skeleton-small-button skeleton-shimmer" style={{marginTop: "1em"}}/>
            </div>
        </div>
    );
}

export default LessonPage;
// export const URL = "http://localhost:8000/"
// export const THIS_URL = "http://localhost:3000/"

// export const URL = "https://lifeplayback.enterscience.com.br/"
// export const THIS_URL = "https://lifeplay.enterscience.com.br/"

// export const URL = "https://apilifeplay.madelife.med.br/"
// export const THIS_URL = "https://lifeplay.madelife.med.br/"

export const URL = "https://api.kzt.com.br/"
export const THIS_URL = "https://ser.kzt.com.br/"

export const API_URL = URL + "api/";
export const STORAGE_URL = URL + "storage/";